import React, { useState, useEffect, useCallback, useMemo } from "react";
import ApiService from "../../../../services/apiService";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import userManagement from "../../../../services/userManagement";
import { FaSortDown, FaSortUp } from "react-icons/fa";

const EditUserRole = () => {
  const { id } = useParams();
  const location = useLocation();
  const userId = location.state;

  const [moduleClaims, setModuleClaims] = useState([]);
  const [crudClaims, setCrudClaims] = useState([]);
  const [activeModule, setActiveModule] = useState(null);
  const [allClaims, setAllClaims] = useState([]);
  const [expandedMasters, setExpandedMasters] = useState(false); // For toggling the "Masters" dropdown
  const [expandedReports, setExpandedReports] = useState(false); // For toggling the "Reports" dropdown
  const [expandedOrders, setExpandedOrders] = useState(false); // For toggling the "Orders" dropdown
  const [isLoading, setIsLoading] = useState(false);

  const apiService = new ApiService();
  const token = localStorage.getItem("Token");
  const user = useMemo(() => userManagement?.getUserId(token), [token]);

  useEffect(() => {
    fetchClaimsData();
  }, []);

  const fetchClaimsData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [userClaimsResponse, allClaimsResponse] = await Promise.all([
        apiService.getApiParamater("GET_USER_CLAIM", `?UserId=${userId}`),
        apiService.get("GET_CLAIM"),
      ]);
  
      const userClaims = userClaimsResponse.data.result || [];
      const allClaims = allClaimsResponse.data.result || [];
      const mappedClaims = mapClaimsWithUserClaims(allClaims, userClaims);
  
      setAllClaims(mappedClaims);
  
      const modules = mappedClaims.filter((claim) => claim.claimType === "module");
      setModuleClaims(modules);
  
      // Maintain or set active module
      const currentActiveModule = modules.find((module) => module.claimValue === activeModule);
      const defaultModule = currentActiveModule || (modules.length > 0 ? modules[0] : null);
  
      if (defaultModule) {
        setActiveModule(defaultModule.claimValue);
        updateCrudClaims(defaultModule.claimValue, mappedClaims);
      }
    } catch (error) {
      console.error("Error fetching claims data:", error);
      toast.error("Failed to fetch claims data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [userId, activeModule, apiService]);
  

  const mapClaimsWithUserClaims = (allClaims, userClaims) => {
    const userClaimIds = new Set(userClaims.map((claim) => claim.claimId));
    return allClaims.map((claim) => ({
      ...claim,
      enabled: userClaimIds.has(claim.id), // Auto-activate claims found in user claims
      userClaimId: userClaims.find((uc) => uc.claimId === claim.id)?.id || null,
      name: claim.claimType === "crud" ? claim.claimValue.split(".")[1] || claim.claimValue : claim.claimValue,
    }));
  };
  

  const updateCrudClaims = useCallback(
    (moduleName, claims) => {
      const moduleCrudClaims = claims.filter(
        (claim) => claim.claimType === "crud" && claim.claimValue.startsWith(`${moduleName}.`)
      );
      setCrudClaims(moduleCrudClaims);
    },
    [setCrudClaims]
  );

  const handleModuleClick = (moduleName) => {
    setActiveModule(moduleName);
    updateCrudClaims(moduleName, allClaims);
  };

  const toggleMasters = () => {
    setExpandedMasters((prev) => !prev);
  };

  const toggleReports = () => {
    setExpandedReports((prev) => !prev);
  };

  const toggleOrders = () => {
    setExpandedOrders((prev) => !prev);
  };

  const handleCheckboxChange = (claimId, userClaimId) => async (e) => {
    const isChecked = e.target.checked;
    try {
      if (isChecked) {
        await apiService.post("CREATE_USER_CLAIM", {
          userId,
          claimId,
          createdBy: user,
          modifiedBy: user,
        });
        toast.success("Permission granted successfully");
      } else if (userClaimId) {
        await apiService.delete("DELETE_USER_CLAIM", { id: userClaimId });
        toast.info("Permission removed successfully");
      } else {
        toast.error("Unable to find the user claim to delete");
      }
      await fetchClaimsData();
    } catch (error) {
      console.error("Error updating user claims:", error);
      toast.error("Failed to update user claims");
    }
  };

  const masterModules = moduleClaims.filter((claim) => claim.claimValue.includes("Master"));
  const reportModules = moduleClaims.filter((claim) => claim.claimValue.includes("Report"));
  const orderModules = moduleClaims.filter((claim) => claim.claimValue.includes("Order")&& !claim.claimValue.includes("Report"));
  const otherModules = moduleClaims.filter(
    (claim) => !claim.claimValue.includes("Master") && !claim.claimValue.includes("Report") && !claim.claimValue.includes("Order")&& !claim.claimValue.includes("Master")
  );

  return (
    <div className="p-3">
      <div className="px-lg-5">
        <h4>Manage User Role</h4>
        <div className="row pt-5">
          {/* Sidebar */}
          <div className="col-lg-3">
            <div className="p-3" style={{ border: "solid #dbdbdb", borderRadius: "13px" }}>
              {/* Masters Section */}
              {masterModules.length > 0 && (
                <div>
                  <div
                    className={`sidebar-text d-flex align-items-center p-2 ${expandedMasters ? "active" : ""}`}
                    onClick={toggleMasters}
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className="mx-1 mb-0">Masters</h5>
                    <span className="ms-auto">{expandedMasters ? <FaSortDown /> : <FaSortUp />}</span>
                  </div>
                  {expandedMasters && (
                    <div style={{ backgroundColor: "#e9ecff" }} className="bg-light-purple">
                      {masterModules.map((claim) => (
                        <div
                          key={claim.id}
                          className={`sidebar-text p-2 ms-3 ${activeModule === claim.claimValue ? "active-module" : ""}`}
                          onClick={() => handleModuleClick(claim.claimValue)}
                          style={{
                            background: activeModule === claim.claimValue ? "#f3f4fa" : "transparent",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {claim.claimValue}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {/* Reports Section */}
              {reportModules.length > 0 && (
                <div>
                  <div
                    className={`sidebar-text d-flex align-items-center p-2 ${expandedReports ? "active" : ""}`}
                    onClick={toggleReports}
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className="mx-1 mb-0">Reports</h5>
                    <span className="ms-auto">{expandedReports ? <FaSortDown /> : <FaSortUp />}</span>
                  </div>
                  {expandedReports && (
                    <div style={{ backgroundColor: "#e9ecff" }} className="bg-light-purple">
                      {reportModules.map((claim) => (
                        <div
                          key={claim.id}
                          className={`sidebar-text p-2 ms-3 ${activeModule === claim.claimValue ? "active-module" : ""}`}
                          onClick={() => handleModuleClick(claim.claimValue)}
                          style={{
                            background: activeModule === claim.claimValue ? "#f3f4fa" : "transparent",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {claim.claimValue}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {/* Orders Section */}
              {orderModules.length > 0 && (
                <div>
                  <div
                    className={`sidebar-text d-flex align-items-center p-2 ${expandedOrders ? "active" : ""}`}
                    onClick={toggleOrders}
                    style={{ cursor: "pointer" }}
                  >
                    <h5 className="mx-1 mb-0">Orders</h5>
                    <span className="ms-auto">{expandedOrders ? <FaSortDown /> : <FaSortUp />}</span>
                  </div>
                  {expandedOrders && (
                    <div style={{ backgroundColor: "#e9ecff" }} className="bg-light-purple">
                      {orderModules.map((claim) => (
                        <div
                          key={claim.id}
                          className={`sidebar-text p-2 ms-3 ${activeModule === claim.claimValue ? "active-module" : ""}`}
                          onClick={() => handleModuleClick(claim.claimValue)}
                          style={{
                            background: activeModule === claim.claimValue ? "#f3f4fa" : "transparent",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {claim.claimValue}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {/* Other Modules */}
              {otherModules.map((claim) => (
                <div
                  key={claim.id}
                  className={`sidebar-text p-2 ${activeModule === claim.claimValue ? "active" : ""}`}
                  onClick={() => handleModuleClick(claim.claimValue)}
                >
                  {claim.claimValue}
                </div>
              ))}
            </div>
          </div>

          {/* Content */}
          <div className="col-lg-9">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                <div className="spinner-border text-primary" role="status"></div>
              </div>
            ) : (
              activeModule && (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Permission</th>
                      <th scope="col">Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    {crudClaims.map(({ id, name, enabled, userClaimId }) => (
                      <tr key={id}>
                        <td>{name.charAt(0).toUpperCase() + name.slice(1)}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={enabled}
                            style={{ width: "23.81px", height: "23.81px", border: "0.5px solid #6E5199" }}
                            onChange={handleCheckboxChange(id, userClaimId)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserRole;
