// Action Types
export const SET_TAX_EXPLICIT = 'SET_TAX_EXPLICIT';
export const SET_TAX_IMPLICIT = 'SET_TAX_IMPLICIT';

// Action Creators
export const setTaxExplicit = (value) => ({
    type: SET_TAX_EXPLICIT,
    payload: value,
});

export const setTaxImplicit = (value) => ({
    type: SET_TAX_IMPLICIT,
    payload: value,
});
