import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom"; // React Router for navigation
import ApiService from "../../../../services/apiService";
import axios from "axios";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize navigation
    const apiService = new ApiService()
  const fetchUsers = async () => {
    try {
      const response = await apiService.get("GET_USERS");
        setUsers(response.data.result);
      
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Navigate to the next page with userId
  const handleEdit = (userId) => {
    navigate(`/admin/master/userMaster/ManageUser/${userId}`,{state:userId});
  };

  // Table Columns
  const columns = [
    {
      name: "User ID",
      selector: (row) => row.user_Id || "---",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name || "---",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.roleName || "---",
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          onClick={() => handleEdit(row.user_Id)}
          style={{
            backgroundColor: "#6E5199",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          Edit
        </button>
      ),
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={users}
        progressPending={loading}
        pagination
        highlightOnHover
        responsive
      />
    </div>
  );
};

export default ManageUsers;
