import { React, useState, useEffect } from 'react'
import Profile from "../../../assets/images/profileImg.png"
import "./header.css"
import { Navigate, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { logout } from '../../../../Redux/actions';
import { ReactComponent as Notification } from '../../../assets/images/svgIcons/ic_bell.svg';
import { ReactComponent as Chat } from '../../../assets/images/svgIcons/ic_chat.svg';
import { ReactComponent as Setting } from '../../../assets/images/svgIcons/ic_setting.svg';
import { connect, useDispatch } from "react-redux"
import { Link } from 'react-router-dom';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';

const AdminHeader = () => {
    const [headerText, setHeaderText] = useState('Dashboard');
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const menuRegex = /^\/admin\/menu\/[^\/]+\/[^\/]+$/;
        const updateRegex = /^\/admin\/master\/Update\/(.+)$/;
        const { pathname } = location;
        if (pathname === '/admin/menu') {
            setHeaderText('Menu');
        } else if (pathname === '/admin/orders') {
            setHeaderText('Orders');
        } else if (pathname.includes('/admin/order/')) {
            setHeaderText('Orders');
        } else if (pathname === '/admin/Home') {
            setHeaderText('Dashboard');
        } else if (pathname === '/admin/ordersDND') {
            setHeaderText('Order Drag and Drop');
        } else if (pathname === '/admin/master/Create') {
            setHeaderText('Add Item');
        } else if (pathname === '/admin/singleMenu') {
            setHeaderText('Single Item');
        } else if (pathname === '/admin/CustomerList') {
            setHeaderText('Customers');
        } else if (pathname === '/admin/AddCustomer') {
            setHeaderText('Create Customer')
        } else if (pathname === '/admin/Restaurant/Create') {
            setHeaderText('Create Restaurants')
        } else if (pathname === '/admin/Restaurant/Branch/Create') {
            setHeaderText('Create Branch')
        } else if (pathname === '/admin/Restaurant') {
            setHeaderText('Restaurants')
        } else if (pathname === '/admin/employee') {
            setHeaderText('Employees')
        } else if (pathname === '/admin/Addemployee') {
            setHeaderText('Add Employees')
        } else if (pathname === '/admin/itemMaster/modifier') {
            setHeaderText('Add Modifier')
        } else if (pathname === '/admin/itemMaster/addsOns') {
            setHeaderText('Add Ons')
        } else if (pathname === '/admin/itemMaster/Combos') {
            setHeaderText('Add Combo')
        } else if (pathname === '/admin/itemMaster/addCategory') {
            setHeaderText('Item Categories')
        } else if (pathname === '/admin/itemMaster/subCategory') {
            setHeaderText('Item Sub-Categories')
        } else if (pathname === '/admin/master/Variants') {
            setHeaderText('Item Variants')
        } else if (pathname === '/admin/itemMaster/UploadFile') {
            setHeaderText('CSV File Management')
        } else if (pathname === '/admin/master/CurrencyManagement') {
            setHeaderText('Currency Management')
        } else if (pathname === '/admin/master/TaxManagement') {
            setHeaderText('Tax Management')
        } else if (pathname === '/admin/master/Table') {
            setHeaderText('Table Management')
        } else if (pathname === '/admin/master/Discount') {
            setHeaderText('Discount Management')
        } else if (pathname === '/admin/Payment') {
            setHeaderText('Payment')
        } else if (pathname === '/admin/Payment') {
            setHeaderText('Payment')
        } else if (pathname === '/admin/master/Kitchen') {
            setHeaderText('Kitchen Master')
        } else if (menuRegex.test(pathname)) {
            setHeaderText('Item Details')
        } else if (updateRegex.test(pathname)) {
            setHeaderText('Update Item')
        } else if (pathname === '/admin/Reports/home') {
            setHeaderText('Total Sale')
        } else if (pathname === '/admin/Reports/SaleDetails') {
            setHeaderText('Sale Detail Report')
        } else if (pathname === '/admin/Reports/Order') {
            setHeaderText('Total Orders and sale')
        } else if (pathname === '/admin/Reports/OrderHistory') {
            setHeaderText('Orders History')
        } else if (pathname === '/admin/Reports/SaleReport') {
            setHeaderText('Items Sale Report')
        } else if (pathname === '/admin/Reports/3rdParty') {
            setHeaderText('Order List By Third Party')
        } else if (pathname === '/admin/Reports/Tax') {
            setHeaderText('Tax Report Detail')
        } else if (pathname === '/admin/Reports/Delivery') {
            setHeaderText('Order Delivery Report')
        } else if (pathname === '/admin/Reports/Cash') {
            setHeaderText('Cash In Out Report')
        } else if (pathname === '/admin/Reports/Table') {
            setHeaderText('Table Report')
        } else if (pathname === '/admin/Reports/SaleReport/chart') {
            setHeaderText('Top Items Sale')
        } else if (pathname === '/admin/Reports/3rdParty/chart') {
            setHeaderText('Top Delivery Sale')
        } else if (pathname === '/admin/Reports/Grid') {
            setHeaderText('Total Sale')
        } else if (pathname === '/admin/master/UserRole') {
            setHeaderText('User Role')
        } else if (pathname === '/admin/master/Promotion') {
            setHeaderText('Promotion Master')
        } else if (pathname === '/admin/master/area') {
            setHeaderText('Table Area Master')
        } else if (pathname === '/admin/master/userMaster/access') {
            setHeaderText('User Permissions')
        } else if (pathname === '/admin/master/userMaster/create') {
            setHeaderText('Create User')
        } else if (pathname === '/admin/UserManagement/userMaster/users') {
            setHeaderText('User List')
        } else if (pathname === '/admin/master/paymentMethod') {
            setHeaderText('Payment Method')
        } else if (pathname === '/admin/UserManagement/userMaster/UserClaim') {
            setHeaderText('User Managment')
        } else if (pathname === '/admin/UserManagement/userMaster/UserRole') {
            setHeaderText('User Role')
        } else if (pathname === '/admin/itemMaster/CounterMaster') {
            setHeaderText('Counter Master')
        } else if (pathname === '/admin/itemMaster/Variants') {
            setHeaderText('Variant Master')
        } else if (pathname === '/admin/Setting/Profile') {
            setHeaderText(' Settings')
        } else if (pathname === '/admin/Setting/Notification') {
            setHeaderText(' Settings')
        } else if (pathname === '/admin/Setting/Currency') {
            setHeaderText(' Settings')
        } else if (pathname === '/admin/master/userMaster/users') {
            setHeaderText(' User Master')
        }
        else if (pathname === '/admin/master/userMaster/list') {
            setHeaderText(' Users List')
        }
        else if (pathname === '/admin/master/userMaster/ManageUser') {
            setHeaderText(' Manage Users Role')
        }
        else if (pathname === '/admin/master/userMaster/ManageUser/:userId') {
            setHeaderText(' Manage Users Role')
        }
        else if (pathname === '/admin/master/userMaster/rights') {
            setHeaderText(' Users Rights')
        }
       else{
            setHeaderText('Dashboard');
        }
    }, [location]);

    const handleOut = () => {
        dispatch(logout())
        localStorage.clear()
        navigate('/Login')
        window.location.reload(false);
    }
    return (
        <nav class="navbar-expand-lg  mainBG ">
            <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse d-flex justify-content-between" id="navbarSupportedContent">
                    <div className='dnon'>
                        <div className='Headtxt'>{headerText}</div>
                        <div className='headtxt2'></div>
                    </div>
                    <div className='d-flex gap-2 pad'>
                        <div className='gap-2 d-flex'>
                            {/* <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                isSearchable={false}
                                defaultValue={filteredDenominations?.code ? { value: filteredDenominations.code, label: filteredDenominations.code } : null}
                                className="Currency-dropdown mbl-dropdown"
                                // options={currency}
                                options={currency?.map((currency) => ({ value: currency, label: currency?.code }))}
                                onChange={(e) => handle_Currency(e)}
                            /> */}
                            <div className='iconBG'><Notification /></div>
                            <div className='iconBG'><Chat /></div>
                            {/* <div className='iconBG'><Setting /></div> */}
                        </div>
                        <div className="profilebox dropdown">
                            Admin
                            <div className="dropdown-content-stats pt-1">
                                <Link to="/admin/Setting/Profile" className="p-2">Setting</Link>
                                <Link onClick={handleOut} className="p-2">Log Out</Link>
                            </div>
                        </div>
                        <div className='adminprofileimgbox boxicon'>
                            {/* <img src={Profile} alt='profile' className='adminImg' /> */}
                            <Setting className='' size={25} />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

// export default AdminHeader

const mapStatetoProps = (state) => state;
// const mapDispatchToProps = (dispatch) => ({
//   toggleDark: (payload) => dispatch(cure()),
// });
export default connect(mapStatetoProps)(AdminHeader);