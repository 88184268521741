import React, { useState, useRef, useEffect } from 'react';
import "../homes.css";
import dahboard from "../../../assets/images/darhboard.png";
import MenuItemCard from './menuItemCard';
import "./menuItemcard.css"
import MenuItemTable from "./menuItemTable";
import AddItembox from './addItems/addItembox';
import ApiService from '../../../services/apiService';
import CategoryNavBar from './categoryNav/categoryNav';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ReactComponent as Items } from '../../../assets/images/svgIcons/menu.svg';
import MenuGrid from './grid/menuGrid';

const Menu = () => {

    const location = useLocation();
    const [menu, setMenu] = useState([]);
    const [view, setView] = useState('card');
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        GetMenu();
    }, [searchTerm]);

    useEffect(() => {
        if (location.state && location.state.categorymenu) {
            setMenu(location.state.categorymenu);
        }
        else {
            setMenu(null);
        }
        }, [location]);
    const toggleView = () => {
        setView(view === 'card' ? 'table' : 'card');
    };

    const GetMenu = async () => {
        try {
            debugger
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_MENU', parameters);
            if (res?.data?.statusCode === 200 || res?.data?.statusCode === 201) {
                setMenu(res.data.result);

            }
            else if (res?.data?.statusCode === 204) {
                setMenu([]);

            }
        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    };

    return (
        <div className='container-fluid p-lg-5 m-0'>
            <div className='pt-3'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 mbl-center'>
                        <div className='d-flex gap-4 pt-3'>
                            <input
                                type='Search'
                                className='form-control w-50'
                                placeholder='Search'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <img onClick={toggleView} src={dahboard} className='filter' />
                        </div>
                    </div>

                    <div className='col-lg-6 col-md-6'>
                        <div className='d-flex justify-content-end pt-top mbl-center'>
                            <AddItembox />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="menu">
                        <div className="pt-5">
                            <CategoryNavBar />
                        </div>

                        <div className="menu-items pt-5">
                            {view === 'table' &&
                                <div className='table-responsive'>
                                    <MenuGrid val={menu} GetMenu={GetMenu} />
                                </div>
                            }
                            {menu?.length > 0 ?
                                <div className="grid">
                                    {
                                        view === 'card' && menu.map((item, itemIndex) => (
                                            <MenuItemCard
                                                key={itemIndex}
                                                {...item}
                                                item={item}
                                                GetMenu={GetMenu}
                                            />
                                        ))
                                    }
                                </div>
                                :
                                <div className='center p-lg-5'>
                                    <h5>No Item in this Category</h5>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
