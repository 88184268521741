import Button from 'react-bootstrap/Button';
import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ApiService from '../../../services/apiService';
import Modal from 'react-bootstrap/Modal';
import "../AdminCustomer/customer.css";
import EmployeeGrid from './gridTable/grid';
import POSPagination from '../pagination/pagination';

const EmployeeList = ({ selectedRow }) => {
    const [show, setShow] = useState(false);
    const [CostValue, setDelCost] = useState("");
    const [data, setData] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [pagination, setPagination] = useState();
    const [pageSize, setPageSize] = useState(20);

    // Handle selecting a row
    const handleSelectedRow = (rowData) => {
        setSelectedRowData(rowData);
        if (selectedRow) {
            selectedRow(rowData); // Pass the selected row data back
        }
    };

    // Close modal
    const handleClose = () => setShow(false);

    // Fetch employees on mount
    useEffect(() => {
        GetCostumer();
    }, []);

    // Delete employee logic
    const DeleteCostumer = async () => {
        const apiService = new ApiService();
        const parameters = `${CostValue?.id}`;
        try {
            const res = await apiService.delete("DELETE_EMPLOYEE", parameters);
            if (res?.data?.statusCode === 204) {
                setShow(false);
                GetCostumer(); // Refresh the employee list
                toast.success("Employee Successfully Deleted");
            } else {
                toast.warning("Waiting for Employee update");
            }
        } catch (error) {
            toast.error("Error deleting employee");
        }
    };

    // Fetch employee data
    const GetCostumer = async (newPageNo = pagination?.currentPage) => {
        const apiService = new ApiService();
        try {
            const params = new URLSearchParams();
            params.append('PageNo', newPageNo || 1);
            params.append('PageSize', pageSize || 20);
            const res = await apiService.getApiParamater('GET_EMPLOYEE', `?${params.toString()}`);
            setPagination(res.data.paginationData);
            setData(res?.data?.result.reverse());
        } catch (error) {
            console.error("Error fetching employee data:", error);
        }
    };

    return (
        <div className="">
            <div className="mt-4 m-0 POsgridshad">
                <EmployeeGrid 
                    employee={data} 
                    onRowSelect={handleSelectedRow}
                />
                <POSPagination 
                    setPageSize={setPageSize} 
                    pagination={pagination} 
                    getItems={GetCostumer} 
                />
            </div>
            <Modal show={show} onHide={handleClose} value={CostValue}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Employee</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete {CostValue?.name}?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => DeleteCostumer(CostValue)}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EmployeeList;
