import React, { useState, useEffect } from "react";
import "./sideBar.css";
import pro360Logo from "../../../assets/images/pro360.png";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Dashboard } from '../../../assets/images/svgIcons/Dashboard.svg';
import { ReactComponent as DashboardInactive } from '../../../assets/images/svgIcons/DashboardinActive.svg';
import { ReactComponent as Menu } from '../../../assets/images/svgIcons/menu.svg';
import { ReactComponent as Menuinactive } from '../../../assets/images/svgIcons/menu-inactive.svg';
import { ReactComponent as Order } from '../../../assets/images/svgIcons/order.svg';
import { ReactComponent as OrderInactive } from '../../../assets/images/svgIcons/order-inactive.svg';
import { ReactComponent as Costumer } from '../../../assets/images/svgIcons/costumer.svg';
import { ReactComponent as Employee } from '../../../assets/images/svgIcons/employee.svg';
import { ReactComponent as EmployeeActive } from '../../../assets/images/svgIcons/Employee-Inactive.svg';
import { ReactComponent as Reports } from '../../../assets/images/svgIcons/reports.svg';
import { ReactComponent as Payment } from '../../../assets/images/svgIcons/payment.svg';
import { ReactComponent as RestaurantActive } from '../../../assets/images/svgIcons/Restaurants_Active.svg';
import { ReactComponent as RestaurantInActive } from '../../../assets/images/svgIcons/Restaurants-InActive.svg';
import { ReactComponent as MasterActive } from '../../../assets/images/svgIcons/MasterActive.svg';
import { ReactComponent as MAsterInActive } from '../../../assets/images/svgIcons/Master.svg';
import { ReactComponent as ItemMaster } from '../../../assets/images/svgIcons/ItemMaster.svg';
import { ReactComponent as UserRole } from '../../../assets/images/svgIcons/UserRole.svg';
import { Link } from "react-router-dom";
import { FaCog, FaPlus, FaTh, FaLayerGroup, FaPuzzlePiece, FaMoneyBillWave, FaPizzaSlice, FaFileUpload, FaUserClock, FaUsersCog } from 'react-icons/fa';
import { BsCurrencyExchange } from "react-icons/bs";
import { FaKitchenSet, FaTruckDroplet } from "react-icons/fa6";
import { MdOutlineTableBar, MdOutlinePayments } from "react-icons/md";
import { TbSpeakerphone, TbTruckDelivery } from "react-icons/tb";
import { CiDiscount1 } from "react-icons/ci";
import userManagement from "../../../services/userManagement"
import ReceiptModal from "../reports/xReport";
import { IoPushOutline } from "react-icons/io5";
const SideBar = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleLinkClick = (e) => {
        e.preventDefault(); // Prevent the default navigation behavior
        setIsModalVisible(true); // Open the modal
    };
    const location = useLocation();
    const [showMasterSubMenu, setShowMasterSubMenu] = useState(false);
    const [showUserMasterSubMenu, setShowUserMasterSubMenu] = useState(false);
    const [permissions, setPermissions] = useState({}); // Dynamic permissions
     const reportsConfig = [
        { key: "Total Sale Report", label: "Total Sale Report", icon: <FaPizzaSlice />, route: "/admin/Reports/TotalSale" },
        { key: "X Report", label: "X Report", icon: <FaPlus />, route: "/admin/Reports/XReport" },
        { key: "Delivery Report", label: "Delivery Report", icon: <BsCurrencyExchange />, route: "/admin/Reports/Delivery" },
        { key: "Orders Report", label: "Orders Report", icon: <FaCog />, route: "/admin/Reports/Orders" },
        { key: "Cash In/Out Report", label: "Cash In/Out Report", icon: <FaFileUpload />, route: "/admin/Reports/CashInOut" },
        { key: "Table Sale Report", label: "Table Sale Report", icon: <MdOutlineTableBar />, route: "/admin/Reports/TableSale" },
        { key: "Tax Report", label: "Tax Report", icon: <FaMoneyBillWave />, route: "/admin/Reports/Tax" },
        { key: "Item Sale Report", label: "Item Sale Report", icon: <FaLayerGroup />, route: "/admin/Reports/ItemSale" },
    ];

    useEffect(() => {
        const fetchPermissions = () => {
            const token = localStorage.getItem("Token");
            const userPermissions = userManagement?.getUserCrud(token);

            // Build permissions dynamically for all reports
            const newPermissions = reportsConfig.reduce((acc, report) => {
                acc[report.key] = userPermissions?.includes(`${report.key}.add`) && userPermissions.includes(`${report.key}.query`);
                return acc;
            }, {});

            setPermissions(newPermissions);
            debugger
        };

        fetchPermissions();
    }, []);

    return (
        <nav class="navbar navbar-expand-md  fixed-left ">
            <a href="/home" class="navbar-brand center" className="logo"><img style={{ height: "26px" }} src={pro360Logo} alt="Logo" /></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
                aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse ovrflo" id="navbarsExampleDefault">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <NavLink to="/home" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/home" ? (
                                <>
                                    <Dashboard size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt" >Home</h6>
                                    </a>

                                </>
                            ) : (
                                <>
                                    <DashboardInactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Home</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/menu" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/menu" ? (
                                <>
                                    <Menuinactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Menus</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Menu size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Menus</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/orders" className="nav-link align-middle" activeClassName="active">

                            {location.pathname === "/admin/orders" ? (
                                <>
                                    <OrderInactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Orders</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Order size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Orders</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <NavLink to="/admin/Payment" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/Payment" ? (
                                <>
                                    <Menuinactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Payment</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Payment size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Payment</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/CustomerList" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/CustomerList" ? (
                                <>
                                    <Menuinactive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Customer</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Costumer size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Customer</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/employee" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/employee" ? (
                                <>
                                    <EmployeeActive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Employees</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <Employee size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Employees</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li class="nav-item">
                        <NavLink to="/admin/posting" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/posting" ? (
                                <>
                                    <IoPushOutline size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Posting</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <IoPushOutline size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Posting</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                </ul>
                <ul class="navbar-nav">


                    <li class="nav-item">
                        <NavLink to="/admin/Restaurant" className="nav-link align-middle" activeClassName="active">
                            {location.pathname === "/admin/Restaurant" ? (
                                <>
                                    <RestaurantActive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  activetxt">Restaurants</h6>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <RestaurantInActive size={25} className="" />
                                    <a className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline  inactivetxt">Restaurants</h6>
                                    </a>
                                </>
                            )}
                        </NavLink>
                    </li>
                    <li className="nav-item dropdown">
            <div className={`nav-link align-middle ${location.pathname.startsWith("/admin/Reports/home/") ? "active" : ""}`}>
                <FaPizzaSlice size={25} />
                <div className="nav-link align-middle">
                    <h6 className="d-none d-sm-inline">
                        {location.pathname.startsWith("/admin/Reports/home/") ? "Reports & Analytics" : "Reports & Analytics"}
                    </h6>
                </div>
            </div>
            <div className="dropdown-content-ab">
                {reportsConfig.map((report) =>
                    permissions[report.key] ? (
                        <Link to={report.route} className="p-2" key={report.key}>
                            {report.icon} {report.label}
                        </Link>
                    ) : null
                )}
            </div>
        </li>
                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <div className={`nav-link align-middle ${location.pathname.startsWith("/admin/master/") && !showMasterSubMenu ? "active" : ""}`}>
                            {location.pathname.startsWith("/admin/master/") ? (
                                <>
                                    <MasterActive size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline activetxt">Master</h6>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <MAsterInActive size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline inactivetxt">Master</h6>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="dropdown-content-ex">
                            <Link to="/admin/master/CurrencyManagement" className="p-2"><FaMoneyBillWave /> Currency Management</Link>
                            <Link to="/admin/master/TaxManagement" className="p-2"><BsCurrencyExchange /> Tax Management</Link>
                            <Link to="/admin/master/paymentMethod" className="p-2"><MdOutlinePayments /> Payment Master</Link>
                            <Link to="/admin/master/Table" className="p-2"><MdOutlineTableBar /> Table Master</Link>
                            <Link to="/admin/master/area" className="p-2"><MdOutlineTableBar /> Table Area Master</Link>
                            <Link to="/admin/master/Kitchen" className="p-2"><FaKitchenSet /> Kitchen Master</Link>
                            <Link to="/admin/master/Discount" className="p-2"><CiDiscount1 /> Discount Master</Link>
                            <Link to="/admin/master/Promotion" className="p-2"><TbSpeakerphone /> Promotion Master</Link>
                            <Link to="/admin/master/userMaster/users" state={{ userId: 'users' }} className="p-2">
                                <FaUsersCog /> User Master
                            </Link>
                            <Link to="/admin/master/userMaster/ManageUser" className="p-2">
                                <FaUsersCog /> Manage User
                            </Link>
                            <Link to="/admin/master/userMaster/ManageRole" className="p-2">
                                <FaUsersCog /> Manage Role
                            </Link>
                            <Link to="/admin/Delivery/Create" className="p-2"><TbTruckDelivery /> Delivery Platforms</Link>


                        </div>
                    </li>


                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <div className={`nav-link align-middle ${location.pathname.startsWith("/admin/UserManagement/") && !showUserMasterSubMenu ? "active" : ""}`}>
                            {location.pathname.startsWith("/admin/UserManagement/") ? (
                                <>
                                    <UserRole size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline activetxt">User Management</h6>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <UserRole size={25} className=" " />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline inactivetxt">User Management</h6>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="dropdown-content-ex">

                            <Link to="/admin/UserManagement/userMaster/UserClaim" className="p-2"><FaUserClock /> User Claim Master</Link>
                            <Link to="/admin/UserManagement/userMaster/UserRole" className="p-2"><FaUserClock /> User Role</Link>
                        </div>
                    </li>

                </ul>
                <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                        <div className={`nav-link align-middle ${location.pathname.startsWith("/admin/itemMaster/") && !showMasterSubMenu ? "active" : ""}`}>
                            {location.pathname.startsWith("/admin/itemMaster/") ? (
                                <>
                                    <ItemMaster size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline activetxt">Item Master</h6>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <ItemMaster active size={25} className="" />
                                    <div className="nav-link align-middle">
                                        <h6 className="d-none d-sm-inline inactivetxt">Item Master</h6>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="dropdown-content-ex">
                            <Link to="/admin/itemMaster/Combos" className="p-2"><FaPizzaSlice /> Combo Master</Link>
                            <Link to="/admin/itemMaster/addsOns" className="p-2"><FaPlus /> AddOn Master</Link>
                            <Link to="/admin/itemMaster/modifier" className="p-2"><FaCog /> Modifier Master</Link>
                            <Link to="/admin/itemMaster/addCategory" className="p-2"><FaTh /> Category Master</Link>
                            <Link to="/admin/itemMaster/subCategory" className="p-2"><FaLayerGroup /> SubCategory Master</Link>
                            <Link to="/admin/itemMaster/UploadFile" className="p-2"><FaFileUpload /> Menu File Master</Link>
                            <Link to="/admin/itemMaster/Variants" className="p-2"><FaFileUpload /> Variant Master</Link>
                            <Link to="/admin/itemMaster/CounterMaster" className="p-2"><FaFileUpload /> Counter Master</Link>
                        </div>
                    </li>
                </ul>
            </div>
            {isModalVisible &&
                <ReceiptModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                />
            }

        </nav>

    );

}
export default SideBar