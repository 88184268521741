import React, { useState, useEffect } from 'react';
import ApiService from '../../../services/apiService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import AdminHeader from '../header/adminHeader';
import { GetCountries, GetState, GetCity, GetLanguages, } from "react-country-state-city";
import { Toast } from 'react-bootstrap';
const AddCostumer = () => {

    let navigate = useNavigate();
    const location = useLocation();
    const [Restaurants, setRestaurant] = useState([]);
    const [branches, setBranches] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);

    const [countriesList, setCountries] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [formData, setFormData] = useState({
        id: '',
        name: "",
        identityCardNumber: "",
        gender: '',
        nationality: '',
        dateOfBirth: "",
        faxNumber: "",
        branchCode: 0,
        area: "",
        address: "",
        phoneNumber: "",
        email: "",
        enableLoyalty: true,
        customerTaxId: 0,
        customerCategoryId: 0,
        address01: "",
        address02: "",
        status: true,
        creationDate: "",
        mobileNumber: 0,
        password: 'Abc123'
    });
    const Gender = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ];
    useEffect(() => {
        GetCountries().then((result) => {
            setCountries(result);
        });
    }, []);
    useEffect(() => {
        GetState(selectedCountry?.value?.id).then((result) => {
            setStateList(result);
        });
    }, [selectedCountry]);
    useEffect(() => {
        GetCity(selectedCountry?.value?.id, selectedState?.value?.id).then((result) => {
            setCityList(result);
        });
    }, [selectedState]);

    const Nationality = [
        { value: '0', label: 'Afghan' },
        { value: '1', label: 'Albanian' },
        { value: '2', label: 'Algerian' },
        { value: '3', label: 'American' },
        { value: '4', label: 'Andorran' },
        { value: '5', label: 'Angolan' },
        { value: '6', label: 'Antiguans' },
        { value: '7', label: 'Argentinean' },
        { value: '8', label: 'Armenian' },
        { value: '9', label: 'Australian' },
        { value: '10', label: 'Emirian' },
        { value: '11', label: 'Indian' },
        { value: '12', label: 'Pakistani' },
    ];
    useEffect(() => {
        getRestaurant()
    }, []);
    useEffect(() => {
        if (location?.state && location?.state?.val) {
            const { val } = location.state;
            setFormData({
                name: val?.name || "",
                id: val?.id || "",
                identityCardNumber: val?.identityCardNumber || "",
                gender: val?.gender || "",
                dateOfBirth: moment(val?.dateOfBirth).format("YYYY-MM-DD") || "",
                faxNumber: val?.faxNumber || "",
                branchCode: val?.branchCode || "",
                area: val?.area || "",
                address: val?.address || "",
                phoneNumber: val?.phoneNumber || "",
                email: val?.email || "",
                enableLoyalty: val?.enableLoyalty || 0,
            });
        }
    }, [location.state]);
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleGenderChange = (selectedOption) => {
        setFormData({ ...formData, gender: selectedOption?.value });
    }
    const handleNationalityChange = (selectedOption) => {
        setFormData({ ...formData, nationality: selectedOption?.value });
    }
    const handleAddCostumer = async (e) => {
        e.preventDefault();
        try {
            if (!formData.name && (!formData.password) && (!formData.email || !formData.phoneNumber)) {
                return toast.warn('Please enter required name and email/phone')
            }
            else {

                const body = {
                    customerTaxId: formData?.customerTaxId,
                    customerCategoryId: 1,
                    address1: formData?.address,
                    address2: formData?.address01,
                    address3: formData?.address02,
                    status: true,
                    name: formData.name,
                    identityCardNumber: formData.identityCardNumber,
                    gender: formData.gender,
                    dateOfBirth: moment(formData.dateOfBirth).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                    faxNumber: formData?.faxNumber,
                    mobileNumber: formData?.phoneNumber,
                    area: formData?.area,
                    mobileNumber: formData?.phoneNumber || formData?.email ,
                    email: formData?.email || formData?.phoneNumber,
                    enableLoyalty: true,
                    phoneNo1: formData?.phoneNumber,
                    password: formData?.password,

                }
                const apiService = new ApiService();
                let response = await apiService.post("ADD_COSTUMER", body);
                if (response.data.statusCode === 201) {
                    toast.success('Customer created successfully');
                    navigate(`/admin/CustomerList`);

                } else if (response?.data?.statusCode === 400) {
                    toast.warning(response?.data?.message);
                }

            }

        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleUpdateCostumer = async (e) => {
        e.preventDefault();
        try {
            const body = {
                id: formData.id,
                name: formData.name,
                identityCardNumber: formData.identityCardNumber,
                gender: formData.gender,
                dateOfBirth: moment(formData.dateOfBirth).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                faxNumber: formData?.faxNumber,
                mobileNumber: formData?.phoneNumber,
                branchCode: formData?.branchCode,
                area: formData?.area,
                address: formData?.address,
                phoneNumber: formData?.phoneNumber,
                email: formData?.email,
                enableLoyalty: formData?.enableLoyalty,
            }
            const apiService = new ApiService();
            let response = await apiService.put("UPDATE_COSTUMER", body);


            if (response.data.statusCode === 204) {
                toast.success('Customer Updated successfully');
                navigate(`/admin/AddCustomer`);

            } else {
                toast.warning('Waiting for menu update');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const getRestaurant = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_RESTAURANT');
            const filter = res.data.result.filter(res => res.isActive == true);
            setRestaurant(filter);

        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    }
    const getBranches = async (restaurantId) => {
        try {
            const apiService = new ApiService();
            let param = `?restaurantId=${restaurantId}`
            let res = await apiService.getApiParamater("GET_BRANCH", param);

            const filter = res.data.result.filter(res => res.isActive == true);
            setBranches(filter);
        } catch (error) {
            console.error('Error fetching branches:', error);
        }
    }
    const handleRestaurantChange = (selectedOption) => {
        setSelectedRestaurant(selectedOption);
        getBranches(selectedOption?.value?.id);
        setFormData({ ...formData, restaurantId: selectedOption?.value?.id });
    }
    const handleBranchChange = (selectedOption) => {
        setSelectedBranch(selectedOption);
        setFormData({ ...formData, branchId: selectedOption?.value?.id });
    }
    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        setSelectedState(null);
        setSelectedCity(null);
    };
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        setSelectedCity(null);
    };
    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };
    return (
        <div className='p-lg-5 container'>
            <h2>Add Customer</h2>
            <form  >
                <div className='py-lg-5'>
                    <div className=''>
                        <h5>Basic Information</h5>
                        <div className='brderbx p-lg-5'>
                            <div className="form-group row justify-content-between pb-3">
                                {/* userName */}
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom01" className="form-label">Name *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom01"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom06" className="form-label">Phone Number *</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="validationCustom06"
                                        name="phoneNumber"
                                        value={formData?.phoneNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom13" className="form-label">Date Of Birth</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="validationCustom13"
                                        name="dateOfBirth"
                                        value={formData.dateOfBirth}
                                        onChange={handleInputChange}

                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                {/* Gender */}

                                {/* firstName */}
                                {/* <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom02" className="form-label">First Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom02"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div> */}
                                {/* LastName */}
                                {/* <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom15" className="form-label">Last Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom15"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div> */}
                                {/* Gender */}
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom09" className="form-label">
                                        Gender
                                    </label>
                                    <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                        defaultValue={formData.gender}
                                        onChange={handleGenderChange}
                                        options={Gender}
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-group row pb-3">
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom07" className="form-label">Tax Id</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="validationCustom07"
                                        name="customerTaxId"
                                        value={formData.customerTaxId}
                                        onChange={handleInputChange}

                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="validationCustom08" className="form-label">Password *</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="password"
                                        name="password"
                                        value={formData?.password}
                                        onChange={handleInputChange}
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='py-4'>
                        <h5>Contact Information</h5>
                        <div className='brderbx p-lg-5'>
                            <div className="form-group row justify-content-between pb-3">
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom03" className="form-label">Home Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom03"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom04" className="form-label">Office Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom04"
                                        name="address01"
                                        value={formData?.address01}
                                        onChange={handleInputChange}

                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom05" className="form-label">Address 2</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom05"
                                        name="address02"
                                        value={formData.address02}
                                        onChange={handleInputChange}

                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom12" className="form-label">Area</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom12"
                                        name="area"
                                        value={formData?.area}
                                        onChange={handleInputChange}

                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row justify-content-between pb-3">
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom21" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="validationCustom21"
                                        name="email"
                                        value={formData?.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom17" className="form-label">
                                        Nationality
                                    </label>
                                    <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                        defaultValue={formData.nationality}
                                        onChange={handleNationalityChange}
                                        options={Nationality}
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
            
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom10" className="form-label">Fax Number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="validationCustom10"
                                        name="faxNumber"
                                        value={formData?.faxNumber}
                                        onChange={handleInputChange}

                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom20" className="form-label">Mobile Number</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="validationCustom20"
                                        name="mobileNumber"
                                        value={formData.mobileNumber}
                                        onChange={handleInputChange}
                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row justify-content-between pb-3">
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom16" className="form-label">Postal Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom16"
                                        name="postalCode"
                                        value={formData?.postalCode}
                                        onChange={handleInputChange}

                                    />
                                    <div className="valid-feedback">
                                        Looks good!
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom18" className="form-label">Country</label>
                                    <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                        value={selectedCountry}
                                        onChange={handleCountryChange}
                                        options={countriesList?.map(country => ({ value: country, label: country?.name }))}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom19" className="form-label">State</label>
                                    <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                        value={selectedState}
                                        onChange={handleStateChange}
                                        options={stateList ? stateList?.map(state => ({ value: state, label: state?.name })) : []}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom22" className="form-label">City</label>
                                    <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                        value={selectedCity}
                                        onChange={handleCityChange}
                                        options={cityList ? cityList?.map(city => ({ value: city, label: city?.name })) : []}
                                    />
                                </div>
                            </div>
                            <div className="form-group row pb-3">
                                <div className="col-md-3 col-lg-3">
                                    <label htmlFor="validationCustom11" className="form-label">Restaurant</label>
                                    <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                        id="restaurantSelect"
                                        value={selectedRestaurant}
                                        onChange={(selectedOption) => handleRestaurantChange(selectedOption)}
                                        options={Restaurants?.map(restaurant => ({ value: restaurant, label: restaurant.name }))}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="validationCustom11" className="form-label">Branch</label>
                                    <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                        id="branchSelect"
                                        value={branches ? selectedBranch : ''}
                                        onChange={handleBranchChange}
                                        options={branches?.map(branch => ({ value: branch, label: branch.branchName }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-5'>
                    {location?.state?.val?.name ?
                        <div className='end'>
                            <button className='addItem-btn' type="submit" onClick={handleUpdateCostumer}>Update</button>
                        </div> :
                        <div className='end'>
                            <button className='addItem-btn' type="submit" onClick={handleAddCostumer}>Save</button>
                        </div>
                    }
                </div>
            </form>
        </div>
    )
}
export default AddCostumer