import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ApiService from '../../../services/apiService';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { updateDiscount } from '../../../../Redux/actions/cartAction';
import { getCartData, getComboData, getOrderEditData } from '../../../../Redux/selecters';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { updateOrderDiscount } from '../../../../Redux/actions/orderEditAction';

const DiscountModal = ({ closeDiscountModal }) => {
    const [discounts, setDiscounts] = useState([]);
    const [orderType, setOrderType] = useState('');
    const [items, setItems] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [promotion, setPromotion] = useState([]);
    const [manualDiscountActive, setManualDiscountActive] = useState(false);
    const [activeType, setActiveType] = useState("Amount");
    const [manualDiscountValue, setManualDiscountValue] = useState('');
    const [discountId, setDiscountId] = useState();
    const [promotionId, setPromotionId] = useState();



    const data = useSelector(getOrderEditData);
    const cartData = useSelector(getCartData);
    const comboCart = useSelector(getComboData);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        Category: 0,
        voucherCode: "",
    });
    const DiscoutsStatic = [
        { value: 'Category', label: 'Category' },
        { value: 'ItemDiscount', label: 'Item Discount' },
        { value: 'totalDiscount', label: 'Total Order Discount' },
        { value: 'Promotion', label: 'Promotion' },
    ];
    const Category = [
        { value: '1', label: 'Voucher' },
        { value: '2', label: 'Promotion' },
    ];
    useEffect(() => {
        fetchDiscounts();
        fetchPromotion()
    }, []);

    useEffect(() => {
        if (orderType?.value === 'Category') {
            const categoryIds = cartData.map(item => item.itemData.categoryId);
            fetchCategories(categoryIds);
            setItems([]);
        } else if (orderType?.value === 'ItemDiscount') {
            if (cartData.length > 0) {
                setItems(cartData.map(item => item.itemData));
            } else if (data.length > 0) {
                setItems(data.map(item => item.itemData));
            }
        } else if (manualDiscountActive) {
            if (cartData.length > 0) {
                setItems(cartData.map(item => item.itemData));
            } else if (data.length > 0) {
                setItems(data.map(item => item.itemData));
            }
        } else if (orderType?.value === 'Promotion') {
            if (cartData.length > 0) {
                setItems(cartData.map(item => item.itemData));
            } else if (data.length > 0) {
                setItems(data.map(item => item.itemData));
            }
        }

    }, [orderType, cartData]);
    const fetchDiscounts = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_DISCOUNT');
            setDiscounts(res?.data.result);
        } catch (error) {
            console.error('Error fetching discounts:', error);
        }
    };
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleCategoryChange = (selectedOption) => {
        setFormData({ ...formData, Category: selectedOption?.value });
    }
    const fetchCategories = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_CATEGORY');
            const allCategories = res.data.result;
            debugger
            const filteredCategories = allCategories?.filter(category => {
                if (cartData.length > 0) {
                    return cartData?.some(item => item?.itemData.categoryId === category.id);
                } else if (data.length > 0) {
                    return data?.some(item => item?.itemData.categoryId === category.id);
                } else {
                }
            });
            setCategories(filteredCategories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const fetchPromotion = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get('GET_PROMOTION');
            const allPromotion = res.data.result;
            setPromotion(allPromotion);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };
    const handleOrderType = (selectedType) => {
        setOrderType(selectedType);
    };
    const handleItemSelection = (itemId) => {
        debugger
        const updatedSelectedCategories = [...selectedCategories];
        if (updatedSelectedCategories.includes(itemId)) {
            setSelectedCategories(updatedSelectedCategories.filter(id => id !== itemId));
        } else {
            setSelectedCategories([...updatedSelectedCategories, itemId]);
        }
    };
    const handleCategorySelection = (categoryId) => {
        const updatedSelectedCategories = [...selectedCategories];
        if (updatedSelectedCategories.includes(categoryId)) {
            setSelectedCategories(updatedSelectedCategories.filter(id => id !== categoryId));
        } else {
            setSelectedCategories([...updatedSelectedCategories, categoryId]);
        }
    };
const handleManualDiscountItemChange = (selectedItem) => {
  if (activeType === "Amount") {
    setManualDiscountValue(selectedItem?.value?.amount || selectedItem?.value?.percentage);
    setDiscountId(selectedItem?.value?.id);
  } else if (activeType === "Percentage") {
    setManualDiscountValue(selectedItem?.value?.percentage);
    setDiscountId(selectedItem?.value?.id);
  }
};

    const handleManualDiscountValueChange = (e) => {
        if (activeType === "Amount") {
            setManualDiscountValue(e.target.value)
        } else if (activeType === "Percentage") {
            setManualDiscountValue(e.target.value)
        }
    };
    const handleVoucherCode = (selectedItem) => {
        if (activeType === "Amount") {
            setManualDiscountValue(selectedItem?.value?.discountAmount)
            setPromotionId(selectedItem?.value?.promotionID)
        } else if (activeType === "Percentage") {
            setManualDiscountValue(selectedItem?.value?.discountPercentage)
            setPromotionId(selectedItem?.value?.promotionID)

        }
    }
    const handleConfirm = () => {
debugger
        if (orderType?.value === 'Category') {
            
            const updatedCartData = cartData.map(item => {
                if (selectedCategories.includes(item.itemData.categoryId)) {
                    return {
                        ...item,
                        itemData: {
                            ...item.itemData,
                            discount: manualDiscountValue
                        }
                    };
                }
                return item;
            });
            updatedCartData?.forEach(itemToUpdate => {
                const index = itemToUpdate.index;
                const id = itemToUpdate.itemData.id;
                const discount = itemToUpdate?.itemData?.discount;
                const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                const discountType = activeType;
                const discountID = discountId
debugger
                dispatch(updateDiscount(index, id, discount, selectedPromotionDetails, discountType, discountID));
            });
        } else if (orderType?.value === 'ItemDiscount') {
            
            const updatedCartData = cartData?.map(item => {
                if (selectedCategories?.includes(item.itemData.id||item.itemData?.itemId)) {
                    return {
                        ...item,
                        itemData: {
                            ...item.itemData,
                            discount: manualDiscountValue
                        }
                    };
                } else {
                    return {
                        ...item,
                        itemData: {
                            ...item.itemData,
                            discount: 0
                        }
                    };
                }
            });
            debugger
            updatedCartData?.forEach(itemToUpdate => {
                const index = itemToUpdate.index;
                const id = itemToUpdate.itemData.id;
                const discount = itemToUpdate?.itemData?.discount;
                const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                const discountType = activeType;
                const discountID = discountId


                dispatch(updateDiscount(index, id, discount, selectedPromotionDetails, discountType, discountID));
            });
        } else if (orderType?.value === 'totalDiscount') {
            
            const updatedCartData = cartData?.map((item, index) => {
                if (activeType === "Amount") {
                    if (index === 0) {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                discount: manualDiscountValue
                            }
                        };
                    } else {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                discount: 0
                            }
                        };
                    }
                } else {
                    return {
                        ...item,
                        itemData: {
                            ...item.itemData,
                            discount: manualDiscountValue
                        }
                    };
                }
            });
            console.log('Updated Cart Data:', updatedCartData);
            updatedCartData?.forEach(itemToUpdate => {
                const index = itemToUpdate.index;
                const id = itemToUpdate.itemData.id;
                const discount = itemToUpdate?.itemData?.discount;
                const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                const discountType = activeType;
                const discountID = discountId


                dispatch(updateDiscount(index, id, discount, selectedPromotionDetails, discountType, discountID));
            });
        } else if (orderType?.value === "Promotion") {
            // Check if a voucher code is provided

            if (formData?.Category === "1") {

                if (!formData.voucherCode.trim()) {
                    toast.error("Please enter a voucher code.");
                    return;
                }
                // Find the selected promotion details based on voucher code
                const selectedPromotionDetails = promotion.flatMap(promo => promo.posPromotionDetails)
                    .find(detail => detail.vouchorNo === formData.voucherCode);
                // Check if the selected promotion details exist

                if (!selectedPromotionDetails) {
                    toast.error("Invalid voucher code.");
                    return;
                }
                // Find the promotion corresponding to the selected promotion details
                const selectedPromotion = promotion.find(promo =>
                    promo.posPromotionDetails.includes(selectedPromotionDetails)
                );
                // Check if the selected promotion is expired
                const currentDate = new Date();
                const promotionDetailToDate = new Date(selectedPromotionDetails.toDate);

                // Compare the current date with the toDate of the promotion detail
                // if (currentDate > promotionDetailToDate) {
                //     toast.warning("Voucher expired.");
                //     return;
                // }

                // Update discount with the selected promotion's discount amount
                const updatedCartData = cartData?.map((item, index) => {
                    if (index === 0) {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                selectedPromotionDetails: selectedPromotionDetails,
                                discount: selectedPromotion.discountAmount
                            }
                        };
                    } else {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                selectedPromotionDetails: null,
                                discount: 0
                            }
                        };
                    }
                });

                updatedCartData?.forEach(itemToUpdate => {
                    const index = itemToUpdate.index;
                    const id = itemToUpdate.itemData.id;
                    const discount = itemToUpdate?.itemData?.discount;
                    const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                    const discountType = activeType;
                    const promotionID = promotionID

                    dispatch(updateDiscount(index, id, discount, selectedPromotionDetails, discountType, promotionID));
                });

            } else if (formData?.Category === "2") {
                const updatedCartData = cartData?.map((item, index) => {
                    if (activeType === "Amount") {
                        if (index === 0) {
                            return {
                                ...item,
                                itemData: {
                                    ...item.itemData,
                                    discount: manualDiscountValue
                                }
                            };
                        } else {
                            return {
                                ...item,
                                itemData: {
                                    ...item.itemData,
                                    discount: 0
                                }
                            };
                        }
                    } else {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                discount: manualDiscountValue
                            }
                        };
                    }
                });

                updatedCartData?.forEach(itemToUpdate => {
                    const index = itemToUpdate.index;
                    const id = itemToUpdate.itemData.id;
                    const discount = itemToUpdate?.itemData?.discount;
                    const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                    const discountType = activeType;
                    const promotionID = promotionId

                    dispatch(updateDiscount(index, id, discount, selectedPromotionDetails, discountType, promotionID));
                });
            }
        }

        closeDiscountModal();
    };
    const handleUpdateConfirm = () => {

        if (orderType?.value === 'Category') {
            const updatedCartData = data?.map(item => {
                if (selectedCategories?.includes(item.itemData.categoryId)) {
                    return {
                        ...item,
                        itemData: {
                            ...item.itemData,
                            menuItemDiscount: manualDiscountValue
                        }
                    };
                }
                return item;
            });

            updatedCartData?.forEach(itemToUpdate => {
                const index = itemToUpdate.index;
                const id = itemToUpdate.itemData.itemId;
                const discount = itemToUpdate?.itemData?.menuItemDiscount;
                const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                const discountType = activeType;
                const discountID = discountId


                dispatch(updateOrderDiscount(index, id, discount, selectedPromotionDetails, discountType, discountID));
            });
        } else if (orderType?.value === 'ItemDiscount') {
            const updatedCartData = data?.map(item => {
                if (selectedCategories?.includes(item.itemData.id)) {
                    if (selectedCategories?.includes(item.itemData.id)) {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                menuItemDiscount: manualDiscountValue
                            }
                        };
                    } else {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                menuItemDiscount: 0
                            }
                        };
                    }
                }
                return item;
            });
            updatedCartData?.forEach(itemToUpdate => {
                const index = itemToUpdate.index;
                const id = itemToUpdate.itemData.itemId;
                const discount = itemToUpdate?.itemData?.menuItemDiscount;
                const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                const discountType = activeType;
                const discountID = discountId


                dispatch(updateOrderDiscount(index, id, discount, selectedPromotionDetails, discountType, discountID));
            });
        } else if (orderType?.value === 'totalDiscount') {
            const updatedCartData = data?.map((item, index) => {
                if (activeType === "Amount") {
                    if (index === 0) {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                menuItemDiscount: manualDiscountValue
                            }
                        };
                    } else {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                menuItemDiscount: 0
                            }
                        };
                    }
                } else {
                    return {
                        ...item,
                        itemData: {
                            ...item.itemData,
                            menuItemDiscount: manualDiscountValue
                        }
                    };
                }
            });
            ;
            updatedCartData?.forEach(itemToUpdate => {
                const index = itemToUpdate.index;
                const id = itemToUpdate.itemData.itemId;
                const discount = itemToUpdate?.itemData?.menuItemDiscount;
                const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                const discountType = activeType;
                const discountID = discountId


                dispatch(updateOrderDiscount(index, id, discount, selectedPromotionDetails, discountType, discountID));
            });
        } else if (orderType?.value === "Promotion") {
            // Check if a voucher code is provided

            if (formData?.Category === "1") {

                if (!formData.voucherCode.trim()) {
                    toast.error("Please enter a voucher code.");
                    return;
                }
                // Find the selected promotion details based on voucher code
                const selectedPromotionDetails = promotion.flatMap(promo => promo.posPromotionDetails)
                    .find(detail => detail.vouchorNo === formData.voucherCode);
                // Check if the selected promotion details exist
                if (!selectedPromotionDetails) {
                    toast.error("Invalid voucher code.");
                    return;
                }
                // Find the promotion corresponding to the selected promotion details
                const selectedPromotion = promotion.find(promo =>
                    promo.posPromotionDetails.includes(selectedPromotionDetails)
                );
                // Check if the selected promotion is expired
                const currentDate = new Date();
                const promotionDetailToDate = new Date(selectedPromotionDetails.toDate);

                // Compare the current date with the toDate of the promotion detail
                // if (currentDate > promotionDetailToDate) {
                //     toast.warning("Voucher expired.");
                //     return;
                // }

                // Update discount with the selected promotion's discount amount
                const updatedCartData = data?.map((item, index) => {
                    if (activeType === "Amount") {
                        if (index === 0) {
                            return {
                                ...item,
                                itemData: {
                                    ...item.itemData,
                                    selectedPromotionDetails: selectedPromotionDetails,
                                    menuItemDiscount: selectedPromotion.discountAmount
                                }
                            };
                        } else {
                            return {
                                ...item,
                                itemData: {
                                    ...item.itemData,
                                    selectedPromotionDetails: null,
                                    menuItemDiscount: 0
                                }
                            };
                        }
                    } else {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                selectedPromotionDetails: selectedPromotionDetails,
                                menuItemDiscount: selectedPromotion.discountAmount
                            }
                        };
                    }
                });

                updatedCartData?.forEach(itemToUpdate => {
                    const index = itemToUpdate.index;
                    const id = itemToUpdate.itemData.itemId;
                    const discount = itemToUpdate?.itemData?.menuItemDiscount;
                    const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                    const discountType = activeType;
                    const promotionID = promotionId

                    dispatch(updateOrderDiscount(index, id, discount, selectedPromotionDetails, discountType, promotionID));
                });

            } else if (formData?.Category === "2") {
                const updatedCartData = data?.map((item, index) => {
                    if (activeType === "Amount") {
                        if (index === 0) {
                            return {
                                ...item,
                                itemData: {
                                    ...item.itemData,
                                    menuItemDiscount: manualDiscountValue
                                }
                            };
                        } else {
                            return {
                                ...item,
                                itemData: {
                                    ...item.itemData,
                                    menuItemDiscount: 0
                                }
                            };
                        }
                    } else {
                        return {
                            ...item,
                            itemData: {
                                ...item.itemData,
                                menuItemDiscount: manualDiscountValue
                            }
                        };
                    }
                });

                updatedCartData?.forEach(itemToUpdate => {
                    const index = itemToUpdate.index;
                    const id = itemToUpdate.itemData.itemId;
                    const discount = itemToUpdate?.itemData?.menuItemDiscount;
                    const selectedPromotionDetails = itemToUpdate?.itemData?.selectedPromotionDetails;
                    const discountType = activeType;
                    const promotionID = promotionId

                    dispatch(updateOrderDiscount(index, id, discount, selectedPromotionDetails, discountType, promotionID));
                });
            }
        }

        closeDiscountModal();
    };
    const handleClick = () => {
        if (cartData.length > 0) {
            handleConfirm();
        }
        else if (data.length > 0) {
            debugger
            handleUpdateConfirm();
        }
    };
    return (
        <div>
            <Modal.Header closeButton>
                <Modal.Title className='center'>Add Discounts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="py-3 d-flex justify-content-between">
                    <div className={`discountact ${activeType === "Amount" && 'disActive'}`} onClick={() => setActiveType('Amount')}>Amount</div>
                    <div className={`discountact1 ${activeType === "Percentage" && 'disActive'}`} onClick={() => setActiveType('Percentage')}>Percentage</div>
                </div>
                <div className='selected-addon d-flex justify-content-between m-3'>
                    {DiscoutsStatic.map((item, index) => (
                        <div key={index} className={`categorycash selectedadd d-flex gap-2 ${item?.label === orderType?.label && 'active'}`}
                            onClick={() => handleOrderType(item)}>
                            <div >{item?.label}</div>
                        </div>
                    ))}
                </div>
                <div className='p-3'>
                    {orderType?.value === 'ItemDiscount' ?
                        <div>
                            {orderType?.value === 'ItemDiscount' && items?.map(item => (
                                <div key={item.id}>
                                    <div className="form-check d-flex justify-content-between pb-3">
                                        <div className="gap-3 d-flex">
                                            <input className="form-check-input"
                                                type="checkbox"
                                                id={item?.id || item?.itemId} style={{ width: "26.81px", height: "26.81px" }}
                                                name={item?.name || item?.menuItemName}
                                                onChange={() => handleItemSelection(item?.id || item?.itemId,)}
                                            />
                                            <label className="form-check-label checkItem" htmlFor={item?.id || item?.itemId}>
                                                {item?.name || item?.menuItemName}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <label htmlFor="validationCustom04" className="form-label cashhead">Select Discount</label>
                            <Select
                                onChange={handleManualDiscountItemChange}
                                options={discounts?.map((item, index) => ({ value: item, label: item.name }))}
                            />
                            <div className="py-3">
                                {activeType === "Amount" ?
                                    <label htmlFor="validationCustom01" className="form-label"> Discount Amount</label>
                                    :
                                    <label htmlFor="validationCustom01" className="form-label"> Discount Percentage</label>
                                }
                                <input
                                    type="number"
                                    className="p-2 form-control"
                                    id="validationCustom01"
                                    value={manualDiscountValue}
                                    onChange={handleManualDiscountValueChange}
                                    required
                                    disabled
                                />
                            </div>
                        </div> :
                        <>{orderType?.value === 'Category' ?
                            <div>
                                {categories?.map(category => (
                                    <div key={category.id}>
                                        <div className="form-check d-flex justify-content-between pb-3">
                                            <div className="gap-3 d-flex">
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id={category.id} style={{ width: "26.81px", height: "26.81px" }}
                                                    name={category.name}
                                                    onChange={() => handleCategorySelection(category.id)}
                                                />
                                                <label className="form-check-label checkItem" htmlFor={category.id}>
                                                    {category.name}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <label htmlFor="validationCustom04" className="form-label cashhead">Select Discount</label>
                                <Select
                                    onChange={handleManualDiscountItemChange}
                                    options={discounts?.map((item, index) => ({ value: item, label: item?.percentage }))}
                                />
                                <div className="py-3">
                                    {activeType === "Amount" ?
                                        <label htmlFor="validationCustom01" className="form-label"> Discount Amount</label>
                                        :
                                        <label htmlFor="validationCustom01" className="form-label"> Discount Percentage</label>
                                    }
                                    <input
                                        type="number"
                                        className="p-2 form-control"
                                        id="validationCustom01"
                                        value={manualDiscountValue}
                                        onChange={handleManualDiscountValueChange}
                                        disabled
                                    />
                                </div>
                            </div> :
                            <div>
                                {orderType?.value === 'totalDiscount' ?
                                    <div>
                                        <label htmlFor="validationCustom04" className="form-label cashhead">Select Discount</label>
                                        <Select
                                            onChange={handleManualDiscountItemChange}
                                            options={discounts?.map((item, index) => ({ value: item, label: item?.name }))}
                                        />
                                        <div className="py-3">
                                            {activeType === "Amount" ?
                                                <label htmlFor="validationCustom01" className="form-label"> Discount Amount</label>
                                                :
                                                <label htmlFor="validationCustom01" className="form-label"> Discount Percentage</label>
                                            }
                                            <input
                                                type="number"
                                                className="p-2 form-control"
                                                id="validationCustom01"
                                                value={manualDiscountValue}
                                                onChange={handleManualDiscountValueChange}
                                                disabled
                                            />
                                        </div>
                                    </div> : " "
                                }
                            </div>
                        }</>
                    }
                    {orderType?.value === "Promotion" ?
                        <div>
                            <label htmlFor="validationCustom04" className="form-label cashhead">Select Promotion Type</label>
                            <Select
                                className=''
                                onChange={handleCategoryChange}
                                options={Category}
                            />
                            {formData?.Category === "2" ?
                                <>

                                    <label htmlFor="validationCustom04" className="form-label cashhead pt-3">Select Promotion</label>
                                    <Select
                                        onChange={handleVoucherCode}
                                        options={promotion?.map((item, index) => ({ value: item, label: item?.name }))}
                                    />
                                    <div className="py-3">
                                        {activeType === "Amount" ?
                                            <label htmlFor="validationCustom01" className="form-label"> Discount Amount</label>
                                            :
                                            <label htmlFor="validationCustom01" className="form-label"> Discount Percentage</label>
                                        }
                                        <input
                                            type="text"
                                            className="p-2 form-control"
                                            id="validationCustom02"
                                            value={manualDiscountValue}
                                            required
                                            disabled
                                        />
                                    </div>
                                </> : " "
                            }
                            {formData?.Category === "1" ?
                                <div className="pt-3">
                                    <label htmlFor="validationCustom02" className="form-label">Voucher Code</label>
                                    <input
                                        type="text"
                                        className="p-2 form-control"
                                        id="validationCustom02"
                                        name='voucherCode'
                                        value={formData?.voucherCode}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div> : " "
                            }

                        </div> : " "

                    }
                </div>
            </Modal.Body>
            <Modal.Footer className='end p-3'>
                <Button onClick={handleClick} className=''>Save</Button>
            </Modal.Footer>
        </div>
    );
};

export default DiscountModal;