// import { v4 as uuidv4 } from 'uuid';
// import React, { useState } from 'react';
// import axios from 'axios';

// const PaymentComponent = () => {
//   const [paymentStatus, setPaymentStatus] = useState(null);

//   const handleCashPayment = () => {
//     alert('Cash Payment Successful!');
//   };

//   const handleCardPayment = () => {
//     const paymentData = {
//         messageId: 'msg_51245e', 
//         posId: "POS_12238",
//         tranType: "SALE",
//         requestAmount: 100,
//         currency: "USD",
//         preferredInstrument: "CARD",
//     };

//     initiatePayment(paymentData);
//   };

//   // const initiatePayment = (paymentData) => {
//   //   fetch("https://api.flocash.com/v1/payment", {
//   //     method: 'POST',
//   //     headers: {
//   //       'Content-Type': 'application/json',
//   //       'Authorization': `Bearer ${paymentData.apiToken}`
//   //     },
//   //     body: JSON.stringify({
//   //       messageId: paymentData.messageId,
//   //       posId: paymentData.posId,
//   //       currency: paymentData.currency,
//   //       tranType: paymentData.tranType,
//   //       requestAmount: paymentData.amount,
//   //       preferredInstrument: paymentData.preferredInstrument,
//   //       shop: paymentData.shop
//   //     })
//   //   })
//   //     .then(response => {
//   //       console.log(response); // Log the raw response for debugging
//   //       return response.json();
//   //     })
//   //     .then(data => {
//   //       if (data.tranStatus === 'APPROVED') {
//   //         setPaymentStatus('Transaction Approved!');
//   //       } else {
//   //         setPaymentStatus('Transaction Failed!');
//   //       }
//   //     })
//   //     .catch(error => {
//   //       console.error('Error:', error);
//   //       setPaymentStatus('Transaction Error!');
//   //     });
//   // };
//   const initiatePayment = async (paymentData) => {
//     try {
//       const response = await axios.post('http://192.168.18.57:2433/v1/payment', {
//         messageId: paymentData.messageId,
//         posId: paymentData.posId,
//         tranType: paymentData.tranType,
//         requestAmount: paymentData.requestAmount,
//         currency: paymentData.currency,
//         preferredInstrument: paymentData.preferredInstrument,
//       }, {
//         headers: {
//           'Content-Type': 'application/json',
//           // 'Access-Control-Allow-Origin': '*', // Make sure your CORS setup is correct
//         }
//       });

//       // Check the response status and handle accordingly
//       if (response.data.tranStatus === 'APPROVED') {
//         setPaymentStatus('Transaction Approved!');
//       } else {
//         setPaymentStatus('Transaction Failed!');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       setPaymentStatus('Transaction Error!');
//     }
//   };

//   return (
//     <div>
//       {/* <button onClick={handleCashPayment}>Cash Payment</button> */}
//       <button onClick={handleCardPayment}>Card Payment</button>
//       {paymentStatus && <p>{paymentStatus}</p>}
//     </div>
//   );
// };

// export default PaymentComponent;
// import React, { useEffect } from 'react';

// const PaymentComponent = () => {
  
//   const handleCashPayment = () => {
//     alert('Cash Payment Successful!');
//   };

//   const handleCardPayment = () => {
//     const paymentData = {
//       merchant: "merchant@test.com",
//       order_id: "12345678",
//       amount: 10,
//       currency: "USD",
//       custom: "1234567890",
//     };

//     // Check if the Flocash SDK is loaded
//     if (window.FlocashSDK) {
//       window.FlocashSDK.processPayment(paymentData)
//         .then(response => {
//           console.log("Payment Response:", response);
//           if (response.status === 'approved') {
//             alert('Transaction Approved!');
//           } else {
//             alert('Transaction Failed!');
//           }
//         })
//         .catch(error => {
//           console.error("Payment Error:", error);
//           alert('Transaction Error!');
//         });
//     } else {
//       console.error("Flocash SDK not loaded.");
//       alert('Payment SDK not available.');
//     }
//   };

//   return (
//     <div>
//       <h1>Flocash Payment</h1>
//       <button onClick={handleCashPayment}>Cash Payment</button>
//       <button onClick={handleCardPayment}>Card Payment</button>
//     </div>
//   );
// };

// export default PaymentComponent;

import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const PaymentComponent = ({ onPaymentSuccess, amount,Currency }) => {  // Accept amount as prop
  const [paymentStatus, setPaymentStatus] = useState(null);
  // let Currency = useSelector(Currency)
  const handleCashPayment = () => {
    alert('Cash Payment Successful!');
  };

  const handleCardPayment = () => {
    const paymentData = {
      messageId: 'msg_51245e', // Use a predefined or dynamic message ID
      posId: "POS_12338", // POS ID
      tranType: "REFUND", // Transaction type (SALE for payments)
      requestAmount: amount, // Use the amount passed from parent
      currency: "USD", // Currency type
      preferredInstrument: "CARD", // Payment method (CARD)
    };

    initiatePayment(paymentData); // Call the function to initiate payment
  };

  // Function to initiate payment via API
  const initiatePayment = async (paymentData) => {
    try {
      const response = await axios.post('/v1/payment', {
        messageId: paymentData.messageId,
        posId: paymentData.posId,
        tranType: paymentData.tranType,
        requestAmount: paymentData.requestAmount,
        currency: paymentData.currency,
        preferredInstrument: paymentData.preferredInstrument,
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      // Handle the response based on the transaction status
      if (response.data.tranStatus === 'APPROVED') {
        setPaymentStatus('Transaction Approved!');
        if (onPaymentSuccess) {
          onPaymentSuccess(response.data);  // Pass the response data to the parent function
        }
      } else {
        setPaymentStatus('Transaction Declined.Try again with another card please!');
        if (onPaymentSuccess) {
          onPaymentSuccess(response.data);  // Pass the failed response to parent as well
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setPaymentStatus('Transaction Error!');
      if (onPaymentSuccess) {
        onPaymentSuccess({ error: error.message });  // Pass error details if needed
      }
    }
  };

  return (
    <div className='row'>
      {/* Cash Payment Button (Commented out for now) */}
      {/* <button onClick={handleCashPayment}>Cash Payment</button> */}

      {/* Card Payment Button */}
      <button className='AddBtn' onClick={handleCardPayment}>Pay {Currency?.currency?.name} {amount} with Card</button>

      {/* Display the payment status */}
      <div className='mt-2'>

      {paymentStatus && <p>{paymentStatus}</p>}
      </div>
    </div>
  );
};

export default PaymentComponent;
