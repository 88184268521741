import React, { useState, useEffect } from "react";
import Select from 'react-select';
import ApiService from "../../../../services/apiService";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import userManagement from "../../../../services/userManagement";

const EditRoleRights = () => {
    const { id } = useParams(); // If needed for routing context
    const location = useLocation();
    const roleId = location.state;
    debugger
    const [users, setUsers] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null); // Selected User
    const [moduleClaims, setModuleClaims] = useState([]);
    const [crudClaims, setCrudClaims] = useState([]);
    const [activeModule, setActiveModule] = useState(null);
    const [allClaims, setAllClaims] = useState([]);
    const apiService = new ApiService();
    const employeeData = useSelector((state) => state?.employeData?.employeData);
    const token = localStorage.getItem('Token');
    const user = userManagement?.getUserId(token);

    // Fetch Users and Claims on Component Mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRes = await apiService.get('GET_USERS');
                const claimRes = await apiService.get('GET_CLAIM');

                setUsers(userRes.data.result);
                setUsersList(userRes.data.result);

                const claimsArray = Object.values(claimRes.data.result);
                setModuleClaims(claimsArray.filter(claim => claim.claimType === 'module'));
                setAllClaims(claimsArray); // All claims for future filtering
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error fetching data. Please try again later.");
            }
        };

        fetchData();
    }, []);

    // Fetch Role-Specific Claims
    const getRoleClaims = async (roleId) => {
        try {
            const param = `?roleId=${roleId}`;
            const res = await apiService.getApiParamater('GET_ROLE_CLAIM', param);
            const roleClaimIds = res?.data?.result?.map(claim => claim?.claimId);

            const updatedCrudClaims = crudClaims.map(claim => ({
                ...claim,
                enabled: roleClaimIds.includes(claim.id),
            }));

            setCrudClaims(updatedCrudClaims);
        } catch (error) {
            console.error('Error fetching Role Claims:', error);
        }
    };

    // Handle User Selection from Dropdown
    const handleSetUserChange = async (selectedOption) => {
        setSelectedUser(selectedOption);
        await getRoleClaims(roleId);
    };

    // Handle Module Selection
    const handleModuleClick = (moduleName) => {
        setActiveModule(moduleName);
        const moduleClaim = moduleClaims.find(claim => claim.claimValue === moduleName);

        if (moduleClaim) {
            updateCrudClaims(moduleClaim.claimValue);
        } else {
            console.error(`Module claim not found for ${moduleName}`);
        }
    };

    // Update CRUD Claims for Selected Module
    const updateCrudClaims = (moduleName) => {
        const capitalizedModuleName = moduleName
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        const crudPrefix = capitalizedModuleName + '.';
        const moduleCrudClaims = allClaims.filter(claim =>
            claim.claimType === 'crud' && claim.claimValue.startsWith(crudPrefix)
        );

        const updatedCrudClaims = moduleCrudClaims.map(claim => {
            const displayName = claim.claimValue.substring(crudPrefix.length);
            const enabled = crudClaims.find(c => c.id === claim.id)?.enabled || false;

            return {
                id: claim.id,
                name: displayName,
                enabled,
            };
        });

        setCrudClaims(updatedCrudClaims);
    };

    // Handle Checkbox Change for CRUD Permissions
    const handleCheckboxChange = (claimId) => async (e) => {
        const isChecked = e.target.checked;

        try {
            const body = {
                roleId: roleId, // Using roleId for the role-based permission management
                claimId,
                createdBy: user,
            };

            if (isChecked) {
                // Assign the permission (POST request)
                const response = await apiService.post('CREATE_ROLE_CLAIM', body);
                if (response?.statusCode === 200 || 201) {
                    toast.success('Permission granted successfully');
                }
            } else {
                // Remove the permission (DELETE request)
                await apiService.deleteParam('DELETE_ROLE_CLAIM', { roleId: body.roleId, claimId });
            }

            // Update the state after the action
            const updatedCrudClaims = crudClaims.map(claim =>
                claim.id === claimId ? { ...claim, enabled: isChecked } : claim
            );
            setCrudClaims(updatedCrudClaims);
        } catch (error) {
            console.error('Error updating role claims:', error);
            toast.error("Error updating role claims");
        }
    };

    return (
        <div className="p-3">
            <div className="px-lg-5">
                <h4>Manage Role</h4>
                {/* Modules and CRUD Permissions */}
                <div className="row pt-5">
                    <div className="col-lg-3">
                        <div className="p-3" style={{ border: 'solid #dbdbdb', borderRadius: '13px' }}>
                            {moduleClaims.map((claim) => (
                                <div
                                    key={claim.id}
                                    className={`sidebar-text p-2 center ${activeModule === claim.claimValue ? 'active' : ''}`}
                                    onClick={() => handleModuleClick(claim.claimValue)}
                                >
                                    {claim.claimValue}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-9">
                        {activeModule && (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Permission</th>
                                        <th scope="col">Access</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {crudClaims.map(({ id, name, enabled }) => (
                                        <tr key={id}>
                                            <td>{name?.charAt(0)?.toUpperCase() + name?.slice(1)}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    style={{ width: "23.81px", height: "23.81px", border: "0.5px solid #6E5199" }}
                                                    checked={enabled}
                                                    onChange={handleCheckboxChange(id)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditRoleRights;
