import React, { useState, useEffect } from 'react';
import './itemCard.css';
import "./receipt.css";
import "./bucket.css";
import { useNavigate } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import ApiService from '../../services/apiService';
import Select from 'react-select';

const CustomerCreatePOpup = ({ orderType, GetCostumer, setAddCustomer, close, selectedCustomer, address }) => {
    let customer = selectedCustomer.value;
    let navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: '',
        name: "",
        idNumber: "",
        city: "",
        gender: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        phoneNumber: "",
        email: "",
        deliveryCharges: '',
        rider: ''
    });

    // Track selected default address and address change state
    const [selectedAddress, setSelectedAddress] = useState('');
    const [currentSelectedAddress, setCurrentSelectedAddress] = useState('');
    const [isAddressChanged, setIsAddressChanged] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

        if (name === currentSelectedAddress) {
            setIsAddressChanged(true); // Mark address as changed if the current address is edited
        }
    };

    const handleAddressSelection = (e) => {
        const selectedAddress = e.target.value;
        setCurrentSelectedAddress(selectedAddress);
        setSelectedAddress(selectedAddress);
        if (selectedAddress && formData[selectedAddress]) {
            address(formData[selectedAddress]);
        }
    };

    const [isEditable, setIsEditable] = useState(false); // Tracks if form fields are editable

    useEffect(() => {
        if (customer) {
            setFormData({
                id: customer?.id,
                name: customer?.name,
                idNumber: customer?.idNumber,
                city: customer.city,
                gender: customer.gender,
                address1: customer?.address1,
                address2: customer?.address2,
                address3: customer.address3,
                address4: customer.address4,
                phoneNumber: customer.phoneNumber,
                email: customer?.email,
                deliveryCharges: customer.deliveryCharges || '',
                rider: customer.rider || ''
            });
            setIsEditable(false);
        } else {
            setIsEditable(true);
        }
    }, [customer]);

    const updateCustomerAddress = async () => {
        try {
            const updatedAddress = formData[currentSelectedAddress];
            if (!updatedAddress) return;

            const body = {
                customerId: formData.id,
                addressKey: currentSelectedAddress,
                addressValue: updatedAddress
            };

            const apiService = new ApiService();
            let response = await apiService.put("UPDATE_CUSTOMER", body); // Replace with your actual API endpoint

            if (response.data.statusCode === 200) {
                toast.success('Address updated successfully');
                setIsAddressChanged(false); // Reset address change state
            } else {
                toast.warning(response.data.message || 'Failed to update address');
            }
        } catch (error) {
            console.error('Error updating address:', error);
            toast.error('An error occurred while updating the address');
        }
    };

    const handleNext = async () => {
        if (isAddressChanged) {
            await updateCustomerAddress(); // Call API if address was changed
        }
        close(); // Proceed to the next step
    };
  
    const handleAddCostumer = async () => {
        try {
            if (!formData?.name) {
                toast?.warning("Enter Customer Name");
            } else {
                const body = {
                    customerCategoryId: 1,
                    address: formData?.address,
                    address2: formData?.address1,
                    status: true,
                    name: formData.name,
                    identityCardNumber: formData.idNumber,
                    mobileNumber: formData?.phoneNumber,
                    area: formData?.city,
                    gender: formData.gender,
                    phoneNo1: formData?.phoneNumber,
                    email: formData?.email,
                    enableLoyalty: true,
                    password: '1234567'
                };

                const apiService = new ApiService();
                let response = await apiService.post("ADD_COSTUMER", body);

                if (response.data.statusCode === 201) {
                    toast.success('Customer created successfully');
                    setAddCustomer(false);
                    GetCostumer();
                    close();
                } else if (response?.data?.statusCode === 400) {
                    toast.warning(response?.data?.message);
                    close();
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleGenderChange = (selectedOption) => {
        setFormData({ ...formData, gender: selectedOption?.value });
    };

    const handleRiderChange = (selectedOption) => {
        setFormData({ ...formData, rider: selectedOption?.value });
    };


    return (
        <Modal.Body>
            <div>
                <h5 className='p-1'>{customer ? 'Edit Customer' : 'Add New Customer'}</h5>
                <div className="form-group row py-4">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom01" className="form-label">Name</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable} // Disable when customer is selected
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom02" className="form-label">Phone</label>
                        <input
                            type="number"
                            className="p-2 form-control"
                            id="validationCustom02"
                            name="phoneNumber"
                            value={formData?.phoneNumber}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable}
                        />
                    </div>
                </div>

                {/* Email, City */}
                <div className="form-group row">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom03" className="form-label">Email</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom03"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                            disabled={!isEditable}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom09" className="form-label">
                            Gender
                        </label>
                        <Select styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                color: state.isFocused ? '#000 ' : '#000',
                                ':hover': {
                                    backgroundColor: '#F5F5F5',
                                    color: '#000 ',
                                },
                            }),
                        }}
                            defaultValue={formData.gender}
                            onChange={handleGenderChange}
                            options={[
                                { value: 'Male', label: 'Male' },
                                { value: 'Female', label: 'Female' }
                            ]}
                            isDisabled={!isEditable}
                        />
                    </div>
                </div>

            

                <div className="form-group row mt-4">
                    {/* Office Address */}
                    <div className="col-md-12">
                        <label htmlFor="validationCustom07" className="form-label">
                            Office Address
                        </label>
                        <div className="form-check row d-flex align-items-center">
                            {/* Radio button */}
                            <div className="col-auto">
                                <input
                                    type="radio"
                                    id="address1-radio"
                                    name="defaultAddress"
                                    value="address1"
                                    checked={selectedAddress === "address1"}
                                    onChange={handleAddressSelection}
                                    className="form-check-input"
                                />
                            </div>

                            {/* Address text input */}
                            <div className="col">
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom07"
                                    name="address1"
                                    value={formData.address1}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Home Address */}
                    <div className="col-md-12">
                        <label htmlFor="validationCustom08" className="form-label">
                            Home Address
                        </label>
                        <div className="form-check row d-flex align-items-center">
                            {/* Radio button */}
                            <div className="col-auto">
                                <input
                                    type="radio"
                                    id="address2-radio"
                                    name="defaultAddress"
                                    value="address2"
                                    checked={selectedAddress === "address2"}
                                    onChange={handleAddressSelection}
                                    className="form-check-input"
                                />
                            </div>

                            {/* Address text input */}
                            <div className="col">
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom08"
                                    name="address2"
                                    value={formData.address2}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Address 3 */}
                    <div className="col-md-12">
                        <label htmlFor="validationCustom08" className="form-label">
                            Address 3
                        </label>
                        <div className="form-check row d-flex align-items-center">
                            {/* Radio button */}
                            <div className="col-auto">
                                <input
                                    type="radio"
                                    id="address3-radio"
                                    name="defaultAddress"
                                    value="address3"
                                    checked={selectedAddress === "address3"}
                                    onChange={handleAddressSelection}
                                    className="form-check-input"
                                />
                            </div>

                            {/* Address text input */}
                            <div className="col">
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom08"
                                    name="address3"
                                    value={formData.address3}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Address 4 */}
                    <div className="col-md-12">
                        <label htmlFor="validationCustom08" className="form-label">
                            Address 4
                        </label>
                        <div className="form-check row d-flex align-items-center">
                            {/* Radio button */}
                            <div className="col-auto">
                                <input
                                    type="radio"
                                    id="address4-radio"
                                    name="defaultAddress"
                                    value="address4"
                                    checked={selectedAddress === "address4"}
                                    onChange={handleAddressSelection}
                                    className="form-check-input"
                                />
                            </div>

                            {/* Address text input */}
                            <div className="col">
                                <input
                                    type="text"
                                    className="p-2 form-control"
                                    id="validationCustom08"
                                    name="address4"
                                    value={formData.address4}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Display the selected default address */}
                    <div className="col-md-12">
                        <label htmlFor="validationCustom07" className="form-label">
                            Selected Address
                            {selectedAddress === "address1"
                                ? " (Office Address)"
                                : selectedAddress === "address2"
                                    ? " (Home Address)"
                                    : selectedAddress === "address3"
                                        ? " (Address 3)"
                                        : " (Address 4)"}
                        </label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom07"
                            name={selectedAddress}
                            value={formData[selectedAddress] || ""}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>

                {orderType === 'Delivery' && (
                    <>
                        <div className="form-group row py-4">
                            <div className="col-md-6">
                                <label htmlFor="validationCustom06" className="form-label">Delivery Charges</label>
                                <input
                                    type="number"
                                    className="p-2 form-control"
                                    id="validationCustom06"
                                    name="deliveryCharges"
                                    value={formData?.deliveryCharges}
                                    onChange={handleInputChange}
                                    required
                                    disabled={!isEditable}
                                />
                            </div>
                       </div>

                    </>
                )}
                <div>
                    {
                        isEditable ?
                    <button className='addItem-btn text-center mt-4' onClick={() => handleAddCostumer()}>Create</button>
                    :
                    <button className='addItem-btn text-center mt-4' onClick={handleNext }>Next</button>

                    }

                </div>
            </div>

        </Modal.Body >
    );
};

export default CustomerCreatePOpup;
