import React, { useEffect } from 'react';
import './setting.css';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import Sidebox from './SideBox';
import { setTaxExplicit, setTaxImplicit } from '../../../../Redux/actions/taxActions';
import { getTaxExplicit, getTaxImplicit } from '../../../../Redux/selecters';

const TaxSetting = () => {
    const dispatch = useDispatch();

    // Get the current state of taxExplicit and taxImplicit from Redux
    const taxExplicit = useSelector(getTaxExplicit);
    const taxImplicit = useSelector(getTaxImplicit);

    // Handle Tax Explicit change (ensure Tax Implicit is off)
    const handleTaxExplicitChange = () => {
        // Dispatch action to enable Tax Explicit and disable Tax Implicit
        dispatch(setTaxExplicit(true));
        dispatch(setTaxImplicit(false));

        // Make an API call for Tax Explicit (if necessary)
        console.log('Calling API for Tax Explicit');
    };

    // Handle Tax Implicit change (ensure Tax Explicit is off)
    const handleTaxImplicitChange = () => {
        // Dispatch action to enable Tax Implicit and disable Tax Explicit
        dispatch(setTaxImplicit(true));
        dispatch(setTaxExplicit(false));

        // Make an API call for Tax Implicit (if necessary)
        console.log('Calling API for Tax Implicit');
    };

    return (
        <div className="container">
            <div className="pading-space pt-5">
                <div className="pt-5 pb-4">
                    <div className="row">
                        <div className="col-3 pt-5">
                            <Sidebox />
                        </div>
                        <div className="col-9 px-5">
                            <div className="pb-5">
                                <span className="settingtext">Tax Explicit/Implicit</span>
                            </div>
                            <div className="row">
                                {/* Tax Explicit Toggle */}
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="taxExplicitCheck"
                                        checked={taxExplicit}
                                        onChange={handleTaxExplicitChange}
                                    />
                                    <label className="form-check-label" htmlFor="taxExplicitCheck">
                                        <h5 className="notificationhead">Tax Explicit</h5>
                                    </label>
                                    <div>
                                        <span className="notificationtxt">
                                            Enable explicit tax calculation.
                                        </span>
                                    </div>
                                </div>

                                {/* Tax Implicit Toggle */}
                                <div className="form-check pt-4">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="taxImplicitCheck"
                                        checked={taxImplicit}
                                        onChange={handleTaxImplicitChange}
                                    />
                                    <label className="form-check-label" htmlFor="taxImplicitCheck">
                                        <h5 className="notificationhead">Tax Implicit</h5>
                                    </label>
                                    <div>
                                        <span className="notificationtxt">
                                            Enable implicit tax calculation.
                                        </span>
                                    </div>
                                </div>

                                {/* Uncomment Save Button if required */}
                                {/* <div className="row pt-5">
                                    <div className="col-5">
                                        <Button className="addItem-btn">Save</Button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TaxSetting;
