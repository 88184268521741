import { React, useState, useEffect } from 'react';
import ImageUpload from '../../menus/addItems/imagUpload';
import "../master.css";
import { toast } from 'react-toastify';
import ApiService from '../../../../services/apiService';
import userManagement from '../../../../services/userManagement';
import Select from 'react-select';

const Image = process.env.REACT_APP_IMAGE_URL;

const CreateMethod = () => {
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [glAccount, setGlAccount] = useState([]);
    const [permissions, setPermissions] = useState({ add: false, update: false, delete: false, enableDisable: false });

    useEffect(() => {
        GetMethods();
        GetAccount();
        getPermissions();
    }, []);

    const [formData, setFormData] = useState({
        paymentMethod: "",
        images: [],
        imageUrl: "",
        glAccountCode: "",
        glAccountName: "",
    });

    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images: images });
    };

    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleGLAccounts = (selectedOption) => {
        setFormData({
            ...formData, glAccountCode: selectedOption?.value?.accountCode,
            glAccountName: selectedOption?.value?.accountName
        });
    };

    const getPermissions = async () => {
        try {
            // Assuming API service provides the user's permissions
            const userPermissions = await userManagement.getPermissions();
            setPermissions(userPermissions);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    };

    const CreatepaymentMethod = async () => {
        if (!permissions.add) {
            toast.error("You do not have permission to add payment methods.");
            return;
        }

        if (!formData?.images && !formData?.imageUrl) {
            toast.error("Upload Image");
        } else if (formData?.images) {
            try {
                const apiService = new ApiService();
                const formdata = new FormData();
                for (const image of formData?.images) {
                    if (image.file) {
                        formdata.append('Images', image?.file);
                    }
                }
                const response = await apiService.postMultiPart("UPLOAD_IMG", formdata);

                if (response?.data?.statusCode === 201 || response?.data?.statusCode === 200) {
                    setFormData({ ...formData, imageUrl: response?.data?.result });
                    handleSubmit(response?.data?.result);
                } else {
                    toast.error(response?.data?.msg);
                }
            } catch (error) {
                toast.error("Something went wrong with the image. Please try again!");
            }
        }
    };

    const handleSubmit = async (imgurl) => {
        try {
            let UserId = userManagement.getUserId();
            let body = {
                name: formData?.paymentMethod,
                imageUrl: imgurl,
                loggedInUserId: UserId,
                glAccountName: formData?.glAccountName,
                glAccountCode: formData?.glAccountCode,
            };

            const apiService = new ApiService();
            let res = await apiService.post('CREATE_PAYMENT_METHOD', body);
            if (res?.data?.statusCode === 201 || res?.data?.statusCode === 200) {
                toast.success("Payment method created successfully");
                setFormData({
                    paymentMethod: "",
                    images: [],
                    imageUrl: '',
                    glAccountCode: "",
                    glAccountName: "",
                });
                GetMethods();
                window.location.reload(false);
            }
        } catch (error) {
            console.error('Error creating payment method:', error);
        }
    };

    const GetAccount = async () => {
        try {
            const apiService = new ApiService();
            let param = `?AccountType=Cash`;
            let res = await apiService.get('GET_GL_ACCOUNT');
            setGlAccount(res?.data?.result);
        } catch (error) {
            console.error('Error fetching GL account data:', error);
        }
    };

    const GetMethods = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_PAYMENT_METHOD');
            setPaymentMethod(res?.data?.result);
        } catch (error) {
            console.error('Error fetching payment method data:', error);
        }
    };

    const handleUpdatePayment = async (payment, newActiveStatus) => {
        if (!permissions.update) {
            toast.error("You do not have permission to update payment methods.");
            return;
        }

        try {
            const apiService = new ApiService();
            const catData = {
                id: payment?.id,
                name: payment?.name,
                loggedInUserId: payment?.loggedInUserId,
                active: newActiveStatus,
                imageUrl: payment?.imageUrl,
            };
            const res = await apiService.put(`UPDATE_PAYMENT_METHOD`, catData);
            if (res?.data?.statusCode === 200) {
                toast.success(`Payment ${payment?.name} updated successfully!`);
                GetMethods();
            } else {
                toast.error("Failed to update payment. Please try again!");
            }
        } catch (error) {
            console.error('Error updating payment:', error);
        }
    };

    const handleDeletePayment = async (payment) => {
        if (!permissions.delete) {
            toast.error("You do not have permission to delete payment methods.");
            return;
        }

        try {
            const apiService = new ApiService();
            const res = await apiService.delete(`DELETE_PAYMENT_METHOD/${payment.id}`);
            if (res?.data?.statusCode === 200) {
                toast.success(`Payment method ${payment?.name} deleted successfully!`);
                GetMethods();
            } else {
                toast.error("Failed to delete payment method.");
            }
        } catch (error) {
            console.error('Error deleting payment method:', error);
        }
    };

    const handleSwitchChange = async (payment, newActiveStatus) => {
        if (!permissions.enableDisable) {
            toast.error("You do not have permission to enable/disable payment methods.");
            return;
        }

        try {
            await handleUpdatePayment(payment, newActiveStatus);
        } catch (error) {
            console.error('Error updating payment status:', error);
        }
    };

    return (
        <div className='p-5 container'>
            <div className='catlist'>
                {/* {permissions.add && ( */}
                <div className='py-3 row'>
                    <div className="col-5 col6">
                        <label htmlFor="validationCustom01" className="form-label">Payment Method</label>
                        <input
                            type="text"
                            className="p-2 form-control"
                            id="validationCustom01"
                            name="paymentMethod"
                            value={formData.paymentMethod}
                            onChange={handleInputChange}
                            required
                            disabled={!permissions.add}

                        />
                    </div>
                    <div className="col">
                        <label htmlFor="validationCustom04" className="form-label">Select Gl Account *</label>
                        <Select
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                    color: state.isFocused ? '#000 ' : '#000',
                                    ':hover': {
                                        backgroundColor: '#F5F5F5',
                                        color: '#000 ',
                                    },
                                }),
                            }}
                            defaultValue={formData.glAccountName}
                            onChange={handleGLAccounts}
                            options={glAccount?.map((gl) => ({ value: gl, label: gl.accountName }))}
                            isDisabled={!permissions.add}
                        />
                    </div>
                </div>
                {/* )} */}
                <div className='row center pt-3 d-flex'>
                    <div className='col'>
                        <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
                    </div>
                    {permissions.add && (
                        <div className='col d-flex align-self-end justify-content-center pt-3'>
                            <button className='addItem-btn' onClick={() => CreatepaymentMethod()} disabled={!permissions.add}>
                                Save
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className='catlist mt-5 table-responsive'>
                <table className='table table-striped mt-3'>
                    <thead>
                        <tr>
                            <th scope='col'>logo</th>
                            <th scope='col'>Payment Method</th>
                            <th scope='col'>Gl Account</th>
                            <th scope='col'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentMethod?.length === 0 ? (
                            <tr>
                                <td colSpan="3" className="text-center">No Methods available</td>
                            </tr>
                        ) : (
                            paymentMethod?.map((methods, index) => (
                                <tr key={index}>
                                    <td><img src={Image + methods?.imageUrl} className='paymentlogo' /></td>
                                    <td>{methods.name}</td>
                                    <td>{methods.glAccountName || '----'}</td>
                                    <td>
                                        <div className="d-flex text-center">
                                            {permissions.enableDisable ? (
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={methods.active}
                                                        onChange={(e) => handleSwitchChange(methods, e.target.checked)}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={methods.active}
                                                        disabled
                                                    />
                                                </div>
                                            )}
                                            {permissions.delete ? <button className="btn btn-danger ms-2" onClick={() => handleDeletePayment(methods)}>Delete</button>
                                                : (
                                                    <button className="btn btn-danger ms-2" disabled>Delete</button>
                                                )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CreateMethod;
