import React, { forwardRef } from 'react';
import './itemCard.css';
import "./receipt.css"
import moment from 'moment/moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from "../../assets/images/Qfinity-BW.png"

const CashierReceipt = forwardRef(({ val, formData, closeModal }, ref) => {
    const currentDatetime = new Date().toISOString();
    const totalOrderAmount = val?.reduce((total, item) => total + item.itemData.price, 0);

    const handleDownload = () => {
        // Create a new instance of jsPDF
        const pdf = new jsPDF();

        pdf.setFont('Arial', 'normal');

        // Centered logo
        const logoImage = new Image();
        logoImage.src = logo;
        const logoWidth = 50; // Adjust as needed
        const logoHeight = 20; // Adjust as needed
        const logoX = (pdf.internal.pageSize.width - logoWidth) / 2;
        const logoY = 10;
        pdf.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

        // Centered "*** Receipt ***"
        pdf.setFontSize(16); // Set font size
        const receiptText = '***  Receipt  ***';
        const receiptTextWidth = pdf.getStringUnitWidth(receiptText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const receiptTextX = (pdf.internal.pageSize.width - receiptTextWidth) / 2;
        const receiptTextY = logoY + logoHeight + 10; // Adjust as needed
        pdf.text(receiptText, receiptTextX, receiptTextY);

        // Other content styling
        pdf.setFontSize(12); // Set font size for other content

        pdf.text('Ordered By', 10, 50);
        pdf.text('Cashier #', 170, 50);

        pdf.text('Created at', 10, 60);
        pdf.text(moment(currentDatetime).format('YYYY-MM-DD HH:mm'), 170, 60);

        pdf.text('Order Details', 10, 80);

        // Use autoTable from jspdf-autotable
        pdf.autoTable({
            startY: 90,
            head: [['#', 'Qty', 'Items', 'Cost']],
            body: val.map((item, index) => [
                item?.itemData?.id,
                item?.quantity,
                item?.itemData?.name,
                item?.itemData?.price,
            ]),
        });

        pdf.setDrawColor(0);
        pdf.setFillColor(255, 255, 255);
        pdf.rect(10, pdf.autoTable.previous.finalY + 10, 190, 0.1, 'FD');

        pdf.text('SubTotal', 10, pdf.autoTable.previous.finalY + 20);
        pdf.text(`${totalOrderAmount}`, 170, pdf.autoTable.previous.finalY + 20);

        pdf.text('Total Amount', 10, pdf.autoTable.previous.finalY + 40);
        pdf.text(`${totalOrderAmount}`, 170, pdf.autoTable.previous.finalY + 40);

        // Save the PDF with a specific name (e.g., 'receipt.pdf')
        const fileName = formData?.name ? `${formData?.name}_receipt.pdf` : 'receipt.pdf';

        pdf.save(fileName);
    };
    return (
        <div  ref={ref} className='p-3'>
            <div className='center recpthead'>
                <img src={logo} alt="Qfinity Logo" />
            </div>
            <div className='center recpthead pt-3'>***  Receipt  *** </div>

            <div className='d-flex justify-content-between pt-2'>
                <div className='recptcashhe'>Ordered By                </div>
                <div className='cashead'>Cashier #</div>
            </div>
            <div className='d-flex justify-content-between pt-2'>
                <div className='cashead'>Created at</div>
                <div className='cashead'>{moment(currentDatetime).format('YYYY-MM-DD HH:mm')}</div>
            </div>
            <div className='recptcashhe pt-2'>Order Details</div>
            <div className='table-responsive'>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Items</th>
                            <th scope="col">Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {val?.map((val, index) => {
                            return (
                                <tr>
                                    <th scope="row">{val?.itemData?.id}</th>
                                    <td>{val?.quantity}</td>
                                    <td>{val?.itemData?.name}</td>
                                    <td>{val?.itemData?.price}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="lineBreak"></div>
            <div className='d-flex justify-content-between py-3'>
                <div className='cashead'>SubTotal</div>
                <div className='cashead'>{totalOrderAmount}</div>
            </div>
            <div className="lineBreak"></div>
            <div className='d-flex justify-content-between py-3'>
                <div className='cashead'>Total Amount</div>
                <div className='cashead'>{totalOrderAmount}</div>
            </div>
            <div className='center'>
                <button onClick={handleDownload} className='btn btn-primary'>Download as PDF</button>
            </div>
        </div>

        // <div class="container-ticket">
        //     <div class="ticket">
        //         <div class="head-ticket">
        //             <img src={logo}/>
        //             <p class="bold">33500 LIBOURNE</p>
        //             <p class="bold">Tel: 05 57 55 08 08</p>
        //             <br />
        //             <p class="bold">VOUS REMERCIE DE VOTRE CONFIANCE</p>
        //             <p>Caisse 015-2019 15 aôut 2017 18:24</p>
        //             <p>Ticket 15/08/170 0QRL 02000</p>
        //             <div class="code-barre">
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //                 <span></span>
        //             </div>
        //         </div>
        //         <div class="body-ticket">
        //             <div class="produits">
        //                 <div class="col2">
        //                     <p>YAO NAT LOU PERAC LAIT BREBIS F</p>
        //                     <p class="prix">1.63</p>
        //                 </div>
        //                 <div class="hr-sm"></div>
        //                 <div class="col2">
        //                     <p>Total 1 article</p>
        //                     <p class="prix">1.63</p>
        //                 </div>
        //                 <p>Soit en franc : 10.69</p>
        //                 <p>(1 euro = 6,55957 francs)</p>
        //                 <br />
        //                 <div class="col2">
        //                     <p>Bon achat carte</p>
        //                     <p class="prix">1.63</p>
        //                 </div>
        //                 <div class="col2">
        //                     <p>Rendu</p>
        //                     <p class="prix">0.00</p>
        //                 </div>
        //             </div>
        //             <div class="hr-lg"></div>
        //             <div class="carte">
        //                 <p class="title-carte">Avec la carte E.Leclerc</p>
        //                 <br />
        //                 <div class="col2">
        //                     <p>CUMUL DISPONIBLE AU 15/08/17 :</p>
        //                     <p class="prix">31.63€</p>
        //                 </div>
        //                 <br />
        //                 <p>Ce CUMUL DISPONIBLE est à valoir sur vos prochains achats dans tous les magasins E.LECLERC participant à l'opération et sur présentation de votre carte E.LECLERC.</p>
        //                 <br />
        //                 <p>Modalités, durée de validité à l'accueil</p>
        //             </div>
        //             <div class="hr-lg"></div>
        //         </div>
        //         <div class="footer-ticket">
        //             <p class="title-footer">Merci de votre<br />visite et à bientôt</p>
        //         </div>
        //     </div>
        // </div>
    );
});

export default CashierReceipt;
