import React, { useState, useEffect, useRef } from 'react';
import "../../Customer/Bucket/bucket.css"
import { ReactComponent as Delete } from '../../assets/images/svgIcons/Delete.svg';
import { useLocation, useNavigate } from 'react-router-dom'
import ApiService from '../../services/apiService';
import { toast } from 'react-toastify';
import CashierReceipt from './receipt';
import { ReactComponent as BackArrow } from '../../assets/images/svgIcons/LeftArrow.svg';
import Modal from 'react-bootstrap/Modal';
import "./receipt.css"
import "./bucket.css"
import Calculator from './calculator/calculator';
import { connect } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { AddToCart, RemoveFromCart, clearCart, downgradeCartItemQuantity, updateCartItemQuantity } from '../../../Redux/actions/cartAction';
import { useSelector } from 'react-redux';
import { getAddons, getCartData, getComboData, getTableData, getTaxExplicit, getTaxImplicit, getUserId } from '../../../Redux/selecters';
import { useDispatch } from 'react-redux';
import { clearTable } from '../../../Redux/actions/tableAction';
import { invokeBroadCastMessage } from '../../services/signalRService';
import { useReactToPrint } from 'react-to-print';
import { RemoveComboFromCart, clearComboCart, downgradeComboQuantity, updateComboQuantity } from '../../../Redux/actions/comboAction';
import DiscountModal from './cashierbucket/addDiscount';
import CustomerCreatePOpup from './createCustomerModal';
import { MdPersonAddAlt1 } from "react-icons/md";
import userManagement from '../../services/userManagement';
import StripeForm from '../../Customer/Payment/StripeForm';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, useStripe, useElements, Elements } from "@stripe/react-stripe-js";
import { BsFileBreak } from 'react-icons/bs';
import currency from '../../component/pages/setting/currency';
import { printReceipt } from '../../services/globalService';
import CardPayment from './cashierPayment/cardPayment';
import CashStripe from './cashierPayment/cashStripe';
import { removeAddon, updateAddonQuantity } from '../../../Redux/actions/addOnAction';
import ThermalPrinter from 'react-thermal-printer';
import axios from 'axios';
import CartPopup from './cartPopup';
import { BiEdit } from 'react-icons/bi';
import { OrderStatus } from '../../../Enums/config';
// import { initiateFlocashPayment } from './flocashPayment'; 
import { setOrderType } from '../../../Redux/actions/cartAction';
import PaymentComponent from './flocashPayment';
const CashierBucket = ({ Currency, handleDisableToggle }) => {
    const stripePromise = loadStripe('pk_test_51Kku1CLBbHIb8JaUQmSNv3YkbJHrbH1u2GaA2mkAOChyuEVYFAVHi7UotKNDnt2cSBBsYz9ebgndtPdkoXToInXP00fLEPzoyA');
    const [cardDetails, setCardDetails] = useState({
        cardOwner: '',
        cardNumber: '',
        expirationMonth: '',
        expirationYear: '',
        cvc: '',
        saveCardDetails: false,
    });

    const cartData = useSelector(getCartData)
    const [perItemRewardPoints, setPerItemRewardPoints] = useState(0);

    const componentRef = useRef();
    const comboCart = useSelector(getComboData);
    const addOnsCart = useSelector(getAddons);
    const taxImplicit = useSelector(getTaxImplicit);
    const taxExplicit = useSelector(getTaxExplicit);
debugger

    let navigate = useNavigate();
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });
    const location = useLocation();
    const tableData = useSelector(getTableData)
    const state = location?.state;
    const Update = state?.Update;
    const allowed = state?.allowed;

    const userId = useSelector(getUserId);
    const dispatch = useDispatch()
    // Calculate per-item reward points based on cart data
    const calculatePerItemRewards = () => {
        return cartData.map((item) => ({
            name: item.itemData.name,
            points: item.itemData.points * item.quantity,
        }));
    };

    // Function to calculate total points for per-item rewards
    const calculatePerItemRewardPoints = () => {
        const totalPoints = cartData.reduce(
            (sum, item) => sum + item.itemData.points * item.quantity,
            0
        );
        setPerItemRewardPoints(totalPoints.toFixed(2));
        return totalPoints.toFixed(2);
    };

    // Handler for setting the reward type and logging points based on selection
    const handleSetReward = () => {
        if (rewardType === "perItemReward") {
            const totalPerItemPoints = calculatePerItemRewardPoints(); // Update perItemRewardPoints
            console.log("Total Per Item Reward Points:", totalPerItemPoints);
        } else {
            console.log("Order Reward Points:", orderRewardPoints);
        }
        closeRewardModal()
    };
    const orderTypeR = useSelector((state) => state.cart.orderType);
    const [receiptValue, setReceiptValue] = useState("");
    const [heldOrders, setheldorders] = useState([]);
    const [taxes, setTax] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHeldModalOpen, setIsHeldModalOpen] = useState(false);
    const [discountModal, setDiscountModalOpen] = useState(false);
    const [TaxModal, setTaxModalOpen] = useState(false);
    const [SplitModal, setSplitModal] = useState(false);
    const [buttonEnable, setbuttonEnable] = useState(state?.state ? true : false);
    const [dateNow, setdateNow] = useState(false);
    const [totalPrice, setTotalPrice] = useState();
    const [orderType, setType] = useState(orderTypeR);
    const [payLater, setPayLater] = useState(false);
    const [PaymentType, setPaymentType] = useState(1);
    const [Customers, setCustomer] = useState([]);
    const [addcustomer, setAddCustomer] = useState(false);
    const [selectedTaxPercentage, setSelectedTaxPercentage] = useState([]);
    const [SelectedTaxRate, setSelectedTaxRate] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true) };
    const [selectedCustomer, setSelectedCustomer] = useState(null); // Store selected customer here

    const handleCustomerSelect = (selectedOption) => {
        if (selectedOption) {
            setSelectedCustomer(selectedCustomer);
            setAddCustomer(true)
        }
        setFormData({ customerName: selectedOption });
    }; const [areAllButtonsDisabled, setAreAllButtonsDisabled] = useState();
    const [areDiscountDisable, setDiscountDisable] = useState(false);
    const [isPaymentAllow, setPaymentAllow] = useState(false);
    const [areTaxDisable, setTaxDisable] = useState(false);
    let token = localStorage.getItem("Token")
    let Permission = userManagement?.getUserCrud(token)
    let role = userManagement?.getUserRole(token)
    const [totalRate, setTotalRate] = useState();
    const [splitNumber, setSplitNumber] = useState();
    const [splitAmounts, setSplitAmounts] = useState([totalRate]);
    const [totalSplitAmount, setTotalSplitAmount] = useState(totalRate); // Sum of split amounts
    const [amountsValid, setAmountsValid] = useState(true); // Validation state
    const [isRewardModalOpen, setIsRewardModalOpen] = useState(false);
    const [rewardType, setRewardType] = useState(''); // Track selected reward type
    const [orderRewardPoints, setOrderRewardPoints] = useState(); // For order reward points
    const [isCashOnDelivery, setIsCashOnDelivery] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsCashOnDelivery(e.target.checked);
    };

    // Handle opening and closing of reward modal
    const openRewardModal = () => {
        if (formData?.customerName) {

            setIsRewardModalOpen(true)
        } else {
            toast.warning("Please select customer to add reward points")
        }
    }
    const closeRewardModal = () => {
        setIsRewardModalOpen(false);
    };

    useEffect(() => {
        validateAmounts();
    }, [splitAmounts]);


    useEffect(() => {
        const isOrderPunchEnabled = Permission?.includes("Order Punch.add") && Permission.includes("Order Punch.query");
        setAreAllButtonsDisabled(!isOrderPunchEnabled)
        const isDiscountDisable = Permission?.includes("Discount.add") && Permission.includes("Discount.query");
        setDiscountDisable(!isDiscountDisable)
        const isTaxDisable = Permission?.includes("Tax.add") && Permission.includes("Tax.query");
        setTaxDisable(!isTaxDisable)
        const isPaymentAllow = Permission?.includes("Payment.add") && Permission.includes("Payment.query");
        setPaymentAllow(isPaymentAllow)
        const currentDate = new Date();
        const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
        const formattedDate = currentDate.toLocaleDateString('en-US', options);
        setdateNow(formattedDate);
        if (!tableData || tableData.length === 0) {
            setType(orderType);
            handleDisableToggle(orderType)

        } else {
            setType("Dine-In");
            handleDisableToggle('Dine-In');
        }
    }, [tableData]);

    const handleDisable = () => {
        if (orderType === null || orderType === undefined) {
            toast.warning("Please select order Type")
        }
        else {
            if (cartData.length > 0 || comboCart.length > 0) {
                setbuttonEnable(true);
                setFormData({ ...formData, amountCollected: 0 })
            } else {
                toast.warning("Please add item to process!")
            }
        }
    }

    const handleEnable = () => {
        setbuttonEnable(false);
    }
    const calculateSelectedTaxes = () => {
        let totalSelectedTaxes = 0;
        selectedTaxPercentage?.forEach(tax => {
            const taxValue = tax.value.taxPercentage || 0;
            const taxAmount = (totalRate * taxValue) / 100;
            totalSelectedTaxes += taxAmount;
        });

        return totalSelectedTaxes;
        setSelectedTaxRate(totalSelectedTaxes)
    };
    const calculateTotalPrice = () => {
        let totalPrice = 0;
    
        // Helper function to calculate the price for an item
        const calculateItemPrice = (item) => {
            return (
                (parseFloat(item?.selectedVariant?.variantPrice) ||
                    parseFloat(item?.itemData?.price) ||
                    parseFloat(item?.itemData?.menuItemPrice)) +
                (calculateAddOnsPrice(item?.selectedAdOn) || 0) +
                (calculateModifierPrice(item?.selectedModifier) || 0)
            );
        };
    
        // Calculate total price of items in cartData
        cartData?.forEach((item) => {
            if (state?.paymentStatus === 0 && !item?.itemData?.voidItem && !item?.void) {
                // If payment status is 0, item is not voided and not marked as void
                let itemPrice = calculateItemPrice(item);
                totalPrice += itemPrice * item?.quantity;
            } else if (!item?.itemData?.refunded && !item?.itemData?.voidItem && !item?.void) {
                // If item is not refunded, not voided, and still active
                let itemPrice = calculateItemPrice(item);
                totalPrice += itemPrice * item?.quantity;
            }
        });
    
        // Apply tax subtraction if tax is implicit
        if (!taxExplicit) {
            debugger
            totalPrice -= calculateTotalTax();
        }
    
        // Add price for comboCart
        comboCart?.forEach(item => {
            totalPrice += item?.price * item?.quantity;
        });
    
        // Add price for addOnsCart
        addOnsCart?.forEach(item => {
            totalPrice += item?.price * item?.quantity;
        });
    
        return totalPrice;
    };
    
    const calculateTotalPriceWithTaxesAndDiscounts = () => {
        let totalPrice = 0;
        debugger
        totalPrice += calculateTotalTax() || 0;
        totalPrice += calculateTotalPrice() || 0;
        totalPrice += calculateSelectedTaxes() || 0;
        totalPrice -= calculateTotalDiscount() || 0;
        return totalPrice;
    };

    const calculateAddOnsPrice = (addOns, index) => {
        return (addOns?.reduce((total, addOn) => total + parseInt(addOn?.metaItemPrice), 0) || 0);
    };
    const calculateModifierPrice = (addOns, index) => {
        return (addOns?.reduce((total, addOn) => total + parseInt(addOn?.metaItemPrice), 0) || 0);
    };
    const calculateTotalDiscount = () => {
        let totalPrice = 0;
        cartData?.forEach((item) => {

            if (state?.paymentStatus == 0) {
                if (!item?.void) {
                    if (!item?.itemData?.voidItem) {
                        const itemPrice = getItemDiscountPrice(item);
                        totalPrice += itemPrice * item?.quantity;
                    }
                }
            } else if (!item.refund) {
                if (!item?.itemData?.refunded) {
                    const itemPrice = getItemDiscountPrice(item);
                    totalPrice += itemPrice * item?.quantity;
                }
            }
        });

        return totalPrice || state?.orderDiscount;
    };
    const getItemDiscountPrice = (item) => {
        const discountType = item?.itemData?.discountType;
        debugger
        if (discountType === "Amount") {
            return parseInt(item?.itemData?.menuItemDiscount || item?.itemData?.discount || 0);
        } else if (discountType === "Percentage") {
            const discountPercentage = parseInt(item?.itemData?.discount || item?.itemData?.menuItemDiscount || 0);
            const itemPrice = parseInt(item?.selectedVariant?.variantPrice || item?.itemData?.price || item?.itemData?.menuItemPrice);
            return (itemPrice * discountPercentage) / 100;
        }
        return 0;
    };
    const calculateTotalTax = () => {
        let totalTax = 0;
        cartData?.forEach((item) => {

            if (state?.paymentStatus === 0) {
                if (!item?.void) {
                    if (!item?.itemData?.voidItem) {
                        const itemTax = calculateItemTax(item, state);
                        totalTax += itemTax * item?.quantity;
                    }
                }
            } else if (!item.refund) {
                if (!item?.itemData?.refunded) {
                    const itemTax = calculateItemTax(item, state);
                    totalTax += itemTax * item?.quantity;
                }
            }
        });

        return parseFloat(totalTax);
    };
    const calculateItemTax = (item, state) => {
        const taxRate = item?.itemData?.tax || item?.itemData?.menuItemTax || 0;
        const itemPrice = item.selectedVariant?.variantPrice || item?.itemData?.price || item?.itemData?.menuItemPrice || 0;
        let itemTax = 0;

        // if (!taxImplicit) {
            itemTax = (taxRate / 100) * itemPrice;
        // }

        const addOnsTax = calculateAddOnsTax(item.selectedAdOn);
        const modifierTax = calculateModifierTax(item.selectedModifier);

        // if (!taxImplicit) {
            return itemTax + addOnsTax + modifierTax;
        // } else {
        //     return addOnsTax + modifierTax;
        // }

    };
    const calculateAddOnsTax = (addOns) => {
        return (addOns?.reduce((total, addOn) => total + (parseInt(addOn.metaItemTax) || 0), 0) || 0);
    };
    const calculateModifierTax = (modifiers) => {
        return (modifiers?.reduce((total, modifier) => total + (parseInt(modifier.metaItemTax) || 0), 0) || 0);
    };
    useEffect(() => {
        GetMethods()
        setTotalRate(calculateTotalPrice());
        GetOrders()
        GetCostumer()
        GetTax()
    }, [cartData, comboCart]);
    const GetOrders = async () => {
        const apiService = new ApiService();
        let param = `?OrderStatus=${8}`

        let res = await apiService.getApiParamater("GET_ORDERS", param);

        setheldorders(res?.data?.result)
    }
    const GetTax = async () => {
        const apiService = new ApiService();
        let res = await apiService.get("GET_TAX");
        const filteredTax = res?.data?.result?.filter(tax => tax?.vat === true);
        setTax(filteredTax);
    }
    const handleDelete = (id, index) => {
        dispatch(RemoveFromCart(id, index));
        setTotalRate(calculateTotalPrice());
    };
    const handleQuantityChange = (item, index) => {
        dispatch(updateCartItemQuantity(index, item?.itemData?.id || item?.itemData?.itemId))
        setTotalRate(calculateTotalPrice());
    };
    const handleQuantityChangeDec = (item, index) => {
        dispatch(downgradeCartItemQuantity(index, item?.itemData?.id || item?.itemData?.itemId))
        setTotalRate(calculateTotalPrice());
    };
    const [formData, setFormData] = useState({
        name: '',
        amountCollected: '',
        due: parseFloat(calculateTotalPriceWithTaxesAndDiscounts() || 0),
        change: '',
        customerName: '',
    });
    const handleInputFromCalculator = (value) => {
        if (value === 'C') {
            const totalRates = parseFloat(value) - parseFloat(calculateTotalPriceWithTaxesAndDiscounts() || 0);
            setFormData(prevState => ({
                ...prevState,
                amountCollected: prevState?.amountCollected?.slice(0, -1),
                change: totalRates || 0

            }));
        } else {
            // Concatenate the value
            setFormData(prevState => ({
                ...prevState,
                amountCollected: prevState.amountCollected + value
            }));
        }
    };

    let due = ((calculateTotalPriceWithTaxesAndDiscounts() || 0))?.toLocaleString('en-US')

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'amountCollected') {
            const totalRates = parseFloat(value) - parseFloat(calculateTotalPriceWithTaxesAndDiscounts() || 0);
            setPayLater(false)
            const newFormData = {
                ...formData,
                [name]: parseFloat(value),
                change: totalRates || 0
            };
            setFormData(newFormData);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };
    const handleclearCart = () => {
        dispatch(clearCart());
        dispatch(clearComboCart());
        dispatch(clearTable());
        setSelectedTaxPercentage([])
        setFormData({ ...formData, customerName: '' });
        setSelectedCustomer('')
        setType('')
    }
    const handleDineIn = () => {
        setType("Dine-In")
        handleDisableToggle(orderType || 'Dine-In')
        navigate(`/cashier/Tables`, { state: formData });

    }
    const handleOrderType = (Type) => {
        dispatch(clearTable());
        dispatch(setOrderType(Type));
        setType(Type);
        handleDisableToggle(Type || 'Dine-In');
    };
    const handleHeldOrder = (heldOrder) => {
        dispatch(clearCart())
        dispatch(clearComboCart());
        dispatch(clearTable());
        const orderItems = heldOrder.orderItems;
        const orderItemsMeta = heldOrder.orderItemsMeta;

        const orderItemsMetaMap = {};

        orderItemsMeta.forEach(orderItemMeta => {
            const menuItemId = orderItemMeta.menuItemId;
            if (!orderItemsMetaMap[menuItemId]) {
                orderItemsMetaMap[menuItemId] = [];
            }
            orderItemsMetaMap[menuItemId].push(orderItemMeta);
        });

        orderItems.forEach(orderItem => {
            const menuItemId = orderItem.itemId;
            if (orderItemsMetaMap[menuItemId]) {
                orderItem.orderItemsMeta = orderItemsMetaMap[menuItemId];

                orderItem.selectedModifier = [];
                orderItem.selectedAdOn = [];
                orderItem.selectedVariant = null;

                orderItemsMetaMap[menuItemId].forEach(metaItem => {
                    switch (metaItem.metaType) {
                        case 2:
                            orderItem.selectedAdOn.push(metaItem);
                            break;
                        case 3:
                            orderItem.selectedModifier.push(metaItem);
                            break;
                        case 4:
                            orderItem.selectedVariant = metaItem;
                            break;
                    }
                });
            }
        });
        orderItems?.map((item) => {
            const mergedData = {
                selectedAdOn: item?.selectedAdOn,
                selectedModifier: item?.selectedModifier,
                selectedVariant: item?.selectedVariant,
                specialInstructions: item?.kitchenInstructions,
                itemData: item,
                quantity: item?.quantity,
            };
            dispatch(AddToCart(mergedData))
        })
        setIsHeldModalOpen(false)
    }
    const getOrderTypeEnumValue = (orderType) => {
        switch (orderType) {
            case "Dine-In":
                return 0;
            case "Delivery":
                return 2;
            case "Take-Away":
                return 1;
            case "Pick-up":
                return 7;
            default:
                return 0;
        }
    };
    const mapDynamicMetaData = (item) => {

        const result = [];
        if (item?.selectedModifier) {

            result.push(...item.selectedModifier?.map((modifier) => ({
                metaItemId: parseInt(modifier?.metaItemId),
                type: parseInt(modifier?.metaType),
                menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                metaItemIndex: parseInt(item?.index)
            })));
        }

        if (item?.selectedAdOn) {
            result.push(...item.selectedAdOn?.map((adOn) => ({
                metaItemId: parseInt(adOn?.metaItemId),
                type: parseInt(adOn?.metaType),
                menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                metaItemIndex: parseInt(item?.index)
            })));
        }

        if (item?.selectedVariant) {
            if (Array.isArray(item.selectedVariant)) {
                result.push(...item.selectedVariant?.map((variant) => ({
                    metaItemId: parseInt(variant?.metaItemId),
                    type: parseInt(variant?.metaType),
                    menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                    metaItemIndex: parseInt(item?.index)

                })));
            } else {
                result.push({
                    metaItemId: parseInt(item?.selectedVariant?.metaItemId),
                    type: parseInt(item?.selectedVariant?.metaType),
                    menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                    metaItemIndex: parseInt(item?.index)

                });
            }
        }
        return result;
    };
    let placeorder = false
    const placeOrder = async () => {
        try {
            if (orderType === null || orderType === undefined) {
                toast.warning("Please select Order type first")
            } else {


                const currentDate = new Date();
                const formattedDate = currentDate.toISOString();
                const datareq1 = {
                    orderDate: formattedDate,
                    orderType: getOrderTypeEnumValue(orderType),
                    orderSource: 0,
                    orderStatus: 1,
                    orderAmount: ((calculateTotalPriceWithTaxesAndDiscounts() || 0)),
                    calculatedTax: calculateTotalTax() + calculateSelectedTaxes(),
                    totalDiscount: calculateTotalDiscount(),
                    amountReturned: formData.change || 0,
                    customerType: 0,
                    tableId: orderType === "Dine-In" ? tableData?.[0]?.id : null,
                    customerId: userId?.toString() || "Guest",
                    customerName: formData?.customerName?.label || "Guest",
                    paymentStatus: 0,
                    invoiceNumber: "string",
                    orderItems: [
                        ...(cartData ? cartData.flatMap((item, index) => ({
                            itemId: parseInt(item.itemData.id || item?.itemData?.itemId),
                            quantity: parseInt(item.quantity),
                            kitchenInstructions: item.specialInstructions,
                            itemIndex: index
                        })) : []),
                    ],
                    orderCombos: [
                        ...(comboCart ? comboCart.flatMap((item, index) => {

                            const comboQuantity = parseInt(item.quantity) || 1;
                            return {
                                comboId: item.id,
                                quantity: comboQuantity,
                                itemIndex: item.index,
                                invoiceId: 0
                            };
                        }) : [])
                    ],
                    orderItemsMeta: [
                        ...(cartData ? cartData.flatMap(mapDynamicMetaData) : []),
                        ...(comboCart ? comboCart.flatMap((item, index) => {
                            const comboQuantity = parseInt(item.quantity) || 1;

                            return item.comboItems.flatMap(modifier => modifier.metaItemIds.map(metaItemId => ({
                                metaItemId: parseInt(metaItemId),
                                type: parseInt(3),
                                menuItemId: parseInt(modifier.itemId),
                                metaItemIndex: parseInt(index)
                            })));
                        }) : [])
                    ],
                    orderAddOns: [
                        ...(addOnsCart ? addOnsCart.flatMap((item, index) => {

                            const AddOnsQuantity = parseInt(item.quantity) || 1;
                            return {
                                addOnId: item.id,
                                quantity: AddOnsQuantity,
                                itemIndex: item.index,
                            };
                        }) : [])
                    ],
                    loggedInUserId: userId || "Guest",
                    discountId: cartData[0]?.itemData?.discountId,
                    promotionId: cartData[0]?.itemData?.promotionId
                };



                const apiService = new ApiService();
                if (cartData.length > 0 || comboCart.length > 0) {
                    let res = await apiService.post("CREATE_ORDER", datareq1);

                    const today = new Date();
                    const fourDaysLater = new Date(today);
                    fourDaysLater.setDate(today.getDate() + 4)
                    let body = {
                        customerId: formData?.customerName?.label,
                        points: rewardType == 'perItemReward' ? calculatePerItemRewardPoints() : orderRewardPoints,
                        earnedDate: Date.now(),
                        expirationDate: fourDaysLater,
                        status: "active",
                    }
                    if (res?.data?.statusCode === 201) {
                        // let res2 = await apiService.post("CREATE_REWARDS", body);
                        handlePrint(cartData, comboCart, addOnsCart, 'kitchen')
                        toast.success("Order Created Successfully");
                        handleUpdateTable()
                        handlePromotion()
                        handleEnable()
                        invokeBroadCastMessage(`New Order Received.`);
                        dispatch(clearCart());
                        dispatch(clearComboCart());
                        dispatch(clearTable());
                        setType(null)
                        setFormData({ ...formData, amountCollected: 0 });
                        setType(null)
                    } else {
                        toast.warning("Something is wrong in your order..Try again please!!");
                    }
                    placeorder = true
                }
                else {
                    toast.warning('Please Choose atleast single item to order!')
                }
            }
        } catch (error) {
            console.log(error)
        }

    }
    const handleHoldPayment = async (kit) => {
        try {
            if (cartData.length > 0 || comboCart.length > 0) {
                const currentDate = new Date();
                const formattedDate = currentDate.toISOString();

                if (Update) {
                    const datareq = {
                        id: Update.toString(),
                        orderStatus: kit ? 8 : allowed ? OrderStatus.Completed : 1,
                        orderAmount: calculateTotalPriceWithTaxesAndDiscounts() || 0,
                        amountCollected: formData.amountCollected || totalRate,
                        amountReturned: formData.change || 0,
                        customerName: formData.customerName?.label || "Guest",
                        paymentStatus: payLater ? 0 : 2,
                        calculatedTax: calculateTotalTax() + calculateSelectedTaxes(),
                        totalDiscount: calculateTotalDiscount(),
                        loggedInUserId: userId || "Guest",
                    };
                    const apiService = new ApiService();
                    let res = await apiService.put("UPDATE_ORDER", datareq)

                    if (res?.data?.statusCode === 201 || 200) {
                        let datareq2 = {
                            orderId: res?.data?.result?.id,
                            paymentDate: new Date().toISOString(),
                            paymentType: PaymentType,
                            paymentAmount: res?.data?.result?.orderAmount,
                            paymentMethod: PaymentType,
                            paymentStatus: payLater || placeorder ? 0 : 2,
                            transactionId: "-",
                            calculatedTax: calculateTotalTax() + calculateSelectedTaxes(),
                            orderAmount: calculateTotalPrice(),
                            loggedInUserId: userId || "Guest",
                        }

                        let res2 = await apiService.post("CREATE_PAYMENT", datareq2)
                        placeorder = false
                        handlePrint(cartData, comboCart, addOnsCart)
                        toast.success("Order Paid Succefully");
                        // handleUpdateTable()
                        setSelectedTaxPercentage([])
                        setType(null)
                        handleEnable()
                        invokeBroadCastMessage(`Order Updated.OrderNo#${Update?.toString()}`);
                        dispatch(clearCart());
                        dispatch(clearComboCart());
                        dispatch(clearTable());
                        handlePromotion()
                        setFormData({ ...formData, amountCollected: 0 });
                        setPayLater(false)
                        setType("")


                    } else {
                        toast.warning("Something is wrong in your order..Try again please!!");
                    }
                }
                else {
                    const datareq1 = {
                        orderDate: formattedDate,
                        orderType: getOrderTypeEnumValue(orderType),
                        orderSource: 0,
                        orderStatus: !kit.payment ? 8 : allowed ? OrderStatus.Completed : 1,
                        orderAmount: calculateTotalPriceWithTaxesAndDiscounts() || 0,
                        calculatedTax: calculateTotalTax() + calculateSelectedTaxes(),
                        totalDiscount: calculateTotalDiscount(),
                        amountCollected: formData?.amountCollected || 0,
                        amountReturned: formData.change || 0,
                        customerType: 0,
                        tableId: orderType === "Dine-In" ? tableData?.[0]?.id : null,
                        customerId: formData?.customerName?.value?.id,
                        customerName: formData.customerName?.label || "Guest",
                        paymentStatus: isCashOnDelivery ? 0 : placeorder ? 0 : 2,
                        invoiceNumber: "string",
                        orderItems: [
                            ...(cartData ? cartData.flatMap((item, index) => ({
                                itemId: parseInt(item.itemData.id || item?.itemData?.itemId),
                                quantity: parseInt(item.quantity),
                                kitchenInstructions: item.specialInstructions,
                                itemIndex: index
                            })) : [])
                        ],
                        orderCombos: [
                            ...(comboCart ? comboCart.flatMap((item, index) => {

                                const comboQuantity = parseInt(item.quantity) || 1;
                                return {
                                    comboId: item.id,
                                    quantity: comboQuantity,
                                    itemIndex: item.index,
                                    invoiceId: 0
                                };
                            }) : [])
                        ],
                        orderAddOns: [
                            ...(addOnsCart ? addOnsCart.flatMap((item, index) => {

                                const AddOnsQuantity = parseInt(item.quantity) || 1;
                                return {
                                    addOnId: item.id,
                                    quantity: AddOnsQuantity,
                                    itemIndex: item.index,
                                };
                            }) : [])
                        ],
                        orderItemsMeta: [
                            ...(cartData ? cartData.flatMap(mapDynamicMetaData) : []),
                            ...(comboCart ? comboCart.flatMap((item, index) => {
                                const comboQuantity = parseInt(item.quantity) || 1;

                                return item.comboItems.flatMap(modifier => modifier.metaItemIds.map(metaItemId => ({
                                    metaItemId: parseInt(metaItemId),
                                    type: parseInt(3),
                                    menuItemId: parseInt(modifier.itemId),
                                    metaItemIndex: parseInt(index)
                                })));
                            }) : [])
                        ],
                        loggedInUserId: userId || "Guest",
                        discountId: cartData[0]?.itemData?.discountId || null,
                        promotionId: cartData[0]?.itemData?.promotionId || null
                    };

                    const apiService = new ApiService();
                    if (!kit.tranStatus == '"DECLINED"') {

                        let res = await apiService.post("CREATE_ORDER", datareq1);
                        debugger
                        if (res?.data?.statusCode === 201) {
                            if (orderType === "Delivery") {

                                let datareq = {

                                    loggedInUserId: "string",
                                    orderId: res.data.result.id,
                                    customerId: formData?.customerName?.value?.id || "String",
                                    customerName: formData?.customerName?.value?.name || 'Guest',
                                    customerMobileNo: formData?.customerName?.value?.mobileNumber || '0',
                                    customerDeliveryAddress: formData?.selectedaddress || formData?.customerName?.value?.address1,

                                }
                                let res2 = await apiService.post("CREATE_DELIVERY_ORDER", datareq);
                            }
                            if (!payLater || !isCashOnDelivery) {
                                let datareq = {
                                    orderId: res?.data?.result?.id.toString(),
                                    paymentDate: new Date().toISOString(),
                                    paymentType: PaymentType,
                                    paymentAmount: res?.data?.result?.orderAmount,
                                    paymentMethod: PaymentType,
                                    paymentStatus: isCashOnDelivery ? 0 : placeorder ? 0 : 2,
                                    transactionId: kit?.tranId,
                                    calculatedTax: calculateTotalTax() + calculateSelectedTaxes(),
                                    orderAmount: calculateTotalPrice(),
                                    loggedInUserId: userId || "Guest",
                                    discountId: cartData.discountId || "",
                                    promotionId: 0,
                                }
                                const today = new Date();
                                const fourDaysLater = new Date(today);
                                fourDaysLater.setDate(today.getDate() + 4)

                                // if (rewardType) {
                                //     let body = {
                                //         customerId: formData?.customerName?.value?.id,
                                //         points: rewardType === 'perItemReward' ? calculatePerItemRewardPoints() : orderRewardPoints,
                                //         earnedDate: new Date().toISOString(), // Use ISO string for earnedDate
                                //         expirationDate: fourDaysLater.toISOString(), // Use ISO string for expirationDate
                                //         status: "active",
                                //     };
                                //     let response = await apiService.post("CREATE_REWARDS", body);
                                // }

                                let res2 = await apiService.post("CREATE_PAYMENT", datareq)

                                handlePrint(cartData, comboCart, addOnsCart)
                            }
                            if (kit?.payment || !kit) {
                                toast.success("Order Created Successfully");
                                setType(null)
                                setSelectedTaxPercentage([])
                                handleUpdateTable()
                                handlePromotion()
                                handleEnable()
                                invokeBroadCastMessage(`New Order Received.`);
                                dispatch(clearCart());
                                dispatch(clearComboCart());
                                dispatch(clearTable());
                                setFormData({ ...formData, amountCollected: 0 });
                                setPayLater(false)
                                placeorder = false
                            }
                            else {
                                toast.success("Order is on hold now");
                                setSelectedTaxPercentage([])
                                setType(null)
                                dispatch(clearCart());
                                dispatch(clearComboCart());
                                dispatch(clearTable());
                                handleUpdateTable()
                                handlePromotion()
                                setPayLater(false)

                            }
                        } else {
                            toast.warning("Something is wrong in your order..Try again please!!");
                        }
                    }

                }
            } else {
                toast.warning("Please add item to process!")
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const handlePromotion = async () => {
        try {
            const itemWithData = cartData.find(item => item.itemData.selectedPromotionDetails);
            if (itemWithData) {
                const selectedPromotionDetails = itemWithData.itemData.selectedPromotionDetails;
                const datareq = {
                    id: selectedPromotionDetails?.promotionDetialsId,
                    promotionID: selectedPromotionDetails?.promotionID,
                    vouchorNo: selectedPromotionDetails?.vouchorNo,
                    fromDate: selectedPromotionDetails?.fromDate,
                    toDate: selectedPromotionDetails?.toDate,
                    status: selectedPromotionDetails?.status,
                    useCount: selectedPromotionDetails?.useCount + 1
                }
                const apiService = new ApiService();
                let res = await apiService.put("UPDATE_PROMOTION_DETAIL", datareq);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleUpdateTable = async () => {
        try {
            if (orderType === 'Dine-In') {
                const datareq = {
                    id: orderType === "Dine-In" ? tableData?.[0]?.id : null,
                    seatCapacity: orderType === "Dine-In" ? tableData?.[0]?.selectedChairs : null,
                    status: 2
                };

                const apiService = new ApiService();
                let res = await apiService.put("UPDATE_TABLE", datareq);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleSplitChange = (e) => {
        const newSplitNumber = parseInt(e.target.value, 10);
        setSplitNumber(newSplitNumber);
        updateSplitAmounts(newSplitNumber, totalRate);
    };
    const updateSplitAmounts = (num, total) => {
        const newAmounts = Array(num)?.fill(total / num) || 0;
        setSplitAmounts(newAmounts);
        setTotalSplitAmount(total);
        setAmountsValid(true);
    };

    const handleAmountChange = (index, value) => {
        const newAmounts = [...splitAmounts];
        newAmounts[index] = parseFloat(value);
        setSplitAmounts(newAmounts);
        setTotalSplitAmount(newAmounts.reduce((sum, amount) => sum + amount, 0));
        setAmountsValid(newAmounts.reduce((sum, amount) => sum + amount, 0) === totalRate);
    };
    const validateAmounts = () => {
        const totalAmount = splitAmounts.reduce((sum, amount) => sum + amount, 0);
        setTotalSplitAmount(totalAmount);
        setAmountsValid(totalAmount === totalRate);
    };

    const handleSubmit = () => {
        if (!amountsValid) {
            toast.error("The split amounts do not add up to the total bill amount.");
            return;
        }
        closeSplitModal();
        // Proceed with payment logic here
    };

    const handleSplitButtonClick = (num) => {
        setSplitNumber(num);
        updateSplitAmounts(num, totalRate);
    };

    const changeStatus = async (id) => {
        try {
            let body = {
                id: id?.id,
                orderStatus: 2
            }
            const apiService = new ApiService();
            const response = await apiService.put('ORDER_STATUS', body);

        } catch (error) {
            console.error('Error:', error);
        }
    }
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);

        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            timeZoneName: "short",
        };

        return new Intl.DateTimeFormat("en-US", options).format(date);
    };
    const totalInvoiceAmount = cartData?.reduce(
        (total, item) => total + (item?.itemData?.price + item?.selectedAdOn?.price) * item?.quantity,
        0
    )
    const openModal = (e) => {
        setReceiptValue(e)
        setIsModalOpen(true);
    }

    const handlePrint = async (itemData, comboData, addsOn, kit) => {
        try {
            const data = {
                items: itemData,
                combos: comboData,
                addOns: addsOn,
                Points: rewardType === "perItemReward" ? (perItemRewardPoints) : (orderRewardPoints) || 0,
                kitchen: kit
            };

            const response = await axios.post("http://localhost:8001/print-receipt", data, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            console.log(response);

            // Handle response
            if (response.status !== 200) {
                throw new Error('Failed to print receipt');
            }

        }
        catch (error) {
            console.error('Error printing receipt:', error);
        }

    }
    const closeModal = () => {
        setIsModalOpen(false);
    }
    const openHeldModal = (e) => {
        setIsHeldModalOpen(true);
    }
    const closeDiscountModal = (e) => {
        setDiscountModalOpen(false);
    }
    const openDiscountModal = (e) => {
        setDiscountModalOpen(true);
    }
    const openSplitModal = (e) => {
        setSplitModal(true);
    }
    const closeTaxModal = (e) => {
        setTaxModalOpen(false);
    }
    const closeSplitModal = (e) => {
        setSplitModal(false);
    }
    const openTaxModal = (e) => {
        setTaxModalOpen(true);
    }
    const closeHeldModal = () => {
        setIsHeldModalOpen(false);
    }
    const handleSelectedTax = (selectedItem) => {
        setSelectedTaxPercentage(selectedItem || []);
    };
    // combo
    const handleComboQuantity = (item, index) => {

        dispatch(updateComboQuantity(index, item?.id));
        setTotalRate(calculateTotalPrice());
    };
    const handlecomboQuantityDec = (item, index) => {
        dispatch(downgradeComboQuantity(index, item?.id));
        setTotalRate(calculateTotalPrice());
    };
    const handleAddOnQuantityInc = (item, index) => {
        dispatch(updateAddonQuantity(item?.id, item?.quantity + 1));
        setTotalRate(calculateTotalPrice());
    };
    const handleAddOnQuantityDec = (item, index) => {
        dispatch(updateAddonQuantity(item?.id, item?.quantity - 1));
        setTotalRate(calculateTotalPrice());
    };
    const handleAddOnDelete = (item, index) => {
        dispatch(removeAddon(item?.id));
        calculateTotalPrice()
    };
    const handlecomboDelete = (item, index) => {
        dispatch(RemoveComboFromCart(item?.id, index));
        calculateTotalPrice()
    };
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedCartItem, setSelectedCartItem] = useState(null);

    const handleEditItem = (item) => {

        setIsEditModalOpen(true);
        setSelectedCartItem(item);
    };

    // const handlePrint = async (cartData, comboCart, addOnsCart) => {
    //     const calculateTotals = (items, combos, addOns) => {
    //         let subtotal = 0;
    //         let totalTax = 0;
    //         let totalDiscount = 0;

    //         items.forEach((item) => {
    //             subtotal += item.selectedVariant.variantPrice * item.quantity;
    //             totalTax += item.itemData.tax * item.quantity; 
    //             if (item.itemData.discount) {
    //                 totalDiscount += item.itemData.discount * item.quantity;
    //             }
    //         });

    //         combos.forEach((combo) => {
    //             subtotal += combo.price * combo.quantity;
    //         });

    //         addOns.forEach((addOn) => {
    //             subtotal += addOn.price * addOn.quantity;
    //         });

    //         const total = subtotal + totalTax - totalDiscount;

    //         return { subtotal, totalTax, totalDiscount, total };
    //     };

    //     const formatLine = (name, quantity, cost) => {
    //         const nameWidth = 20;
    //         const qtyWidth = 8;
    //         const costWidth = 6;
    //         const maxLineWidth = 45;

    //         const truncatedName = name.length > nameWidth ? name.slice(0, nameWidth - 1) + '.' : name;
    //         const namePart = truncatedName.padEnd(nameWidth, ' ');
    //         const qtyPart = quantity.toString().padStart(qtyWidth, ' ');
    //         const costPart = `$${cost?.toFixed(2)}`.padStart(costWidth, ' ');

    //         return `${namePart}${qtyPart}  X  ${costPart}`.slice(0, maxLineWidth);
    //     };

    //     const { subtotal, totalTax, totalDiscount, total } = calculateTotals(cartData, comboCart, addOnsCart);

    //     const printReceipt = async () => {
    //         const printerIp = 'http://192.168.18.123:9100'; 
    //         const escPosCommands = `
    //           \x1b\x40
    //           \x1b\x61\x31
    //           Qfinity\n
    //           \x1b\x21\x00
    //           Street Avenue No 90\n
    //           1234 - 9820284\n
    //           www.qfinity.ae\n
    //           ----------------------------------------\n
    //           ${new Date().toLocaleDateString()}    ${new Date().toLocaleTimeString()}   NO : 182028201\n
    //           ---------------------------------------------\n
    //           ${cartData.length > 0 ? 'ITEMS                      QTY                \n---------------------------------------------\n' : ''}
    //           ${cartData.map(item => formatLine(item.itemData.name, item.quantity, item.selectedVariant.variantPrice)).join('\n')}
    //           ${cartData.length > 0 ? '\n---------------------------------------------\n' : ''}
    //           ${comboCart.length > 0 ? 'COMBOS                     QTY              \n--------------------------------------------\n' : ''}
    //           ${comboCart.map(combo => formatLine(combo.name, combo.quantity, combo.price)).join('\n')}
    //           ${comboCart.length > 0 ? '\n---------------------------------------------\n' : ''}
    //           ${addOnsCart.length > 0 ? 'ADD-ONS                    QTY              \n--------------------------------------------\n' : ''}
    //           ${addOnsCart.map(addOn => formatLine(addOn.name, addOn.quantity, addOn.price)).join('\n')}
    //           ${addOnsCart.length > 0 ? '\n---------------------------------------------\n' : ''}
    //           Subtotal:              $${subtotal.toFixed(2)}\n
    //           Tax:                   $${totalTax.toFixed(2)}\n
    //           Discount:             -$${totalDiscount.toFixed(2)}\n
    //           \x1b\x21\x30
    //           Total:                 $${total.toFixed(2)}\n
    //           \x1b\x21\x00
    //           ---------------------------------------------\n
    //           \n\n
    //           Thank you for your purchase!\n
    //           \x1b\x64\x02
    //         `;

    //         try {
    //             const response = await fetch(printerIp, {
    //                 method: 'POST',
    //                 headers: { 'Content-Type': 'text/plain' },
    //                 body: escPosCommands,
    //             });

    //             if (response.ok) {
    //                 console.log('Print successful!');
    //             } else {
    //                 console.error('Failed to print.');
    //             }
    //         } catch (error) {
    //             console.error('Error printing:', error);
    //         }
    //     };

    //     printReceipt();
    //     handleOpenCashDrawer();
    // };

    const handleOpenCashDrawer = async () => {
        const printerIp = 'http://192.168.18.123:9100';
        const openCashDrawerCommand = `\x1b\x70\x00\x19\xFA`;

        try {
            const response = await fetch(printerIp, {
                method: 'POST',
                headers: { 'Content-Type': 'text/plain' },
                body: openCashDrawerCommand,
            });

            if (response.ok) {
                console.log('Cash drawer opened successfully!');
            } else {
                console.error('Failed to open cash drawer.');
            }
        } catch (error) {
            console.error('Failed to open cash drawer:', error);
        }
    };

    // let totalItemPrice = 0;
    // {
    //     cartData?.map((val) => {
    //         totalItemPrice += val?.itemData?.price * val?.quantity
    //     })
    // }
    let totalItemPrice = 0;
    {
        cartData?.map((val) => {
            totalItemPrice += val?.itemData?.price * val?.quantity
        })
    }
    useEffect(() => {
        setTotalPrice(totalItemPrice);
    }, [totalItemPrice]);
    const GetCostumer = async (page) => {
        const apiService = new ApiService();
        try {
            let res = await apiService.get("GET_COSTUMER");
            setCustomer(res?.data?.result);

        } catch (error) {
            console.error("Error fetching customer data:", error);
        }
    };
    const GetMethods = async () => {
        try {
            const apiService = new ApiService();
            let res = await apiService.get('GET_PAYMENT_METHOD');
            const filteredMenu = res.data.result.filter(item => item?.active === true);
            setPaymentMethod(filteredMenu);
        } catch (error) {
            console.error('Error fetching paymentMethod data:', error);
        }
    }
    return (
        <div >
            <div className='cartBG'>
                <div className='d-flex justify-content-between'>
                    <div>
                        <div>New Order</div>
                        <div className='datetme'>{dateNow}</div>
                    </div>
                    <div className=''>
                        <div className='d-flex justify-content-end'>
                            {tableData?.[0]
                                ? `Table: ${tableData?.[0]?.name}`
                                : `Order Type: ${orderType}`
                            }
                        </div>
                        <div className='datetme d-flex justify-content-end'>Cashier  :  {userManagement.getUserName(token)}</div>
                    </div>
                </div>
            </div>
            {buttonEnable === false ?
                <div className='d-flex justify-content-between py-4 overflow-auto gap-3'>
                    <div className={`categorycash ${orderType === "Dine-In" && 'active'}`} onClick={() => handleDineIn()}>Dine In</div>
                    <div className={`categorycash ${orderType === "Delivery" && 'active'}`} onClick={() => handleOrderType('Delivery')}>Delivery</div>
                    <div className={`categorycash ${orderType === "Take-Away" && 'active'}`} onClick={() => handleOrderType('Take-Away')}>Take Away</div>
                    <div className={`categorycash ${orderType === "Pick-up" && 'active'}`} onClick={() => handleOrderType('Pick-up')}>Pick Up</div>
                </div> :
                <div className='d-flex justify-content-between py-4 gap-3 overflow-auto'>
                    {paymentMethod?.map((val, key) => (
                        <div className={`categorycash ${PaymentType === val?.id && 'active'}`} onClick={() => setPaymentType(val?.id)}>{val?.name}</div>
                    ))}
                </div>
            }
            {buttonEnable === false ?
                <>
                    <div className='table-responsive'>
                        <table className="table p-2">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Modifier</th>
                                    <th>Quantity</th>
                                    <th>Rate</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartData?.map((item, index) => (
                                    <tr key={index} >
                                        <td style={item?.itemData?.voidItem ? { color: 'red' } : {}} title={item?.itemData?.name || item?.itemData?.menuItemName}>{(item?.itemData?.name || item?.itemData?.menuItemName)}</td>
                                        <td>
                                            <>
                                                {item?.selectedModifier?.map((modifier, i) => (
                                                    <div key={i} title={modifier.metaItemName}>
                                                        {modifier.metaItemName}
                                                    </div>
                                                ))}
                                            </>

                                        </td>

                                        <td>
                                            <div className="quantity-control d-flex justify-content-around center">
                                                {item?.quantity == 1
                                                    ? <div
                                                        className="addbtn"
                                                    >
                                                        -
                                                    </div> :
                                                    <div
                                                        onClick={() =>
                                                            handleQuantityChangeDec(
                                                                item, index
                                                            )
                                                        }
                                                        className="addbtn"
                                                    >
                                                        -
                                                    </div>
                                                }

                                                <div style={item?.itemData?.voidItem ? { color: 'red' } : {}, { paddingRight: '10px', paddingLeft: '10px' }} className="">
                                                    {item.quantity}
                                                </div>
                                                <div
                                                    onClick={() =>
                                                        handleQuantityChange(
                                                            item, index
                                                        )
                                                    }
                                                    className="addbtn"
                                                >
                                                    +
                                                </div>
                                            </div>
                                        </td>
                                        <td style={item?.itemData?.voidItem ? { color: 'red' } : {}}>

                                            {Currency.currency.name}  {item?.selectedVariant ? item?.selectedVariant?.variantPrice
                                                + calculateAddOnsPrice(item?.selectedAdOn, index) + calculateModifierPrice(item?.selectedModifier, index) :
                                                item?.itemData?.price || item?.itemData?.menuItemPrice + calculateAddOnsPrice(item?.selectedAdOn, index) + calculateModifierPrice(item?.selectedModifier, index)
                                            }
                                        </td>
                                        <td className='d-flex justify-content-between'>
                                            <Delete width={25} style={item?.itemData?.voidItem ? { color: 'red' } : {}} onClick={() => handleDelete(item?.itemData?.id || item?.itemData?.itemId, index)} />
                                            <BiEdit width={35} style={{ width: '22px', height: '22px', color: 'silver' }} onClick={() => handleEditItem(item)} />

                                        </td>
                                    </tr>
                                ))}
                                {comboCart?.map((item, index) => (
                                    <tr key={index}>
                                        <td title={item?.name}>{item?.name}</td>
                                        <td title={item?.name}>{item?.comboItems?.metaItem?.length} </td>
                                        <td>
                                            <div className="quantity-control  d-flex justify-content-around center">
                                                <div
                                                    onClick={() => handlecomboQuantityDec(item, index)}
                                                    className="addbtn ">
                                                    -
                                                </div>
                                                <div className="center " style={{ paddingRight: '10px', paddingLeft: '10px' }}>{item?.quantity}</div>
                                                <div
                                                    onClick={() => handleComboQuantity(item, index)}
                                                    className="addbtn "
                                                >
                                                    +
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {Currency.currency.name} {item?.price}
                                        </td>
                                        <td>
                                            <Delete onClick={() => handlecomboDelete(item, index)} />
                                            {/* <BiEdit onClick={() => handleEditItem(item)} /> */}

                                        </td>
                                    </tr>
                                ))}
                                {addOnsCart?.map((item, index) => (
                                    <tr key={index}>
                                        <td title={item?.name}>{item?.name}</td>
                                        <td title={item?.name}>{item?.comboItems?.metaItem?.length} </td>
                                        <td>
                                            <div className="quantity-control d-flex justify-content-around center">
                                                <div
                                                    onClick={() => handleAddOnQuantityDec(item, index)}
                                                    className="addbtn center">
                                                    -
                                                </div>
                                                <div className="center" style={{ paddingRight: '10px', paddingLeft: '10px' }}>{item?.quantity}</div>
                                                <div
                                                    onClick={() => handleAddOnQuantityInc(item, index)}
                                                    className="addbtn center"
                                                >
                                                    +
                                                </div>
                                            </div>
                                        </td>
                                        <td className=''>
                                            {Currency.currency.name} {item?.price}
                                        </td>
                                        <td>
                                            <Delete onClick={() => handleAddOnDelete(item, index)} />
                                            <BiEdit onClick={() => handleEditItem(item)} />

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='form-group'>
                        <div className='d-flex justify-content-between'>
                            <div className='cashhead'>Total Price</div>
                            <div className='cashhead'>{Currency.currency.name}  {totalRate?.toFixed(2)}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='cashhead'> Tax</div>
                            <div className='cashhead'>{Currency.currency.name}  {(calculateTotalTax().toFixed(2))}</div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='cashhead'>Discount</div>
                            <div className='cashhead'>{Currency.currency.name}  {calculateTotalDiscount() || 0}</div>
                        </div>
                        {TaxModal === false ?
                            <>{selectedTaxPercentage?.map((tax, index) => (
                                <div className='d-flex justify-content-between'>
                                    <div className='cashhead'>{tax?.label}</div>
                                    <div className='cashhead'>{tax?.value?.taxPercentage || 0} %</div>
                                </div>
                            ))}</> : " "
                        }
                        <div className="lineBreak"></div>
                        <div className='d-flex justify-content-between py-3'>
                            <div className='cashhead bolds'>Invoice Total</div>
                            <div className='cashhead'>{Currency.currency.name}  {(calculateTotalPriceWithTaxesAndDiscounts())?.toLocaleString('en-US')}</div>
                        </div>
                        {
                            buttonEnable === false ? "" :
                                <>
                                    <div className="">
                                        <label htmlFor="validationCustom02" className="form-label cashhead">Name</label>
                                        <input
                                            type="text"
                                            className="form-control cashinvc"
                                            id="validationCustom02"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    {payLater ? "" :
                                        <div className="">
                                            <label htmlFor="validationCustom02" className="form-label cashhead">Amount </label>
                                            <input
                                                type="number"
                                                className="form-control cashinvc"
                                                id="validationCustom01"
                                                name="name"
                                                value={formData.amountCollected}
                                                onChange={handleInputChange}

                                            />
                                            <div className="valid-feedback">
                                                Looks good!
                                            </div>
                                        </div>
                                    }
                                </>

                        }

                    </div>
                </>
                :
                PaymentType === 1 ?
                    <div className='p-2'>
                        <div className="row">
                            <div className="col-lg-5">
                                <div>
                                    <label htmlFor="validationCustom01" className="form-label cashhead">
                                        Order Amount
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control cashinvc"
                                        id="validationCustom01"
                                        name="due"
                                        value={due}
                                        disabled
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div className="py-2">
                                    <label htmlFor="validationCustom02" className="form-label cashhead">
                                        Amount Collected
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control cashinvc"
                                        id="validationCustom02"
                                        name="amountCollected"
                                        value={formData?.amountCollected}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                <div>
                                    <label htmlFor="validationCustom03" className="form-label cashhead">
                                        Amount Return
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control cashinvc"
                                        id="validationCustom03"
                                        name="change"
                                        value={formData.change}
                                        onChange={() => { }}
                                        required
                                    />
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                                {allowed ? '' :
                                    <div className="pt-2">
                                        <label htmlFor="validationCustom04" className="form-label cashhead">
                                            Customer
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control cashinvc"
                                                id="validationCustom02"
                                                name="customerName"
                                                value={formData.customerName?.label || " "}
                                                disabled
                                                required
                                            />
                                            <button
                                                className="btn btn-outline-secondary cashinvc"
                                                type="button"
                                                onClick={handleShow}
                                            >
                                                <MdPersonAddAlt1 />
                                            </button>
                                        </div>
                                    </div>
                                }

                                {
                                    allowed ? '' :

                                        <div className="pt-3">
                                            <input
                                                type="checkbox"
                                                id="cashOnDelivery"
                                                checked={isCashOnDelivery}
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="cashOnDelivery" className="ms-2 cashhead">
                                                Cash on Delivery
                                            </label>
                                        </div>
                                }
                            </div>
                            <div className="col-lg-7">
                                <Calculator handleInput={handleInputFromCalculator} />
                            </div>
                        </div>
                    </div>
                    : PaymentType === 2 ?
                        <div className='p-2'>
                            {/* This section is for PaymentType 2 (e.g., card payment) */}
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <div className="">
                                        <label htmlFor="balance" className="form-label cashhead">Balance</label>
                                        <input
                                            type="number"
                                            className="form-control cashinvc"
                                            id="balance"
                                            name="balance"
                                            value={formData.balance}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <label htmlFor="received" className="form-label cashhead">Received</label>
                                        <input
                                            type="number"
                                            className="form-control cashinvc"
                                            id="received"
                                            name="received"
                                            value={formData.received}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="last4Digits" className="form-label cashhead">Last 4 Digit #</label>
                                        <input
                                            type="text"
                                            className="form-control cashinvc"
                                            id="last4Digits"
                                            name="last4Digits"
                                            value={formData.last4Digits}
                                            onChange={handleInputChange}
                                            maxLength={4}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="py-2">
                                        <label htmlFor="approvalNumber" className="form-label cashhead">Approval #</label>
                                        <input
                                            type="text"
                                            className="form-control cashinvc"
                                            id="approvalNumber"
                                            name="approvalNumber"
                                            value={formData.approvalNumber}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="pt-2">
                                        <div className=' row justify-content-around d-flex align-item-center justify-center gap-2'>

                                            <div className='col-lg-5 btn btn-primary d-flex align-item-center justify-center'>
                                                <div className=' w-lg-100 '>Visa Card</div>
                                            </div>
                                            <div className='col-lg-5 btn btn-primary align-item-center justify-center'>

                                                <div className='w-lg-100 '>Master Card</div>
                                            </div>
                                            <div className='col-lg-5 btn btn-primary align-item-center justify-center' >

                                                <div className='w-lg-100 '>American Express</div>
                                            </div>
                                            <div className='col-lg-5 btn btn-primary align-item-center justify-center'>

                                                <div className='w-lg-100 '>Union Pay</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-7'>
                                    <Calculator handleInput={handleInputFromCalculator} />
                                </div>
                            </div>
                        </div>
                        : PaymentType == 3 ?
                            <div className="p-2">
                                {/* This section is for PaymentType 3 (Flocash Tap2Phone payment) */}
                                <h4>Flocash Tap2Phone Payment</h4>
                                <div className='row'>
                                    <div className='col-lg-5'>
                                        <p>Please click the button below to initiate Flocash payment:</p>
                                        {/* <button onClick={initiateFlocashPayment} className="btn btn-primary">
                                Start Flocash Payment
                              </button> */}
                                        <PaymentComponent Currency={Currency} amount={calculateTotalPriceWithTaxesAndDiscounts()} onPaymentSuccess={handleHoldPayment} />
                                    </div>
                                    <div className='col-lg-7'>
                                        {/* You can add any additional details or UI */}
                                    </div>
                                </div>
                            </div>
                            :
                            <Elements stripe={stripePromise}>
                                <CashStripe
                                    handlePaymentSuccess={handleHoldPayment}
                                    data={cartData}
                                    comboCart={comboCart}
                                    cardDetails={cardDetails}
                                    total={calculateTotalPriceWithTaxesAndDiscounts()}
                                />
                            </Elements>
            }
            {buttonEnable === false ?
                <div class='button-container'>
                    {areAllButtonsDisabled ?
                        <>
                            <button disabled class='cashbtn disable' style={{ cursor: "pointer" }}>Clear Order</button>

                            {
                                Update ? "" :
                                    (!formData?.amountCollected > 0 || !formData?.amountCollected) && allowed ?
                                        <button class={`AddBtn disable ${payLater ? 'active' : ''}`} disabled >Place Order</button>
                                        :
                                        <button class={`AddBtn disable ${payLater ? 'active' : ''}`}>Place Order</button>
                            }
                            {role == 'Waiter' ? " " :

                                <button disabled class='AddBtn disable w-50'>Tender</button>
                            }
                        </> :
                        <>
                            <button class='cashbtn' onClick={() => handleclearCart()} style={{ cursor: "pointer" }}>Clear Order</button>
                            <button onClick={() => handleHoldPayment("holdit")} class='cashbtn' style={{ cursor: "pointer" }}>Hold sale</button>
                            <button onClick={() => openHeldModal(cartData)} class='cashbtn' style={{ cursor: "pointer" }}>Load Held Sales</button>
                            <button onClick={() => handleShow()} class='cashbtn' style={{ cursor: "pointer" }}>Add Customer</button>

                            <>
                                {areDiscountDisable ?
                                    " "
                                    :
                                    <button onClick={() => openDiscountModal()} class='cashbtn' style={{ cursor: "pointer" }}>Add Discount</button>
                                }
                                {/* {areTaxDisable ?
                                    " " :

                                    <button onClick={() => openTaxModal()} class='cashbtn' style={{ cursor: "pointer" }}>Add Tax</button>
                                } */}
                            </>

                            {
                                Update ? "" :
                                    (!formData?.amountCollected > 0 || !formData?.amountCollected) && (orderTypeR === 'Delivery' ? allowed : true) ?
                                        <>
                                            {
                                                <button class={`AddBtn ${payLater ? 'active' : ''}`} onClick={() => placeOrder()}>Place Order</button>
                                            }
                                        </>
                                        :
                                        <button class={`AddBtn disabled ${payLater ? 'active' : ''}`}>Place Order</button>
                            }
                            {/* {role == 'Waiter' ? " " : */}
                            <>

                                {isPaymentAllow ?
                                    <>
                                        {
                                            <button onClick={() => handleDisable()} class='AddBtn w-50'>Tender</button>
                                        }
                                    </>
                                    : " "
                                }
                            </>

                            {/* } */}
                        </>

                    }
                </div>
                :
                <>
                    <div className='center mt-3 row gap-3'>
                        {
                            allowed ? ' ' :
                                <>
                                    <button className='AddBtn col' onClick={() => handleEnable()}> Back</button>
                                    <button className='AddBtn col w-25' onClick={() => openSplitModal()}><BsFileBreak />  Split Bill</button>
                                </>

                        }
                        {/* <button onClick={handlePrint}>
                        Print Receipts
                    </button> */}
                        {allowed ? ''
                            :
                            <button onClick={() => handlePrint(cartData, comboCart, addOnsCart)} className=' billBtn '>Print Bill</button>
                        }
                    </div>
                    <div className='center mt-3 row gap-3'>

                        {(formData?.amountCollected >= parseInt(totalRate + (calculateTotalTax() || 0)) - (calculateTotalDiscount() || 0) && !formData?.amountCollected == 0) || payLater == true || isCashOnDelivery || allowed
                            ?
                            <button onClick={() => handleHoldPayment()} className='AddBtn col-lg-4'>{(Update || allowed) ? "Pay Order" : "Create Order"}</button>
                            :
                            <button className='AddBtn disable col-lg-4 disabled' >{(Update || allowed) ? "Pay Order" : "Create Order"}</button>

                        }
                        <button className='AddBtn col' onClick={openRewardModal}>Add Reward</button>

                        {isRewardModalOpen && (
                            <Modal
                                show={isRewardModalOpen}
                                onHide={closeRewardModal}
                                contentLabel='Rewards'
                                value={rewardType}
                                style={customStyles}
                            >
                                <div className="reward-modal-overlay">
                                    <div className="reward-modal">
                                        <h4>Select Reward Type</h4>
                                        <div className="reward-options">
                                            <label className="reward-option">
                                                <input
                                                    type="radio"
                                                    name="rewardType"
                                                    value="orderReward"
                                                    checked={rewardType === "orderReward"}
                                                    onChange={() => setRewardType("orderReward")}
                                                />
                                                Order Reward
                                            </label>
                                            <label className="reward-option">
                                                <input
                                                    type="radio"
                                                    name="rewardType"
                                                    value="perItemReward"
                                                    checked={rewardType === "perItemReward"}
                                                    onChange={() => setRewardType("perItemReward")}
                                                />
                                                Per Item Reward
                                            </label>
                                        </div>

                                        {rewardType === "orderReward" && (
                                            <div className="order-reward-input">
                                                <label>Order Reward Points:</label>
                                                <input
                                                    type="number"
                                                    value={orderRewardPoints}
                                                    onChange={(e) => setOrderRewardPoints(e.target.value)}
                                                    placeholder="Enter reward points"
                                                />
                                            </div>
                                        )}

                                        {rewardType === "perItemReward" && (
                                            <div className="per-item-reward mt-3">
                                                <p>Per-Item Reward Points:</p>
                                                {calculatePerItemRewards().map((reward, idx) => (
                                                    <div key={idx} className="reward-item m-3">
                                                        <span>{reward.name}: </span>
                                                        <span>{reward.points.toFixed(2)} points</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div className="row">
                                            <button className="AddBtn w-100 mb-3" onClick={handleSetReward}>
                                                Submit Order Reward
                                            </button>
                                            <button className="close-btn" onClick={closeRewardModal}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                        )}
                    </div>
                </>

                // </div>

            }
            <Modal
                show={isEditModalOpen}
                onRequestClose={closeModal}
                contentLabel='Cart Modal'
            >
                {/* {isEditModalOpen && ( */}
                <CartPopup
                    CartValue={selectedCartItem?.itemData}
                    selectedAdOn={selectedCartItem?.selectedAdOn}
                    selectedModifier={selectedCartItem?.selectedModifier}
                    selectedVariant={selectedCartItem?.selectedVariant}
                    closeModal={() => setIsEditModalOpen(false)}
                    isEdit={true} // Indicate edit mode
                    editingItem={selectedCartItem}
                />
                {/* )} */}
            </Modal>
            <Modal
                show={isModalOpen}
                onHide={closeModal}
                contentLabel='Receipt Modal'
                value={receiptValue}
                style={customStyles}
            >
                {/* <button onClick={handlePrint} className='btn btn-primary'>Print Receipt</button> */}

                <CashierReceipt ref={componentRef} val={receiptValue} formData={formData} closeModal={closeModal} />
            </Modal>

            {/* Modal for held orders */}
            <Modal
                show={isHeldModalOpen}
                onHide={closeHeldModal}
                contentLabel='Receipt Modal'
                style={customStyles}
            >
                <div className='p-3'>
                    <h4>Held Orders</h4>
                    {heldOrders?.map((heldOrder, index) => (
                        <div className='calbx1 p-2 m-2 cursor-pointer radius' key={index} onClick={() => handleHeldOrder(heldOrder)}>
                            <div >
                                Order ID #{heldOrder.id} - ({formatDate(heldOrder.orderDate)})
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
            {/* Discount Modal */}
            <Modal size="lg"
                show={discountModal} onHide={closeDiscountModal}
                onRequestClose={closeDiscountModal}
                contentLabel='Receipt Modal'
                style={customStyles} closeButton
            >
                <DiscountModal closeDiscountModal={closeDiscountModal} />
            </Modal>
            {/* Tax Modal */}
            <Modal size="md"
                show={TaxModal} onHide={closeTaxModal}
                onRequestClose={closeTaxModal}
                contentLabel='Receipt Modal'
                style={customStyles} closeButton
            >
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Add Tax</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreatableSelect
                        isMulti
                        options={taxes?.map((taxes) => ({ value: taxes, label: taxes?.name }))}
                        value={selectedTaxPercentage}
                        onChange={handleSelectedTax}
                        onCreateOption={null}
                        className=''
                        isClearable
                    />
                </Modal.Body>
                <Modal.Footer className='end p-3'>
                    <button className='AddBtn' onClick={closeTaxModal}>Save</button>
                </Modal.Footer>
            </Modal >

            <Modal
                size="lg"
                show={SplitModal}
                onHide={closeSplitModal}
                aria-labelledby="split-modal"
                ariaHideApp={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="split-modal" className='center'>Split Bill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='p-4'>
                        <div className='form-group mb-4'>
                            <label htmlFor='splitNumber' className='mb-3'>Number of Splits:</label>
                            <input
                                type='number'
                                id='splitNumber'
                                value={splitNumber}
                                onChange={handleSplitChange}
                                className='form-control'
                                min="1"
                            />
                        </div>
                        <div className='h6 flex-end mb-5'>
                            Total Bill: {totalRate} / {splitNumber} People
                        </div>
                        <div className='split-buttons row center mb-3'>
                            {[2, 3, 4, 5, 7].map(num => (
                                <button
                                    key={num}
                                    className={`btn mx-1 col-lg-2 ${splitNumber === num ? 'active' : 'btn-primary'}`}
                                    onClick={() => handleSplitButtonClick(num)}
                                >
                                    {num}
                                </button>
                            ))}
                        </div>
                        <div className='split-amounts row center'>
                            {splitAmounts.map((amount, index) => (
                                <div className="col-lg-3 m-2 p-3" key={index}>
                                    <label>Per {index + 1}:</label>
                                    <input
                                        type='number'
                                        value={amount}
                                        onChange={(e) => handleAmountChange(index, e.target.value)}
                                        className={`form-control ${!amountsValid && 'is-invalid'}`}
                                        min="0"
                                    />
                                    <span className='text-success'>{Currency.currency.name}</span>
                                </div>
                            ))}
                        </div>
                        <div className='h6 flex-end mt-4'>
                            Current Total: {totalSplitAmount} / {totalRate}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='end p-3'>
                    <button className='AddBtn w-25' onClick={handleSubmit}>Pay</button>
                </Modal.Footer>
            </Modal>

            {/* Add Customer */}
            < Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Add Customer</Modal.Title>
                </Modal.Header>
                <div className='p-4'>
                    {/* {addcustomer === true ? */}
                    <div>
                        <CreatableSelect
                            isMulti={false}
                            options={Customers?.map((Items) => ({
                                value: Items,
                                label: `${Items.name} (${Items.mobileNumber})`
                            }))}
                            value={formData?.customerName}
                            onChange={(value) => handleInputChange({ target: { name: 'customerName', value } })}
                            onCreateOption={handleCustomerSelect}
                            placeholder="Select Customer"
                            className=""
                            required
                        />

                        <div className='row mt-4'>
                            <div className='end '>
                                <button className='addItem-btn text-center' type="submit" onClick={() => handleClose()}>Select</button>
                            </div>
                        </div>
                    </div>
                    <CustomerCreatePOpup
                        orderType={orderType}
                        GetCostumer={GetCostumer}
                        setAddCustomer={setAddCustomer}
                        close={handleClose}
                        selectedCustomer={formData?.customerName}
                        address={(address) =>
                            setFormData((prevFormData) => ({ ...prevFormData, selectedaddress: address }))
                        }
                    />

                </div>
            </Modal >
        </div >
    )
}

const mapStatetoProps = (state) => state;
const mapDispatchToProps = {

    updateCartItemQuantity
};

export default connect(mapStatetoProps, mapDispatchToProps)(CashierBucket);

const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};