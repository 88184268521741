import { SET_TAX_EXPLICIT, SET_TAX_IMPLICIT } from '../actions/taxActions';

const initialState = {
    taxExplicit: false,
    taxImplicit: false,
};

const taxReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAX_EXPLICIT:
            return {
                ...state,
                taxExplicit: action.payload,
            };
        case SET_TAX_IMPLICIT:
            return {
                ...state,
                taxImplicit: action.payload,
            };
        default:
            return state;
    }
};

export default taxReducer;
