import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART_ITEM_QUANTITY,
  DOWNGRADE_CART_ITEM_QUANTITY,
  CLEAR_CART,
  UPDATE_CART_DISCOUNT,
  UPDATE_CART_ITEM,
  SET_ORDER_TYPE
} from '../actions/types';

const initialState = {
  items: [],
  discount: 0,
  orderType: '', 
};


const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART: {
      const newIndex = state.items.length;
      const newItem = { ...action.payload, index: newIndex, id: action.payload.itemData.id || Date.now() };
      return {
        ...state,
        items: [...state.items, newItem],
      };
    }
    case SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload,
      };
      
    case REMOVE_FROM_CART: {
      return {
        ...state,
        items: state.items.filter(
          (item, i) => item.itemData.id !== action.payload.itemId || i !== action.payload.index
        ),
      };
    }

    case UPDATE_CART_ITEM_QUANTITY: {
      return {
        ...state,
        items: state.items.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        }),
      };
    }

    case DOWNGRADE_CART_ITEM_QUANTITY: {
      return {
        ...state,
        items: state.items.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              quantity: item.quantity - 1,
            };
          }
          return item;
        }),
      };
    }

    case UPDATE_CART_DISCOUNT: {
      return {
        ...state,
        items: state.items.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...item,
              itemData: {
                ...item.itemData,
                discount: action.payload.discount,
                selectedPromotionDetails: action.payload.selectedPromotionDetails,
                discountId: action.payload.discountId,
                promotionId: action.payload.promotionId,
                discountType: action.payload.discountType,
              },
            };
          }
          return item;
        }),
      };
    }

    case UPDATE_CART_ITEM: {
      const updatedItems = state.items.map((item) =>
        item.itemData.id === action.payload.itemData.id ? { ...item, ...action.payload } : item
      );
      return {
        ...state,
        items: updatedItems,
      };
    }

    case CLEAR_CART: {
      return {
        ...state,
        items: [],
      };
    }

    default:
      return state;
  }
};

export default cartReducer;
