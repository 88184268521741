import { ADD_TO_CART, CLEAR_CART, DOWNGRADE_CART_ITEM_QUANTITY, REMOVE_FROM_CART, UPDATE_CART_ITEM_QUANTITY, UPDATE_CART_DISCOUNT,SET_ORDER_TYPE } from "./types"

export const AddToCart = (item) => ({
  type: ADD_TO_CART,
  payload: item,
});

export const RemoveFromCart = (itemId, index) => ({
  type: REMOVE_FROM_CART,
  payload: { itemId, index },
});

export const updateCartItemQuantity = (index, itemId, quantity) => ({
  type: UPDATE_CART_ITEM_QUANTITY,
  payload: { index, itemId, quantity },
});

export const downgradeCartItemQuantity = (index, itemId, quantity) => ({
  type: DOWNGRADE_CART_ITEM_QUANTITY,
  payload: { index, itemId, quantity },
});
export const updateDiscount = (index, itemId, discount, selectedPromotionDetails, discountType,discountId,promotionId) => {
  return {
    type: UPDATE_CART_DISCOUNT,
    payload: { index, itemId, discount, selectedPromotionDetails, discountType ,discountId,promotionId},
  };
};
export const updateCartItem = (updatedItem) => ({
  type: 'UPDATE_CART_ITEM',
  payload: updatedItem,
});


export const clearCart = () => ({
  type: CLEAR_CART,
});
export const setOrderType = (orderType) => ({
  type: SET_ORDER_TYPE,
  payload: orderType,
});