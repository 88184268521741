import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { BiEdit } from 'react-icons/bi';
import ApiService from '../../../../services/apiService';
import "../master.css";

const UserRoleTable = ({ data }) => {
    const [isActive, setIsActive] = useState(data?.data?.isActive || false);

    useEffect(() => {
        // Synchronize state with the passed data prop
        setIsActive(data?.data?.isActive || false);
    }, [data?.data?.isActive]);

    const toggleClaimStatus = async (id, newStatus) => {
        try {
            debugger
            const body = {
                rolesDtoInfo: {
                    id: data.id, // Pass the specific role ID
                    active: newStatus,
                },
            };
            const apiService = new ApiService();
            const res = await apiService.post(`UPDATE_ROLE`, body);

            if (res?.data?.statusCode === 200) {
                toast.success(res.data.message);
                setIsActive(newStatus); // Update state only if API call succeeds
            } else {
                toast.error("Failed to update role status.");
            }
        } catch (error) {
            console.error('Error toggling claim status:', error);
            toast.error("An error occurred while updating the role status.");
        }
    };

    const editUserRole = async (roleId) => {
        try {
            const body = { id: roleId };
            const apiService = new ApiService();
            const res = await apiService.put(`UPDATE_ROLE`, body);

            if (res?.data?.statusCode === 200) {
                toast.success("Role updated successfully");
            } else {
                toast.error("Failed to update role.");
            }
        } catch (error) {
            console.error('Error updating role:', error);
            toast.error("An error occurred while updating the role.");
        }
    };

    const handleSwitchChange = () => {
        const newStatus = !isActive;
        toggleClaimStatus(data?.id, newStatus); // Pass the specific role ID to the API
    };

    return (
        <tr>
            <td>{data?.name || "N/A"}</td>
            <td>
                <div className="action-icons d-flex gap-4 align-items-center">
                    <BiEdit
                        size={25}
                        onClick={() => editUserRole(data?.id)} 
                        className="edit-icon"
                    />
                    <div className="d-flex text-center gap-3">
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`flexSwitchCheckDefault-${data?.id}`}
                                checked={isActive}
                                onChange={handleSwitchChange}
                            />
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default UserRoleTable;
