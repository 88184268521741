import React, { useState, useEffect } from 'react';
import CartSlider from '../../Customer/Cart/imgSlider';
import "../../Customer/Cart/cart.css";
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
import { AddToCart, updateCartItem } from '../../../Redux/actions/cartAction';

const CartModal = ({
  CartValue,
  closeModal,
  isEdit,
  editingItem,
  Currency
}) => {
  const [selectedAdOn, setSelectedAdOn] = useState([]);
  const [selectedModifier, setSelectedModifier] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [specialInstructions, setSpecialInstructions] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let isEditing= isEdit
  useEffect(() => {
    // Pre-fill the modal with data if editing
    if (isEditing && editingItem) {
      setSelectedAdOn(editingItem.selectedAdOn || []);
      setSelectedModifier(editingItem.selectedModifier || []);
      setSelectedVariant(editingItem.selectedVariant || null);
      setSpecialInstructions(editingItem.specialInstructions || '');
    }
  }, [isEditing, editingItem]);

  const AdOns = CartValue?.menuItemMeta;
  const itemData = CartValue;

  useEffect(() => {
    // Automatically select the first variant if available
    const firstVariant = AdOns?.find((item) => item.metaType === 4);
    if (!isEditing && firstVariant) {
      setSelectedVariant(firstVariant);
    }
  }, [AdOns, isEditing]);

  const handleCheckboxChange = (item, type) => {
    if (type === 'AddsOn') {
      setSelectedAdOn((prev) =>
        prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
      );
    } else if (type === 'Modifier') {
      setSelectedModifier((prev) =>
        prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
      );
    }
  };

  const handleRadioChange = (item) => {
    setSelectedVariant(item);
  };

  const handleSpecialInstructionsChange = (event) => {
    setSpecialInstructions(event.target.value);
  };

  const calculateTotalAmount = () => {
    let totalAmount;

    if (selectedVariant) {
      totalAmount = parseInt(selectedVariant?.variantPrice || 0);
    } else {
      totalAmount = parseInt(CartValue?.price || 0);
    }

    selectedAdOn?.forEach((item) => {
      totalAmount += parseInt(item?.metaItemPrice || 0);
    });
    selectedModifier?.forEach((item) => {
      totalAmount += parseInt(item?.metaItemPrice || 0);
    });

    return totalAmount?.toLocaleString('en-US');
  };

  const handleATC = () => {
    debugger
    const mergedData = {
      selectedAdOn,
      selectedModifier,
      selectedVariant,
      specialInstructions,
      itemData: CartValue,
      quantity: isEditing ? editingItem.quantity : 1,
    };
  debugger
    if (isEditing) {
      dispatch(updateCartItem({ ...mergedData, index: editingItem.index }));
    } else {
      dispatch(AddToCart(mergedData));
    }
    closeModal(true);
  };
  

  const groupedItems = AdOns?.reduce((acc, item) => {
    acc[item.metaType] = acc[item.metaType] || [];
    acc[item.metaType].push(item);
    return acc;
  }, {});

  return (
    <div className=''>
      <div className='container-fluid m-0 p-0 pt-5 px-5'>
        <div className="row py-lg-3">
          <div className="col-lg-4">
            <CartSlider val={itemData} />
          </div>
          <div className="col-lg-8 px-lg-5">
            <div className="CartName">{itemData?.name}</div>
            <div className="cartPrice pt-2">{Currency.currency.name} {itemData?.price?.toLocaleString('en-US')}</div>
            <div className="py-lg-5">
              {Object.keys(groupedItems || {}).map((metaType) => {
                const filteredItems = groupedItems[metaType]?.filter(
                  (item) => !(metaType === '3' && !item.modifierActive)
                );
                if (metaType === '3' && filteredItems.length === 0) {
                  return null;
                }
                return (
                  <div key={metaType}>
                    <h5 className='mt-3'>
                      {metaType == 2 ? 'Add On' : metaType == 3 ? 'Modifiers' : 'Variants'}
                    </h5>
                    {filteredItems.map((item) => (
                      <div key={item?.metaItemId}>
                        {metaType === '2' && (
                          <div className="form-check d-flex justify-content-between pb-3">
                            <div className="gap-3 d-flex">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedAdOn.includes(item)}
                                style={{ width: "26.81px", height: "26.81px" }}
                                onChange={() => handleCheckboxChange(item, 'AddsOn')}
                              />
                              <label className="form-check-label checkItem" htmlFor={item?.metaItemId}>
                                {item?.metaItemName}
                              </label>
                            </div>
                            <div className="checkItem">
                              {Currency?.currency?.name} {parseInt(item?.metaItemPrice || 0)}
                            </div>
                          </div>
                        )}
                        {metaType === '3' && (
                          <div className="form-check d-flex justify-content-between pb-3">
                            <div className="gap-3 d-flex">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedModifier.includes(item)}
                                style={{ width: "26.81px", height: "26.81px" }}
                                onChange={() => handleCheckboxChange(item, 'Modifier')}
                              />
                              <label className="form-check-label checkItem" htmlFor={item.metaItemId}>
                                {item?.metaItemName}
                              </label>
                            </div>
                            <div className="checkItem">
                              {Currency?.currency?.name} {parseInt(item?.metaItemPrice || 0)}
                            </div>
                          </div>
                        )}
                        {metaType === '4' && (
                          <div className="form-check d-flex justify-content-between pb-3">
                            <div className="gap-3 d-flex">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`flexRadioDisabled_${metaType}`}
                                checked={selectedVariant?.metaItemId === item.metaItemId}
                                style={{ width: "26.81px", height: "26.81px" }}
                                onChange={() => handleRadioChange(item)}
                              />
                              <label className="form-check-label checkItem" htmlFor={item.metaItemId}>
                                {item?.metaItemName}
                              </label>
                            </div>
                            <div className="checkItem">
                              {Currency.currency.name} {item?.variantPrice || 0}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
              <div className="form-group">
                <label className="special pb-3">Special Instructions</label>
                <textarea
                  rows="4"
                  cols="100"
                  className="textarea"
                  placeholder="Enter the instructions about this item if any."
                  value={specialInstructions}
                  onChange={handleSpecialInstructionsChange}
                ></textarea>
              </div>
              <div className='row pt-lg-5'>
                <div className='col-lg-12'>
                  <button onClick={handleATC} className='w-100 AddBtn'>
                    {isEditing ? 'Update Cart' : 'Add to Cart'} {Currency.currency.name} {calculateTotalAmount()}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  AddToCart,
  updateCartItem,
};
const mapStatetoProps = (state) => state;

export default connect(mapStatetoProps, mapDispatchToProps)(CartModal);
