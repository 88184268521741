import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React, { useRef, useState, Component } from 'react';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

const EmployeeGrid = ({ employee, onRowSelect }) => {
    const gridRef = useRef();
    const navigate = useNavigate();

    // Navigate to a specific employee's details
    const handleShow = (field) => {
        navigate(`/admin/employee/${field?.employee_Name}`, { state: { field } });
    };

    // Handle row selection and pass selected data to parent component
    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        if (selectedRows.length === 1) {
            const selectedRowData = selectedRows[0];
            onRowSelect(selectedRowData); // Trigger parent callback
        }
    };

    // Date formatter for grid column
    const dateFormatter = (params) => {
        const date = moment(params.value);
        return date.format("MMM Do YY");
    };

    // Date comparator for sorting
    const dateComparator = (date1, date2) => {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        return momentDate1.isSame(momentDate2) ? 0 : momentDate1.isBefore(momentDate2) ? -1 : 1;
    };

    // Custom renderer for order items (example)
    const orderItemsRenderer = (params) => {
        const orderItems = params.data.orderItems;
        return orderItems?.map((item) => item.itemName).join(', ') || '';
    };

    // Column definitions for the grid
    const [colDefs] = useState([
        { field: "id", filter: true, floatingFilter: true, width: 100 },
        { field: "email", filter: true, floatingFilter: true },
        { field: "employee_Name", filter: true, floatingFilter: true, width: 290 },
        { headerName: 'Job', field: "department", filter: true, floatingFilter: true, width: 245 },
        { headerName: 'Phone Number', field: "phoneNumber", filter: true, floatingFilter: true, width: 250 },
        { headerName: 'Address', field: "exp3", filter: true, floatingFilter: true, width: 350 },
        { field: "city", filter: true, floatingFilter: true },
        {
            headerName: 'Action',
            field: 'id', width: 100,
            cellRenderer: BtnCellRenderer,
            cellRendererParams: {
                clicked: (params) => handleShow(params),
            },
        },
    ]);

    return (
        <div className="ag-theme-quartz" style={{ height: 550, backgroundColor: "#6E5199" }}>
            <AgGridReact
            
                ref={gridRef}
                rowData={employee}
                onSelectionChanged={onSelectionChanged}
                rowSelection="single" // Allow single row selection
                columnDefs={colDefs}
                frameworkComponents={{ orderItemsRenderer }}
                getRowStyle={(params) => {
                    if (params.node.isSelected()) {
                        return { background: '#d6eaff' }; // Highlight selected row
                    }
                    return params.node.rowIndex % 2 === 0 ? { background: '#F0EDF5' } : { background: '#fff' };
                }}
                suppressRowClickSelection={false} // Allow row clicks to trigger selection
            />
        </div>
    );
};

// Custom button cell renderer for the Action column
class BtnCellRenderer extends Component {
    constructor(props) {
        super(props);
        this.btnClickedHandler = this.btnClickedHandler.bind(this);
    }

    btnClickedHandler() {
        const { data, clicked } = this.props;
        clicked(data); // Call the provided function when button is clicked
    }

    render() {
        return (
            <button
                onClick={this.btnClickedHandler}
                className="delbtn"
                style={{ height: "39px", padding: "0px" }}
            >
                View
            </button>
        );
    }
}

export default EmployeeGrid;
