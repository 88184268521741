import React from "react";
import { useLocation, useParams } from 'react-router-dom';
import UserNav from "./userNav";
import UserList from "./userList";
import AddUser from "./addUser";
import AccessPage from "./accessPage";
import UserRights from "./userRights";

const UserMaster = () => {
    const { id } = useParams();
    const { state } = useLocation();
debugger
    let componentToRender;

    switch (id) {
        case "list":
            componentToRender = <UserList state={state} />;
            break;
        case "create":
            componentToRender = <AddUser state={state} />;
            break;
        case "access":
            componentToRender = <AccessPage state={state} />;
            break;
        case "rights":
            componentToRender = <UserRights state={state} />;
            break;
        default:
            componentToRender = <UserList state={state} />;
            break;
    }

    return (
        <div className="p-3">
            <div className="row px-lg-5">
                <UserNav id={id} state={state} />
                {componentToRender}
            </div>
        </div>
    );
};

export default UserMaster;
