import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiService from "../../../services/apiService";
import moment from "moment";
import "./DeliveryList.css";
import { AddToOrder, clearOrder } from "../../../../Redux/actions/orderEditAction";
import { clearCart } from "../../../../Redux/actions/cartAction";
import { AddComboToCart, clearComboCart } from "../../../../Redux/actions/comboAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Delivery = () => {
    const [orders, setOrders] = useState([]);
    const [riders, setRiders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
    const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
    const [orderRiderMap, setOrderRiderMap] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    const dispatch = new useDispatch()
    const navigate = new useNavigate()
    const OrderStatus = {
        NewOrder: 0,
        Accepted: 1,
        Cooking: 2,
        ReadyForDelivery: 3,
        ReadyForPickup: 4,
        OutForDelivery: 5,
        Completed: 6,
        Canceled: 7,
        OnHold: 8,
    };

    useEffect(() => {
        fetchOrders();
        fetchRiders();
    }, [selectedOrderStatus]);

    const handleEditOrder = (data) => {
        dispatch(clearOrder())
        dispatch(clearCart())
        dispatch(clearComboCart())

        const orderItems = data?.orderItems;
        const orderItemsMeta = data?.orderItemsMeta;

        const orderItemsMetaMap = {};

        orderItemsMeta.forEach(orderItemMeta => {
            const menuItemId = orderItemMeta.menuItemId;
            if (!orderItemsMetaMap[menuItemId]) {
                orderItemsMetaMap[menuItemId] = [];
            }
            orderItemsMetaMap[menuItemId].push(orderItemMeta);
        });

        orderItems.forEach(orderItem => {
            const menuItemId = orderItem.itemId;
            if (orderItemsMetaMap[menuItemId]) {
                orderItem.orderItemsMeta = orderItemsMetaMap[menuItemId];

                orderItem.selectedModifier = [];
                orderItem.selectedAdOn = [];
                orderItem.selectedVariant = null;

                orderItemsMetaMap[menuItemId].forEach(metaItem => {
                    switch (metaItem.metaType) {
                        case 2:
                            orderItem.selectedAdOn.push(metaItem);
                            break;
                        case 3:
                            orderItem.selectedModifier.push(metaItem);
                            break;
                        case 4:
                            orderItem.selectedVariant = metaItem;
                            break;
                    }
                });
            }
        });
        orderItems?.map((item) => {
            const mergedData = {
                selectedAdOn: item?.selectedAdOn,
                selectedModifier: item?.selectedModifier,
                selectedVariant: item?.selectedVariant,
                specialInstructions: item?.kitchenInstructions,
                itemData: item,
                quantity: item?.quantity,
            };

            dispatch(AddToOrder(mergedData))

        })

        data?.orderCombos.map((data) => {
            let itemdata = ({
                name: data.comboName,
                description: data?.description,
                price: data?.comboPrice,
                tax: data?.tax,
                discount: data?.discount,
                active: data?.active,
                imageUrl: data?.imageUrl,
                quantity: data?.quantity,
                comboItems: data?.comboItems?.map(comboItem => ({
                    itemId: comboItem.itemId,
                    metaItemIds: [],
                })),
                id: data.comboId,
                loggedInUserId: orderItems?.loggedInUserId
            });
            dispatch((AddComboToCart(itemdata)))
            itemdata = ""
        })

    }
    const handleTender = async (order) => {
        try {
            const apiService = new ApiService();
            let param = `?id=${order.orderId}`;
            const response = await apiService.getApiParamater(`GET_ORDERS`, param);
            const fetchedOrder = response?.data?.result[0];
            if (fetchedOrder.paymentStatus === 0) {

                console.log(`Order fetched ${fetchedOrder}`)
                if (fetchedOrder) {
                    handleEditOrder(fetchedOrder);
                    navigate('/Cashier/UpdateOrder', { state: { fetchedOrder, allowed: true } });
                    console.log("Fetched Order Data", fetchedOrder);
                } else {
                    toast.error("Order not found!");
                }
            } else {
                dispatch(clearComboCart());
                toast.warning("Order is already paid.");
            }
        } catch (error) {
            toast.error("Error fetching order by ID.");
        }

    };

    const fetchOrders = async () => {
        try {
            const apiService = new ApiService();
            const res = await apiService.get("GET_DELIVERY_ORDER");
            setOrders(res?.data?.result || []);
        } catch (error) {
            toast.error("Error fetching orders.");
        }
    };

    const fetchRiders = async () => {
        try {
            const apiService = new ApiService();
            const param = `?Role=Rider`;
            const res = await apiService.getApiParamater("GET_EMPLOYEE", param);
            setRiders(res?.data?.result || []);
        } catch (error) {
            toast.error("Error fetching riders.");
        }
    };

    const handleStatusChange = async (order, newStatus) => {
        const riderId = orderRiderMap[order.id];
        if (!riderId) {
            toast.error("Please assign a rider before departing.");
            return;
        }

        try {
            setStatusUpdateLoading(true);
            const apiService = new ApiService();

            const assignRiderPayload = {
                id: order.id,
                employeId: riderId,
            };
            await apiService.put("UPDATE_DELIVERY_ORDER", assignRiderPayload);
            toast.success(`Rider ${riderId} assigned successfully!`);

            const updateOrderPayload = {
                orderStatus: newStatus,
                id: order.orderId,
            };
            await apiService.put("UPDATE_ORDER", updateOrderPayload);
            toast.success("Order status updated to Out for Delivery!");
            fetchOrders();
        } catch (error) {
            toast.error("Error updating rider or order status.");
        } finally {
            setStatusUpdateLoading(false);
        }
    };

    const handleAssignRider = (orderId, riderId) => {
        setOrderRiderMap((prevMap) => ({
            ...prevMap,
            [orderId]: riderId,
        }));
        // toast.success(`Rider ${riderId} selected for Order ${orderId}`);
    };

    const filteredOrders = orders.filter((order) => {
        // Filter by search query first
        const searchText = searchQuery.toLowerCase();
        const matchesSearch =
            order.id.toString().includes(searchText) ||
            (order.customerName && order.customerName.toLowerCase().includes(searchText)) ||
            (order.customerDeliveryAddress &&
                order.customerDeliveryAddress.toLowerCase().includes(searchText));

        // If a status is selected, filter by that status
        const matchesStatus =
            !selectedOrderStatus || order.orderStatus === Number(selectedOrderStatus);

        return matchesSearch && matchesStatus;
    });

    return (
        <div className="delivery-list-container">
            <header className="row">
                <div className="status-filter delivery-list-header d-flex justify-space-between">
                    <div className="col-lg-2">
                        <select
                            id="orderStatus"
                            value={selectedOrderStatus}
                            onChange={(e) => setSelectedOrderStatus(e.target.value)}
                            className="w-100"
                        >
                            <option value="">All</option>
                            <option value={OrderStatus.Accepted}>Accepted</option>
                            <option value={OrderStatus.ReadyForDelivery}>Ready for Delivery</option>
                            <option value={OrderStatus.OutForDelivery}>Out for Delivery</option>
                            <option value={OrderStatus.Completed}>Completed</option>
                        </select>
                    </div>
                    <div>
                        <div className="date-display col">{moment().format("DD-MMM-YYYY HH:mm")}</div>
                    </div>
                </div>
                <div className="search-bar col-lg-3 mt-4 center">
                    <input
                        type="text"
                        placeholder="Search orders by ID, customer, or address..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input form-control w-100 border-none"
                    />
                </div>
            </header>

            <div className="delivery-list-table">
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Order No</th>
                            <th>Ordered Date</th>
                            <th>Address</th>
                            <th>Billed Amount</th>
                            <th>Customer</th>
                            <th>Order Status</th>
                            <th>Contact No</th>
                            <th>Rider</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.map((order) => (
                            <tr key={order.id}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedOrder === order.orderId}
                                        onChange={() =>
                                            setSelectedOrder((prevOrderId) =>
                                                prevOrderId === order.orderId ? null : order.orderId
                                            )
                                        }
                                    />
                                </td>
                                <td>ORD-{order.orderId || "N/A"}</td>
                                <td>{moment(order.orderDate).format("DD-MMM-YYYY HH:mm:ss")}</td>
                                <td>{order.customerDeliveryAddress || "N/A"}</td>
                                <td>{order.orderAmount}</td>
                                <td>{order.customerName || "N/A"}</td>
                                <td>
                                    {(() => {
                                        switch (order.orderStatus) {
                                            case OrderStatus.NewOrder:
                                                return "New Order";
                                            case OrderStatus.OutForDelivery:
                                                return "Out for Delivery";
                                            case OrderStatus.ReadyForDelivery:
                                                return "Ready for Delivery";
                                            case OrderStatus.Completed:
                                                return "Completed";
                                            case OrderStatus.Accepted:
                                                return "Accepted";
                                            default:
                                                return "Other"; // Fallback
                                        }
                                    })()}
                                </td>
                                <td>{order.customerMobileNo || "N/A"}</td>
                                <td>
                                    <select
                                        onChange={(e) => handleAssignRider(order.id, e.target.value)}
                                        value={orderRiderMap[order.id] || ""}
                                    >
                                        <option value="" disabled>
                                            Select Rider
                                        </option>
                                        {riders.map((rider) => (
                                            <option key={rider.id} value={rider.id}>
                                                {rider.employee_Name}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td className="d-flex justify-content-around">
                                    <button
                                        className={`AddBtn ${selectedOrder !== order.orderId ||
                                            order.orderStatus === OrderStatus.OutForDelivery
                                            ? "disabled"
                                            : ""
                                            }`}
                                        onClick={() =>
                                            handleStatusChange(order, OrderStatus.OutForDelivery)
                                        }
                                        disabled={
                                            selectedOrder !== order.orderId ||
                                            order.orderStatus === OrderStatus.OutForDelivery
                                        }
                                    >
                                        Depart
                                    </button>
                                    {order.orderStatus === OrderStatus.OutForDelivery ? (
                                        <button onClick={() =>
                                            handleTender(order, OrderStatus.OutForDelivery)
                                        } className="AddBtn ml-3 TenderBtn">
                                            Tender
                                        </button>
                                    ):   <button className="AddBtn disabled TenderBtn">
                                        Tender
                                    </button>}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Delivery;
