import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select';
import ApiService from '../../../../services/apiService';
import userManagement from '../../../../services/userManagement';
const AddUser = ({ showNavBox }) => {
    const { state } = useLocation();
    const apiService = new ApiService();
    let navigate = useNavigate();
    const location = useLocation();
    const [otp, setOTP] = useState(['', '', '', '']); // State to hold OTP values
    const refs = [useRef(), useRef(), useRef(), useRef()];
    const [searchTerm, setSearchTerm] = useState();
    const [Roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState();

    const [formData, setFormData] = useState({
        id: state?.id || '',
        userName: state?.employee_Name || "",
        firstName: "",
        lastName: "",
        email: state?.email || "",
        password: "",
        ConfirmPassword: "",
        role: "", 
        branch: state?.branchId || "",
        identityCardNumber: "",
        dateOfBirth: state?.dob ? moment(state.dob).format('YYYY-MM-DD') : "",
        gender: state?.exp3 || "",
        maritalstatus: "",
        bloodGroup: "",
        mobileNumber: state?.phoneNumber || "",
        contactNumber: state?.phoneNumber || "",
        familyNumber: "",
        socialLink: "",
        guardianName: "",
        drivingLicence: "",
        refName: "",
        permanentAddress: "",
        currentAddress: "",
    });

    useEffect(() => {
        getRoles();
    }, []);

    useEffect(() => {
        if (Roles.length && state?.roleId) {
            const selectedRole = Roles.find(p => p.id === state.roleId);
            if (selectedRole) {
                debugger
                setFormData(prevState => ({
                    ...prevState,
                    role: { label: selectedRole.name, value: selectedRole.id }
                }));
                setSelectedRole(selectedRole.id); // Correctly set the selected role ID
            }
        }
    }, [Roles, state?.roleId]);  // Re-run when roles or roleId changes

    useEffect(() => {
        if (state?.employee_Name) {
            const [first, ...rest] = state.employee_Name.split(' ');
            setFormData(prevState => ({
                ...prevState,
                firstName: first,
                lastName: rest.join(' '),
            }));
        }
    }, [state]);

    // Fetch Roles from API
    const getRoles = async () => {
        try {
            const parameters = `?Id=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_ROLE', parameters);
            setRoles(res.data.result);  // Save roles to state
            setSearchTerm(state?.roleId); // Optionally set searchTerm
        } catch (error) {
            console.error('Error fetching User Roles:', error);
        }
    };


    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const createUser = async () => {
        try {
            if (!formData?.userName || !formData?.email || !formData?.password) {
                toast.error("Please fill in all required fields.");
                return;
            }
            // Validate password and confirm password
            if (formData?.password !== formData?.ConfirmPassword) {
                toast.error("Password and Confirm Password must match.");
                return;
            }
            // Prepare data for the API call
            let Token = localStorage.getItem("Token");
            let userId = userManagement.getUserId(Token)
            const otpString = otp.join('');
            const userData = {
                userName: formData?.email || state?.userName,
                email: formData?.email,
                password: formData?.password,
                role: formData.role.label,
                createdBy: userId,
                passCode: otpString,
                firstName: formData?.firstName,
                lastName: formData?.lastName,
                user_Fullname:formData?.firstName + formData?.lastName
                // gender: formData?.gender,
                // dateOfBirth: formData?.dateOfBirth,
            };
            debugger
            const response = await apiService.post("USER_REGISTER", userData);
            toast.success("User registered successfully");

            if (response?.data?.statusCode === 201) {
                const updateEmploye = {
                    id: state?.id,
                    Email: formData?.email,
                    UserId: response?.data?.result?.id
                }

                const response2 = await apiService.put("UPDATE_EMPLOYEE", updateEmploye);

                // onUserId(response?.data?.result?.id);

                navigate("/admin/master/userMaster/rights", { state: state, userID: response?.data?.result?.id });
            }
        } catch (error) {
            console.error("Error registering user:", error);
            toast.error("Error registering user. Please try again later.");
        }
    };

    const handleOtpChange = (index, value) => {
        if (isNaN(value)) return; // Only allow numeric values
        const updatedOTP = [...otp];
        updatedOTP[index] = value.slice(-1); // Update OTP value at the given index
        setOTP(updatedOTP);

        if (value !== '') {
            // Move focus to the next input field if available
            if (index < 3) {
                refs[index + 1].current.focus();
            }
        }
    };

    const handleGenderChange = (selectedOption) => {
        setFormData({ ...formData, gender: selectedOption?.value });
    };
    const handleRoleChange = (selectedOption) => {
        setSelectedRole(selectedOption?.value?.name); // This should set the selected role name
        setFormData(prevState => ({
            ...prevState,
            role: selectedOption.value.id // Set the role ID in formData
        }));
    };

    const handleMaritalStatus = (selectedOption) => {
        setFormData({ ...formData, maritalstatus: selectedOption?.value });
    };

    const Gender = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ];

    const Maritalstatus = [
        { value: 'Single', label: 'Single' },
        { value: 'Married', label: 'Married' },
        { value: 'Divorced', label: 'Divorced' },
    ];

    return (
        <div className='px-lg-5 container'>
            <div className=''>
                <div className=''>

                    <h5>Basic Information</h5>
                    <div className='brderbx p-lg-5'>
                        <div className="form-group row justify-content-between pb-3">
                            <div className="col-md-6 col-lg-5">
                                <label htmlFor="validationCustom01" className="form-label">First Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom01"
                                    name="firstName"
                                    value={formData?.firstName}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5">
                                <label htmlFor="validationCustom2" className="form-label">Last Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom2"
                                    name="lastName"
                                    value={formData?.lastName}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className="form-group row justify-content-between">
                            <div className="col-md-5 col-lg-5">
                                <label htmlFor="validationCustom03" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="validationCustom03"
                                    name="email"
                                    value={formData?.email}
                                    onChange={handleInputChange}

                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-4'>
                    <h5>Roles And Permission</h5>
                    <div className='brderbx p-lg-5'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <div className="form-group row justify-content-between pb-3">
                                    <div className="col-md-4 col-lg-4">
                                        <label htmlFor="validationCustom04" className="form-label">Username *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="validationCustom04"
                                            name="userName"
                                            value={formData?.userName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationCustom05" className="form-label">Password *</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="validationCustom05"
                                            name="password"
                                            value={formData?.password}
                                            onChange={handleInputChange}

                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-4">
                                        <label htmlFor="validationCustom05" className="form-label">Confirm Password *</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="validationCustom05"
                                            name="ConfirmPassword"
                                            value={formData?.ConfirmPassword}
                                            onChange={handleInputChange}

                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-4">
                                        <label htmlFor="validationCustom09" className="form-label">Role *</label>

                                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                            value={formData.role} // Set the selected role as value
                                            // value={Roles.find(r => r.id === formData.role?.value) ? { value: formData.role, label: Roles.find(r => r.id === formData.role.name) } : null}
                                            isDisabled={true} // Disable the Select component
                                            options={Roles?.map((role) => ({ value: role.id, label: role.name }))} // Properly map options
                                            onChange={(selectedOption) => handleRoleChange(selectedOption)} // Just in case this is needed later
                                        />


                                        <div className="valid-feedback">Looks good!</div>
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <label htmlFor="validationCustom07" className="form-label">Branch *</label>
                                        <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                            value={formData?.role}
                                            onChange={handleRoleChange}
                                            options={Brach?.map((role) => ({ value: role, label: role.name }))}
                                        />
                                        <div className="valid-feedback">
                                            Looks good!
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <label className="form-label center">Pass Code *</label>
                                <div className='passbx'>
                                    <div className='d-flex'>
                                        {[0, 1, 2, 3].map(index => (
                                            <input
                                                key={index}
                                                type="number"
                                                maxLength={1}
                                                value={otp[index]}
                                                onChange={e => handleOtpChange(index, e.target.value)}
                                                ref={refs[index]}
                                                className="otpbx"
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='py-4'>
                    <h5>More Information </h5>
                    <div className='brderbx p-lg-5'>
                        <div className="form-group row justify-content-between">
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom08" className="form-label">Date of birth *</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="validationCustom08"
                                    name="dateOfBirth"
                                    value={formData?.dateOfBirth}
                                    onChange={handleInputChange}
                                    required
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom09" className="form-label">
                                    Gender
                                </label>
                                <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    defaultValue={formData?.gender}
                                    onChange={handleGenderChange}
                                    options={Gender}
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom10" className="form-label">
                                    Marital status *
                                </label>
                                <Select styles={{
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused ? '#F5F5F5 ' : null,
                                            color: state.isFocused ? '#000 ' : '#000',
                                            ':hover': {
                                                backgroundColor: '#F5F5F5',
                                                color: '#000 ',
                                            },
                                        }),
                                    }}
                                    defaultValue={formData?.maritalstatus}
                                    onChange={handleMaritalStatus}
                                    options={Maritalstatus}
                                />
                                <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom11" className="form-label">Blood Group *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom11"
                                    name="bloodGroup"
                                    value={formData?.bloodGroup}
                                    onChange={handleInputChange}

                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className="form-group row py-3">
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom12" className="form-label">Mobile Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="validationCustom12"
                                    name="mobileNumber"
                                    value={formData?.mobileNumber}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom13" className="form-label">Alternate Contact Number *</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="validationCustom13"
                                    name="contactNumber"
                                    value={formData?.contactNumber}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom14" className="form-label">Family Contact Number *</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="validationCustom14"
                                    name="familyNumber"
                                    value={formData?.familyNumber}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom15" className="form-label">Social Link *</label>
                                <input
                                    type="url"
                                    className="form-control"
                                    id="validationCustom15"
                                    name="socialLink"
                                    value={formData?.socialLink}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom16" className="form-label">Guardian Name *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom16"
                                    name="guardianName"
                                    value={formData?.guardianName}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom17" className="form-label">ID card Number *</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="validationCustom17"
                                    name="identityCardNumber"
                                    value={formData?.identityCardNumber}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom18" className="form-label">Driving Licence  Number *</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="validationCustom18"
                                    name="drivingLicence"
                                    value={formData?.drivingLicence}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <label htmlFor="validationCustom19" className="form-label">Referral name *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom19"
                                    name="refName"
                                    value={formData?.refName}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                        <div className="form-group row pt-3">
                            <div className="col-md-6 col-lg-6">
                                <label htmlFor="validationCustom20" className="form-label">Permanent Address *</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="validationCustom20"
                                    name="permanentAddress"
                                    value={formData?.permanentAddress}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <label htmlFor="validationCustom21" className="form-label">Current Address *</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="validationCustom21"
                                    name="currentAddress"
                                    value={formData?.currentAddress}
                                    onChange={handleInputChange}
                                />
                                <div className="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='row mt-5'>
                {location?.state?.val?.name ?
                    <div className='end'>
                        <button className='addItem-btn' >Update User</button>
                    </div> :
                    <div className='end'>
                        <button className='addItem-btn' onClick={createUser} >Save</button>
                    </div>
                }
            </div>
        </div>
    )
}
export default AddUser