// selectors/index.js

// Existing selectors
import userManagement from '../../POS/services/userManagement';

// Add the tax selector
export const getTaxExplicit = (state) => state.tax.taxExplicit;
export const getTaxImplicit = (state) => state.tax.taxImplicit;

// Other selectors (if you need them for other features)
export const getToken = (state) => state?.userAuth?.user?.token;
export const getProfile = (state) => state?.userAuth?.user?.profileImage;
export const getName = (state) => state?.userAuth?.user?.fullName;

export const getRole = (state) => userManagement.getUserRole(getToken(state));
export const getUserId = (state) => userManagement.getUserId(getToken(state));
export const getUserName = (state) => userManagement.getUserName(getToken(state));

export const getCartData = (state) => state?.cart?.items;
export const getComboData = (state) => state?.comboCart?.items;
export const getTableData = (state) => state?.table?.items;
export const getOrderEditData = (state) => state?.orderEdit?.items;
export const getEmployeData = (state) => state?.employeData;
export const getUserRights = (state) => state?.userDetails;

// Add on selectors
export const getAddons = (state) => state.addons.addons;
export const getAddonById = (state, id) => state.addons.addons.find(addon => addon.id === id);

export const CURRENCY = 'CURRENCY';
