import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiService from "../../../services/apiService";
import moment from "moment";
import "./DeliveryList.css";
import { OrderStatus, OrderType } from "../../../../Enums/config";

const DeliveryList = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCompletedDeliveryOrders();
    }, []);

    const fetchCompletedDeliveryOrders = async () => {
        setLoading(true);
        try {
            const apiService = new ApiService();
            // const params = `?OrderType=${OrderType.Delivery}&OrderStatus=${OrderStatus.OutForDelivery}`;
            const response = await apiService.get("GET_DELIVERY_ORDER");

            setOrders(response?.data?.result || []);
        } catch (error) {
            toast.error("Error fetching completed delivery orders.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="delivery-list-container">
            <h3 className="delivery-list-title">Completed Delivery Orders</h3>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="delivery-list-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Order No</th>
                                <th>Ordered Date</th>
                                <th>Address</th>
                                <th>Billed Amount</th>
                                <th>Customer</th>
                                <th>Rider Name</th>
                                <th>Rider Contact</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.length > 0 ? (
                                orders.map((order) => (
                                    <tr key={order.id}>
                                        <td>ORD-{order.orderId || "N/A"}</td>
                                        <td>{moment(order.orderDate).format("DD-MMM-YYYY HH:mm:ss")}</td>
                                        <td>{order.customerDeliveryAddress || "N/A"}</td>
                                        <td>{order.orderAmount?.toFixed(2) || "N/A"}</td>
                                        <td>{order.customerName || "N/A"}</td>
                                        <td>{order.employeName || "----"}</td>
                                        <td>{order.employeId || "N/A"}</td>
                                        <td>
                                            <span className="status-completed">Completed</span>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8">No completed delivery orders found.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default DeliveryList;
