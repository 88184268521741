import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ApiService from '../../../../services/apiService';
import ImageUpload from '../../menus/addItems/imagUpload';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../../Redux/selecters';
import Select from 'react-select';
import RestaurentMasterCard from './restaurentCard';
import userManagement from '../../../../services/userManagement';

const AreaMaster = () => {
    const userId = useSelector(getUserId);
    const [restaurantList, setRestaurantList] = useState([]);
    const [restaurantArea, setRestaurantArea] = useState([]);
    const [permissions, setPermissions] = useState({ add: false, update: false, delete: false });

    const [editMode, setEditMode] = useState(false);
    const [selectedArea, setSelectedArea] = useState(null);

    useEffect(() => {
        fetchKitchen();
        fetchKitchenArea();
        getPermissions();  // Fetch permissions when component mounts
    }, []);

    const [formData, setFormData] = useState({
        restaurant: '',
        area: "",
        images: [],
        imageUrl: ""
    });

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleRestaurantChange = (selectedOption) => {
        setFormData({ ...formData, restaurant: selectedOption });
    };

    const getPermissions = async () => {
        try {
            const userPermissions = await userManagement.getPermissions();
            setPermissions(userPermissions);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    };

    const fetchKitchen = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_RESTAURANT');
            const updatedKitchen = response?.data?.result;
            setRestaurantList(updatedKitchen);
        } catch (error) {
            console.error('Error fetching restaurants:', error);
        }
    };

    const fetchKitchenArea = async () => {
        try {
            const apiService = new ApiService();
            const response = await apiService.get('GET_RESTAURANT_AREA');
            const updatedAreas = response?.data?.result;
            setRestaurantArea(updatedAreas);
        } catch (error) {
            console.error('Error fetching restaurant areas:', error);
        }
    };

    const handleSubmit = async (imgurl) => {
        try {
            if (!permissions.add) {
                toast.error("You do not have permission to add areas.");
                return;
            }

            if (imgurl) {
                const body = {
                    resturentId: formData?.restaurant?.value?.id,
                    loggedInUserId: userId,
                    name: formData?.area,
                    imageUrl: imgurl,
                    active: true,
                };

                const apiService = new ApiService();
                const endpoint = editMode ? 'UPDATE_RESTAURANT_AREA' : 'CREATE_RESTAURANT_AREA';
                const res = await apiService.post(endpoint, body);

                if (res?.data?.statusCode === 201) {
                    toast?.success(editMode ? "Area Updated Successfully" : "Area Created Successfully");
                    resetForm();
                    fetchKitchen();
                    fetchKitchenArea();
                }
            }
        } catch (error) {
            console.error('Error saving area:', error);
        }
    };

    const resetForm = () => {
        setFormData({
            restaurant: '',
            area: '',
            images: [],
            imageUrl: ''
        });
        setEditMode(false);
        setSelectedArea(null);
    };

    const handleImagesSelect = (images) => {
        setFormData({ ...formData, images });
    };

    const handleImageRemove = (index) => {
        const updatedImages = [...formData.images];
        updatedImages.splice(index, 1);
        setFormData({ ...formData, images: updatedImages });
    };

    const handleRestaurantCardClick = (area) => {
        if (!permissions.update) {
            toast.error("You do not have permission to edit areas.");
            return;
        }

        setEditMode(true);
        setSelectedArea(area);
        setFormData({
            restaurant: { value: area.restaurant },
            area: area.name,
            images: [],
            imageUrl: area.imageUrl
        });
    };

    const handleDeleteArea = async (area) => {
        if (!permissions.delete) {
            toast.error("You do not have permission to delete areas.");
            return;
        }

        try {
            const apiService = new ApiService();
            const res = await apiService.delete(`DELETE_RESTAURANT_AREA/${area.id}`);
            if (res?.data?.statusCode === 200) {
                toast.success(`Area ${area?.name} deleted successfully!`);
                fetchKitchenArea();
            } else {
                toast.error("Failed to delete area.");
            }
        } catch (error) {
            console.error('Error deleting area:', error);
        }
    };

    return (
        <div className='p-5 container'>
            <div className='catlist'>
                {permissions.add && (
                    <div className='pt-3'>
                        <ImageUpload onImagesSelect={handleImagesSelect} onImageRemove={handleImageRemove} formData={formData} />
                    </div>
                )}

                {/* {permissions.add && ( */}
                    <div className='py-3 row'>
                        <div className="col-5">
                            <label htmlFor="validationCustom01" className="form-label">Area Name </label>
                            <input
                                type="text"
                                className="p-2 form-control"
                                id="validationCustom01"
                                name="area"
                                value={formData.area}
                                onChange={handleInputChange}
                                disabled={!permissions.add && !permissions.update}  // Disable if no add or update permission
                                required
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className="col-5">
                            <label htmlFor="validationCustom04" className="form-label texthead">Restaurant</label>
                            <Select
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#F5F5F5' : null,
                                        color: state.isFocused ? '#000' : '#000',
                                        ':hover': {
                                            backgroundColor: '#F5F5F5',
                                            color: '#000',
                                        },
                                    }),
                                }}
                                defaultValue={formData.restaurant}
                                onChange={handleRestaurantChange}
                                options={restaurantList?.map((restaurant) => ({ value: restaurant, label: restaurant.name }))}
                                isDisabled={!permissions.add && !permissions.update}  // Disable if no add or update permission
                            />
                            <div className="valid-feedback">
                                Looks good!
                            </div>
                        </div>
                        <div className='col-2 d-flex align-self-end col6 pt-3'>
                            <button
                                className='addItem-btn end'
                                onClick={() => handleSubmit(formData.imageUrl)}
                                disabled={!permissions.add}  // Disable if no add permission
                            >
                                Save
                            </button>
                        </div>
                    </div>
                {/* )} */}
            </div>

            <div className='catlist mt-5'>
                <div className="row">
                    {restaurantArea?.map((item) => (
                        <div key={item.id} onClick={() => handleRestaurantCardClick(item)}>
                            <RestaurentMasterCard
                                val={item}
                                fetchKitchenArea={fetchKitchenArea}
                            />
                            {permissions.delete && (
                                <button
                                    className="btn btn-danger ms-2"
                                    onClick={(e) => {
                                        e.stopPropagation();  // Prevent the click from triggering the card click
                                        handleDeleteArea(item);
                                    }}
                                >
                                    Delete
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AreaMaster;
