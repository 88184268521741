import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import "./update.css"
import { connect } from "react-redux";
import { ReactComponent as BackArrow } from '../../../assets/images/svgIcons/LeftArrow.svg';
import ApiService from '../../../services/apiService';
import CategoryCardEdit from '../categoryCard/CategoryCardEdit';
import { AddToOrder, RemoveFromOrder, clearOrder, downgradeOrderItemQuantity, updateOrderItem, updateOrderItemQuantity } from '../../../../Redux/actions/orderEditAction';
import { useDispatch } from 'react-redux';
import { getAddons, getComboData, getOrderEditData, getUserName } from '../../../../Redux/selecters';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import UpdateItemCard from './updateItemCard';
import userManagement from '../../../services/userManagement';
import { toast } from 'react-toastify';
import { invokeBroadCastMessage } from '../../../services/signalRService';
import { AddToCart, clearCart } from '../../../../Redux/actions/cartAction';
import DiscountModal from '../cashierbucket/addDiscount';
import CreatableSelect from 'react-select/creatable';
import { RemoveComboFromCart, downgradeComboQuantity, updateComboQuantity } from '../../../../Redux/actions/comboAction';
import { FiDelete } from 'react-icons/fi';
import { paymentOptions } from '../../../../Enums/config';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { removeAddon, updateAddonQuantity } from '../../../../Redux/actions/addOnAction';

const UpdateOrder = ({ Currency }) => {
    const { state } = useLocation();
    const {allowed } = state || {};

    const data = useSelector(getOrderEditData);
    const userName = useSelector(getUserName);
    const [discountModal, setDiscountModalOpen] = useState(false);
    const [TaxModal, setTaxModalOpen] = useState(false);
    const [Itemdata, setItemdata] = useState(data);

    const comboCart = useSelector(getComboData);
    const addOnsCart = useSelector(getAddons);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [menu, setMenu] = useState([]);
    const [table, setTable] = useState();
    const [taxes, setTax] = useState([]);
    const [areAllButtonsDisabled, setAreAllButtonsDisabled] = useState();
    const [areDiscountDisable, setDiscountDisable] = useState(false);
    const [isPaymentAllow, setPaymentAllow] = useState(false);
    const [selectedTaxPercentage, setSelectedTaxPercentage] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [areTaxDisable, setTaxDisable] = useState(false);
    let token = localStorage.getItem("Token")
    let Permission = userManagement?.getUserCrud(token)
    let userRole = userManagement.getUserRole(token)
    useEffect(() => {
        setItemdata(data);
        const isDiscountDisable = Permission?.includes("Discount.add") && Permission.includes("Discount.query");
        setDiscountDisable(!isDiscountDisable)
        const isTaxDisable = Permission?.includes("Tax.add") && Permission.includes("Tax.query");
        setTaxDisable(!isTaxDisable)
        const isPaymentAllow = Permission?.includes("Payment.add") && Permission.includes("Payment.query");
        setPaymentAllow(!isPaymentAllow)
    }, [data, refreshKey]);
    let navigate = useNavigate();
    let dispatch = useDispatch()
    useEffect(() => {
        GetCategory();
        AddToOrder()
        GetTax()
        GetTable()
        setRefreshKey((prevKey) => prevKey + 1);
    }, [searchTerm]);
    const calculateTotalPrice = () => {
        let totalPrice = 0;

        Itemdata.forEach((item) => {
            const itemData = item.itemData;
            const itemIndex = itemData.itemIndex;

            const matchedMeta = itemData.orderItemsMeta?.find(metaItem => metaItem.metaItemIndex === itemIndex);


            if (!itemData.voidItem && !item.refunded) {
                const variantPrice = parseFloat(matchedMeta?.variantPrice) || parseFloat(item.selectedVariant?.variantPrice) || parseFloat(itemData.menuItemPrice) || parseFloat(itemData.price);
                const addOnsPrice = calculateAddOnsPrice(item.selectedAdOn);

                const modifierPrice = calculateModifierPrice(itemData.orderItemsMeta, itemIndex);

                const finalItemPrice = variantPrice + addOnsPrice + modifierPrice;

                totalPrice += finalItemPrice * item.quantity;
            }
        });
        comboCart.forEach((item) => {
            totalPrice += item.quantity * item.price
        })
        addOnsCart.forEach((item) => {
            debugger
            totalPrice += item.quantity * item.price
        })
        return totalPrice;
    };

    const calculateAddOnsPrice = (addOns) => {
        return addOns?.reduce((total, addOn) => total + parseFloat(addOn?.metaItemPrice || 0), 0) || 0;
    };

    const calculateModifierPrice = (modifiers, itemIndex) => {
        return modifiers?.reduce((total, modifier) => {
            if (modifier.metaItemIndex === itemIndex) {
                return total + parseFloat(modifier?.metaItemPrice || 0);
            }
            return total;
        }, 0) || 0;
    };

    useEffect(() => {
        if (allowed) {
            proceed();
        }
    }, [allowed]);

    const closeDiscountModal = (e) => {
        setDiscountModalOpen(false);
    }
    const openDiscountModal = (e) => {
        setDiscountModalOpen(true);
    }
    const closeTaxModal = (e) => {
        setTaxModalOpen(false);
    }
    const openTaxModal = (e) => {
        setTaxModalOpen(true);
    }
    const calculateTotalDiscount = () => {
        let totalPrice = 0;
        Itemdata?.forEach((item) => {
            if (state?.paymentStatus == 0) {
                if (!item?.void) {
                    if (!item?.itemData?.voidItem) {
                        const itemPrice = getItemDiscountPrice(item);
                        totalPrice += itemPrice * item?.quantity;
                    }
                }
            } else if (!item.refund) {
                if (!item?.itemData?.refunded) {
                    const itemPrice = getItemDiscountPrice(item);
                    totalPrice += itemPrice * item?.quantity;
                }
            }
        });
        return totalPrice || state?.discount;
    };
    // combo
    const handleComboQuantity = (item, index) => {

        dispatch(updateComboQuantity(index, item?.id));
        setTotalRate(calculateTotalPrice());
    };
    const handlecomboQuantityDec = (item, index) => {
        dispatch(downgradeComboQuantity(index, item?.id));
        setTotalRate(calculateTotalPrice());
    };
    const handleAddOnQuantityInc = (item, index) => {
        dispatch(updateAddonQuantity(item?.id, item?.quantity + 1));
        setTotalRate(calculateTotalPrice());
    };
    const handleAddOnQuantityDec = (item, index) => {
        dispatch(updateAddonQuantity(item?.id, item?.quantity - 1));
        setTotalRate(calculateTotalPrice());
    };
    const handleAddOnDelete = (item, index) => {
        dispatch(removeAddon(item?.id));
        calculateTotalPrice()
    };
    const handlecomboDelete = (item, index) => {
        dispatch(RemoveComboFromCart(item?.id, index));
        calculateTotalPrice()
    };

    const getItemDiscountPrice = (item) => {
        const discountType = item?.itemData?.discountType;
        if (discountType === "Amount") {
            return parseInt(item?.itemData?.menuItemDiscount || item?.itemData?.discount || 0);
        } else if (discountType === "Percentage") {
            const discountPercentage = parseInt(item?.itemData?.discount || item?.itemData?.menuItemDiscount || 0);
            const itemPrice = parseInt(item?.selectedVariant?.variantPrice || 0);
            return (itemPrice * discountPercentage) / 100;
        }
        return 0;
    };
    const calculateSelectedTaxes = () => {
        let totalSelectedTaxes = 0;
        selectedTaxPercentage?.forEach(tax => {
            const taxValue = tax.value.taxPercentage || 0;
            const taxAmount = (totalRate * taxValue) / 100;
            totalSelectedTaxes += taxAmount;
        });

        return totalSelectedTaxes;
    };
    const calculateTotalPriceWithTaxesAndDiscounts = () => {
        let totalPrice = calculateTotalPrice();
        totalPrice += calculateTotalTax();
        const totalDiscount = calculateTotalDiscount();
        totalPrice -= totalDiscount || state?.discount;
        totalPrice += calculateSelectedTaxes();
        return totalPrice;
    };

    const calculateTotalTax = () => {
        let totalTax = 0;
        debugger
        Itemdata?.forEach((item) => {
            const itemData = item.itemData;
            const itemIndex = itemData.itemIndex;

            // Find matched variant in orderItemsMeta
            const matchedVariant = itemData.orderItemsMeta?.find(metaItem => metaItem.metaItemIndex === itemIndex);

            if (state?.paymentStatus === 0) {
                if (!item.void && !itemData.voidItem) {
                    const itemTax = calculateItemTax(item, state, matchedVariant);
                    totalTax += itemTax * item.quantity;
                }
            } else if (!item.refund) {
                if (!itemData.voidItem && !itemData.refunded) {
                    const itemTax = calculateItemTax(item, state, matchedVariant);
                    totalTax += itemTax * item.quantity;
                }
            }
        });

        return totalTax;
    };



    const calculateItemTax = (item, state, matchedVariant) => {
        const taxRate = parseFloat(item.itemData.tax) || parseFloat(item.itemData.menuItemTax) || 0;
        let itemPrice = parseFloat(item.selectedVariant?.variantPrice) || parseFloat(matchedVariant?.variantPrice) || parseFloat(item.itemData.menuItemPrice) || parseFloat(item.itemData.price);
        debugger
        if (item.itemData.taxIncluded) {
            itemPrice = itemPrice / (1 + (taxRate / 100));
        }

        const addOnsTax = calculateAddOnsTax(item.selectedAdOn, taxRate);
        // const modifierTax = item.itemData.taxIncluded ? 0 : calculateModifierTax(item.selectedModifier, taxRate);

        const itemTax = (taxRate / 100) * itemPrice;

        return itemTax + addOnsTax;
    };

    const calculateAddOnsTax = (addOns, taxRate) => {
        return addOns?.reduce((total, addOn) => total + ((parseFloat(addOn.metaItemPrice) || 0) * (taxRate / 100)), 0) || 0;
    };

    const calculateModifierTax = (modifiers, taxRate) => {
        return modifiers?.reduce((total, modifier) => total + ((parseFloat(modifier.metaItemPrice) || 0) * (taxRate / 100)), 0) || 0;
    };


    const [totalRate, setTotalRate] = useState();
    useEffect(() => {
        setTotalRate(calculateTotalPrice());
    }, [Itemdata]);
    const handleQuantityChange = (item, index) => {
        dispatch(updateOrderItemQuantity(index, item?.itemData?.id || item?.itemData?.itemId))
        setRefreshKey((prevKey) => prevKey + 1);
        setTotalRate(calculateTotalPrice());
    };
    const handleQuantityChangeDec = (item, index) => {
        dispatch(downgradeOrderItemQuantity(index, item?.itemData?.id || item?.itemData?.itemId))
        setRefreshKey((prevKey) => prevKey + 1);
        setTotalRate(calculateTotalPrice());
    };
    useEffect(() => {
        if (selectedCategory) {
            handlemenu()

            GetSubCategories(selectedCategory.id);
        }
    }, [selectedCategory]);
    const handleBack = () => {
        setSelectedCategory(null);
        setMenu([]);
    };
    const handlePageback = () => {
        dispatch(clearOrder())
        setSelectedCategory(null);
        setMenu([]);
        navigate('/Cashier/SaleHistory')
    };
    const handleVoidCheckbox = (item, index) => {

        const itemId = item?.itemData?.id || item?.itemData?.itemId;
        const updatedData = Itemdata?.map((dataItem, dataIndex) => {
            if (dataIndex === index && (dataItem?.itemData?.id === itemId || dataItem?.itemData?.itemId === itemId)) {

                // Toggle the void status of the clicked item
                return {
                    ...dataItem,
                    void: !dataItem.void,
                };
            }
            // dispatch(updateOrderItem(index, dataItem?.itemData?.id))
            return dataItem;
        });
        setItemdata(updatedData);
    };
    const handleRefund = (item, index) => {
        const itemId = item?.itemData?.id || item?.itemData?.itemId;
        const updatedData = Itemdata?.map((dataItem, dataIndex) => {
            if (dataIndex === index && (dataItem?.itemData?.id === itemId || dataItem?.itemData?.itemId === itemId)) {
                // Enable refund only if item is voided
                return {
                    ...dataItem,
                    refund: dataItem.void ? !dataItem.refund : false,
                };
            }
            return dataItem;
        });
        setItemdata(updatedData);
    };
    const itemNidumber = state?.id;
    const itemNidumber1 = state?.id?.replace(/^ORD-/i, '');

    const proceed = () => {
        UpdateOrderData()
        dispatch(clearCart())
        Itemdata?.forEach(item => {
            dispatch(AddToCart(item));
        });
        navigate('/cashier/home', { state: { state: true, Update: itemNidumber, paymentStatus: state?.paymentStatus, orderDiscount: state?.discount,allowed:allowed } });
    }
    const mapOrderType = (orderType) => {
        switch (orderType) {
            case 0:
                return "DineIn";
            case 1:
                return "Takeout";
            case 2:
                return "Delivery";
            case 3:
                return "OnlineOrder";
            case 4:
                return "DriveThru";
            case 5:
                return "Catering";
            case 6:
                return "RoomService";
            case 7:
                return "Pickup";
            case 8:
                return "CurbsidePickup";
            default:
                return "Unknown Order Type";
        }
    };
    const mapOrderStatus = (orderStatus) => {
        switch (orderStatus) {
            case 0:
                return "New Order";
            case 1:
                return "Accepted";
            case 2:
                return "Preparing";
            case 3:
                return "Ready For Delivery";
            case 4:
                return "Ready For Pick up";
            case 5:
                return "Out For Delivery";
            case 6:
                return "Delivered";
            case 7:
                return "Cancelled";
            case 8:
                return "On-Hold";
            default:
                return "Unknown Order Status";
        }
    };
    const GetCategory = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Name=${searchTerm}`;
            const res = await apiService.getApiParamater('GET_CATEGORY', parameters);
            const filteredcategory = res?.data?.result?.filter(item => item?.active === true);

            setCategories(filteredcategory);
        } catch (error) {
            console.error('Error fetching AddsOn data:', error);
        }
    };
    const GetSubCategories = async (categoryId) => {
        try {

            const apiService = new ApiService();
            let parameters = `?CategoryId=${selectedCategory?.id}`;

            let res = await apiService.getApiParamater(`GET_SUBCATEGORY`, parameters);
            setSubCategories(res?.data?.result);
        } catch (error) {
            console.error('Error fetching sub-category data:', error);
        }
    };
    const handleMenuClick = (selectedCategory) => {

        setSelectedCategory(selectedCategory);
    };
    const handlemenu = async (sub) => {
        setSelectedSubCategory(sub?.name)
        try {
            const apiService = new ApiService();
            let parameters = sub ? `?SubCategoryId=${sub?.id}` : `?CategoryId=${selectedCategory?.id}`;
            let res = await apiService.getApiParamater('GET_MENU', parameters);
            if (res?.data?.statusCode === 200) {
                setMenu(res?.data?.result);
            } else {
                console.error('Error fetching menu by category:', res?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching menu by category:', error);
        }
    }
    const mapDynamicMetaData = (item) => {

        const result = [];
        if (item?.selectedModifier) {

            result.push(...item.selectedModifier?.map((modifier) => ({
                metaItemId: parseInt(modifier?.metaItemId),
                type: parseInt(modifier?.metaType),
                menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                metaItemIndex: parseInt(item?.index)
            })));
        }

        if (item?.selectedAdOn) {
            result.push(...item.selectedAdOn?.map((adOn) => ({
                metaItemId: parseInt(adOn?.metaItemId),
                type: parseInt(adOn?.metaType),
                menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                metaItemIndex: parseInt(item?.index)
            })));
        }

        if (item?.selectedVariant) {
            if (Array.isArray(item.selectedVariant)) {
                result.push(...item.selectedVariant?.map((variant) => ({
                    metaItemId: parseInt(variant?.metaItemId),
                    type: parseInt(variant?.metaType),
                    menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                    metaItemIndex: parseInt(item?.index)

                })));
            } else {
                result.push({
                    metaItemId: parseInt(item?.selectedVariant?.metaItemId),
                    type: parseInt(item?.selectedVariant?.metaType),
                    menuItemId: parseInt(item?.itemData?.id || item?.itemData?.itemId),
                    metaItemIndex: parseInt(item?.index)

                });
            }
        }


        return result;
    };
    const UpdateOrderData = async () => {

        let dataReq = {
            id: parseInt(itemNidumber1),
            orderItems: Itemdata?.map((item, index) => ({
                itemId: parseInt(item.itemData.id || item?.itemData?.itemId),
                quantity: parseInt(item.quantity),
                kitchenInstructions: item.specialInstructions,
                voidItem: item?.void || item?.itemData?.voidItem ? item?.void || item?.itemData?.voidItem : false,
                refunded: item?.refund || item?.itemData?.refunded ? item?.refund || item?.itemData?.refunded : false,
                itemIndex: index
            })),
            orderItemsMeta: Itemdata.flatMap(mapDynamicMetaData),
            loggedInUserId: userManagement.getUserId(token) || 'Guest'
        }
        let datareq1 = {
            id: parseInt(itemNidumber1),
            orderAmount: (calculateTotalPriceWithTaxesAndDiscounts() || 0),
            totalDiscount: (calculateTotalDiscount() || 0),
            amountRefunded: 0,
            calculatedTax: calculateTotalTax() + calculateSelectedTaxes(),
            discountId: Itemdata[0]?.itemData?.discountId,
            promotionId: Itemdata[0].itemData?.promotionId
        }

        try {
            const apiService = new ApiService();
            let res = await apiService.put("UPDATE_ORDER_META", dataReq)
            let res1 = await apiService.put("UPDATE_ORDER", datareq1)
            if (res) {
                invokeBroadCastMessage(`Order Updated.OrderNo#${itemNidumber1.toString()}`);
                // navigate('/Cashier/home')
                // window.location.reload(false);
            }
            else {
                toast.warning("Something is wrong in your order.Try Again updating your order")
            }
        } catch (error) {
        }
    };
    const GetTable = async () => {
        try {
            const apiService = new ApiService();
            let parameters = `?Id=${state?.tableId}`;
            const res = await apiService.getApiParamater('GET_TABLE', parameters);
            setTable(res?.data?.result?.[0])
        } catch (error) {
            console.error('Error fetching AddsOn data:', error);
        }
    };
    const handleItemRemove = (id, index) => {

        dispatch(RemoveFromOrder(id, index));
        calculateTotalPrice()
    }
    const GetTax = async () => {
        const apiService = new ApiService();
        let res = await apiService.get("GET_TAX");
        const filteredTax = res?.data?.result.filter(tax => tax?.vat === false);
        setTax(filteredTax);
    }
    const handleSelectedTax = (selectedItem) => {
        setSelectedTaxPercentage(selectedItem || []);
    };
    return (
        <>
            <div className='m-5'>
                <div className='HeadBG'>
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">
                            Table No
                        </label>
                        <div className='brdrbt'>
                            {!table?.name ?
                                'No Table selected' : table?.name
                            }
                        </div>
                        <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">
                            Order No
                        </label>
                        <div className='brdrbt'>
                            {state?.id}
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">
                            KOT
                        </label>
                        <div className='brdrbt'>
                            KOT-{itemNidumber}
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">
                            Type
                        </label>
                        <div className='brdrbt'>
                            {mapOrderType(state?.orderType)}
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">
                            Cashier
                        </label>
                        <div className='brdrbt'>
                            {userName}
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">
                            Customer
                        </label>
                        <div className='brdrbt'>
                            {Itemdata?.customerName || "Guest"}
                        </div>
                    </div>
                    <div className="">
                        <label htmlFor="validationCustom04" className="form-label">
                            Status
                        </label>
                        <div className='brdrbt'>
                            {mapOrderStatus(state?.orderStatus)}
                        </div>
                    </div>
                </div>
                <div className='row pt-5 p-3'>
                    <div className='col-lg-7 tablebG'>
                        <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Modifier</th>
                                        <th>Quantity</th>
                                        <th>Rate</th>
                                        {userRole == 'Waiter' ? " " :

                                            <th>Action</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {Itemdata?.map((item, index) => {
                                        // Find the variant by matching metaItemIndex with itemIndex
                                        debugger
                                        const matchedVariant = item?.itemData.orderItemsMeta?.find(metaItem => metaItem.metaItemIndex === item.itemData.itemIndex);
                                        return (
                                            <React.Fragment key={index}>
                                                <tr>
                                                    <td title={item?.itemData?.menuItemName || item?.itemData?.name}>
                                                        {item?.itemData?.menuItemName || item?.itemData?.name}
                                                    </td>
                                                    <td>
                                                        {/* {item?.selectedModifier?.map((modifier, i) => (
                                                            <div key={i} title={modifier.metaItemName}>
                                                                {modifier.metaItemName}
                                                            </div>
                                                        ))} */}
                                                        {matchedVariant && (
                                                            <div title={matchedVariant.metaItemName}>
                                                                {matchedVariant.metaItemName}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="quantity-control d-flex">
                                                            {item?.quantity === 1
                                                                ? <div className="addbtn">-</div>
                                                                : <div
                                                                    onClick={() => handleQuantityChangeDec(item, index)}
                                                                    className="addbtn"
                                                                >
                                                                    -
                                                                </div>
                                                            }
                                                            <div style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                                                                {item.quantity}
                                                            </div>
                                                            <div
                                                                onClick={() => handleQuantityChange(item, index)}
                                                                className="addbtn"
                                                            >
                                                                +
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {Currency.currency.name}  {item?.selectedVariant ? matchedVariant?.variantPrice || item?.selectedVariant?.variantPrice
                                                            + calculateAddOnsPrice(item?.selectedAdOn, index) + calculateModifierPrice(item?.selectedModifier, index)
                                                            : item?.itemData?.menuItemPrice + calculateAddOnsPrice(item?.selectedAdOn, index) + calculateModifierPrice(item?.selectedModifier, index) || item?.itemData?.price
                                                        }
                                                    </td>
                                                    {userRole !== 'Waiter' && (
                                                        <td className='d-flex gap-4 flex-inline'>
                                                            {state?.paymentStatus === 2
                                                                ? <div
                                                                    className={`AddCart-btn p-2 d-flex justify-content-center ${item.void || item?.itemData?.voidItem ? 'active' : 'inactive'}`}
                                                                    onClick={() => handleVoidCheckbox(item, index)}
                                                                >
                                                                    Void
                                                                </div>
                                                                : <td>
                                                                    <RiDeleteBin2Fill color='#6E5199' size={20} onClick={() => handleItemRemove(item, index)} />
                                                                </td>
                                                            }
                                                            {item?.void && state?.paymentStatus !== 0 && (
                                                                <div
                                                                    className={`AddCart-btn p-2 d-flex justify-content-center ${item.refund || item?.itemData?.refunded ? 'active' : 'inactive'}`}
                                                                    onClick={() => handleRefund(item, index)}
                                                                >
                                                                    Refund
                                                                </div>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}


                                    {comboCart?.map((item, index) => (
                                        <tr key={index}>
                                            <td title={item?.name}>{item?.name}</td>
                                            <td title={item?.name}>{item?.comboItems?.length}</td>
                                            <td>
                                                <div className="quantity-control d-flex justify-content-between center">
                                                    <div
                                                        onClick={() => handlecomboQuantityDec(item, index)}
                                                        className="addbtn center">
                                                        -
                                                    </div>
                                                    <div style={{ paddingRight: '10px', paddingLeft: '10px' }} className="center ">{item?.quantity}</div>
                                                    <div
                                                        onClick={() => handleComboQuantity(item, index)}
                                                        className="addbtn center"
                                                    >
                                                        +
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {Currency.currency.name} {item?.price}
                                            </td>
                                            <td>
                                                <RiDeleteBin2Fill color='#6E5199' size={20} onClick={() => handlecomboDelete(item, index)} />
                                            </td>
                                        </tr>
                                    ))}
                                    {addOnsCart?.map((item, index) => (
                                        <tr key={index}>
                                            <td title={item?.name}>{item?.name}</td>
                                            <td title={item?.name}>{item?.comboItems?.metaItem?.length} </td>
                                            <td>
                                                <div className="quantity-control d-flex  ">
                                                    <div
                                                        onClick={() => handleAddOnQuantityDec(item, index)}
                                                        className="addbtn center">
                                                        -
                                                    </div>
                                                    <div style={{ paddingRight: '10px', paddingLeft: '10px' }} className="center">{item?.quantity}</div>
                                                    <div
                                                        onClick={() => handleAddOnQuantityInc(item, index)}
                                                        className="addbtn center"
                                                    >
                                                        +
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                {Currency.currency.name} {item?.price}
                                            </td>
                                            <td>
                                                <RiDeleteBin2Fill color='#6E5199' size={20} onClick={() => handleAddOnDelete(item, index)} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={`col-lg-5 ${state?.paymentStatus === 2 ? 'disabled-div' : ''}`}>
                        <div>
                            {selectedCategory ? (
                                <div className='col-lg-12 ovrflo'>
                                    <div className='row'>
                                        <div className='col-5'>
                                            <button className='AddBtn' onClick={handleBack}>
                                                <BackArrow /> Back
                                            </button>
                                        </div>
                                        <div className='col-7'>
                                            <div className='catbtn'>{selectedCategory?.name}</div>
                                        </div>
                                    </div>
                                    <div className='subcat mt-4 mb-3'>
                                        <div className='d-flex justify-content-around'>
                                            {subCategories?.map((cat) => (
                                                <div
                                                    key={cat.id}
                                                    className={`subcatlist cursor-pointer ${selectedSubCategory === cat?.name && 'CatActive'}`}
                                                    onClick={() => handlemenu(cat)}>
                                                    {cat.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='CashierGrid'>
                                        {menu?.map((item) => (
                                            <UpdateItemCard key={item.id} val={item} />
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className='col-lg-12'>
                                    <div className='d-flex justify-content-between'>
                                        <h4>Categories</h4>
                                        <div>
                                            <input
                                                type='Search'
                                                className='form-control'
                                                id='validationCustom01'
                                                placeholder='Search Categories'
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {categories?.map((item) => (
                                            <CategoryCardEdit key={item.id} val={item} handleMenuClick={handleMenuClick} />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='row p-3'>
                        <div className='col-lg-7 tablebG'>
                            <div className='d-flex justify-content-between py-3'>
                                <div className='char'>Invoice Total</div>
                                <div className='char'>{Currency.currency.name} {calculateTotalPrice()?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div className='char'> Tax</div>
                                <div className='char'>{Currency.currency.name} {" "}
                                    {calculateTotalTax().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                            </div>
                            <div className='d-flex justify-content-between py-3'>
                                <div className='char'>Discount</div>
                                <div className='char'>{Currency.currency.name} {calculateTotalDiscount() || 0}</div>
                                {/* <div className='char'>{Currency.currency.name} {state?.discount || 0}</div> */}
                            </div>
                            {TaxModal === false ?
                                <>{selectedTaxPercentage?.map((tax, index) => (
                                    <div className='d-flex justify-content-between pt-2'>
                                        <div className='cashhead'>{tax?.label} {tax?.value?.taxPercentage || 0} %</div>
                                        <div className='cashhead'>{calculateSelectedTaxes()}</div>
                                    </div>
                                ))}</> : " "
                            }
                            <div className="lineBreak"></div>
                            <div className='d-flex justify-content-between py-3'>
                                <div className='payab'>Total Price</div>
                                <div className='payab'>{Currency.currency.name}  {(calculateTotalPriceWithTaxesAndDiscounts().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 mt-3 align-items-end justify-content-between px-5'>
                                <div className='row d-flex justify-content-between'>
                                    {isPaymentAllow ? " " :
                                        <div className='col-lg-3'><button className='AddBtn w-100' onClick={proceed}>Proceed</button></div>
                                    }
                                    <div className='col-lg-3'><button className='AddBtn w-100'>Print Bill</button></div>
                                    <div className='col-lg-3'><button className='AddBtn w-100' onClick={UpdateOrderData}>Update Order</button></div>
                                </div>

                                <div className='row mt-4 d-flex justify-content-between'>
                                    {areDiscountDisable ? " " :
                                        <>
                                            <div className='col-lg-3'><button onClick={openDiscountModal} className='AddBtn w-100'>Add Discount</button></div>
                                        </>
                                    }
                                    {areTaxDisable ? " " :
                                        <div className='col-lg-3'><button onClick={openTaxModal} className='AddBtn w-100'>Add Tax</button></div>
                                    }
                                    <div className='col-lg-3'><button onClick={handlePageback} className='AddBtn w-100'>Back</button></div>
                                </div>
                            </div></div>
                    </div>
                </div>
            </div>
            {/* Discount Modal */}
            <Modal size="lg"
                show={discountModal} onHide={closeDiscountModal}
                onRequestClose={closeDiscountModal}
                contentLabel='Receipt Modal'
                style={customStyles} closeButton
            >
                <DiscountModal closeDiscountModal={closeDiscountModal} />
            </Modal>
            {/* Tax Modal */}
            <Modal size="md"
                show={TaxModal} onHide={closeTaxModal}
                onRequestClose={closeTaxModal}
                contentLabel='Receipt Modal'
                style={customStyles} closeButton
            >
                <Modal.Header closeButton>
                    <Modal.Title className='center'>Add Tax</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreatableSelect
                        isMulti
                        options={taxes?.map((taxes) => ({ value: taxes, label: taxes?.name }))}
                        value={selectedTaxPercentage}
                        onChange={handleSelectedTax}
                        onCreateOption={null}
                        className=''
                        isClearable
                    />
                </Modal.Body>
                <Modal.Footer className='end p-3'>
                    <button className='AddBtn' onClick={closeTaxModal}>Save</button>
                </Modal.Footer>
            </Modal >
        </>
    );
};
const mapStatetoProps = (state) => state;
export default connect(mapStatetoProps)(UpdateOrder);
const customStyles = {
    content: {
        width: '30%', // Set the desired width
        height: '75%', // Set the desired 
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        right: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Center both vertically and horizontally
    },
};