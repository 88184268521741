import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom"; // React Router for navigation
import ApiService from "../../../../services/apiService";

const ManageRoles = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize navigation
  const apiService = new ApiService();

  // Fetch roles data from the API
  const fetchRoles = async () => {
    try {
      const response = await apiService.get("GET_ROLE");
      setRoles(response.data.result);
    } catch (error) {
      console.error("Error fetching roles:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  // Navigate to the next page with roleId (adjusted from userId)
  const handleEdit = (roleId) => {
    debugger
    navigate(`/admin/master/userMaster/ManageRole/${roleId}`,{state:roleId});
  };

  // Table Columns
  const columns = [
    {
      name: "Role ID",
      selector: (row) => row.id || "---",  // Use the 'id' field for role ID
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name || "---",  // Use 'name' for role name
      sortable: true,
    },
    {
      name: "Normalized Name",
      selector: (row) => row.normalizedName || "---",  // Use 'normalizedName' for the normalized version of the name
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => row.active === null ? "N/A" : (row.active ? "Yes" : "No"),  // Handle the 'active' field with a conditional check
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          onClick={() => handleEdit(row.id)} // Use 'id' for the action
          style={{
            backgroundColor: "#6E5199",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "3px",
            cursor: "pointer",
          }}
        >
          Edit
        </button>
      ),
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={roles}
        progressPending={loading}
        pagination
        highlightOnHover
        responsive
      />
    </div>
  );
};

export default ManageRoles;
