import React from "react";
import { Link, useLocation } from 'react-router-dom';
import './setting.css';
import './sideBox.css';
import { MdNotificationsNone } from "react-icons/md";
import { GrCurrency, GrOrganization } from "react-icons/gr";
import { LuUser2 } from "react-icons/lu";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";

const Sidebox = () => {
    const location = useLocation();

    const isActive = (route) => location.pathname === route;

    return (
        <ProSidebar className="set-sidebar">
            <Menu iconShape="square">
                {/* Profile Menu Item */}
                <MenuItem
                    className={`dashitems mb-2 sidebar-text ${isActive("/admin/Setting/Profile") ? "sideactive" : ""}`}
                >
                    <Link to="/admin/Setting/Profile">
                        <LuUser2
                            className={`${isActive("/admin/Setting/Profile") ? "sideactive-icon" : ""}`}
                        />
                        <span className={`${isActive("/admin/Setting/Profile") ? "sideactive-text" : ""}`}>Profile</span>
                    </Link>
                </MenuItem>

                {/* Notifications Menu Item */}
                <MenuItem
                    className={`dashitems mb-2 sidebar-text ${isActive("/admin/Setting/Notification") ? "sideactive" : ""}`}
                >
                    <Link to="/admin/Setting/Notification">
                        <MdNotificationsNone
                            className={`${isActive("/admin/Setting/Notification") ? "sideactive-icon" : ""}`}
                        />
                        <span className={`${isActive("/admin/Setting/Notification") ? "sideactive-text" : ""}`}>Notifications</span>
                    </Link>
                </MenuItem>

                {/* Currency Menu Item */}
                <MenuItem
                    className={`dashitems mb-2 sidebar-text ${isActive("/admin/Setting/Currency") ? "sideactive" : ""}`}
                >
                    <Link to="/admin/Setting/Currency">
                        <GrCurrency
                            className={`${isActive("/admin/Setting/Currency") ? "sideactive-icon" : ""}`}
                        />
                        <span className={`${isActive("/admin/Setting/Currency") ? "sideactive-text" : ""}`}>Currency</span>
                    </Link>
                </MenuItem>

                {/* Tax Settings Menu Item */}
                <MenuItem
                    className={`dashitems mb-2 sidebar-text ${isActive("/admin/Setting/Tax") ? "sideactive" : ""}`}
                >
                    <Link to="/admin/Setting/Tax">
                        <GrOrganization
                            className={`${isActive("/admin/Setting/Tax") ? "sideactive-icon" : ""}`}
                        />
                        <span className={`${isActive("/admin/Setting/Tax") ? "sideactive-text" : ""}`}>Tax Settings</span>
                    </Link>
                </MenuItem>
            </Menu>
        </ProSidebar>
    );
}

export default Sidebox;
